import React from 'react';
import './TKCheckbox.scss';

const TKCheckbox = ({name, value, checked, onChange, label}) => {
  return (
    <label className="tk_checkbox">
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="tk_checkbox-checkmark"></span>
      {label}
    </label>
  );
};

export default TKCheckbox;

TKCheckbox.defaultProps = {
  name: "",
  value: "",
  checked: false,
  onChange: () => {},
  label: ""
}