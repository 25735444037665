import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Form, Button, Modal } from "react-bootstrap";
import TKLogo from "../../TKLogo/TKLogo";
import TKIcon from "../../TKIcon/TKIcon";
import InputMask from "react-input-mask";
import { validate } from "../../../helpers/validate";
import { tkRegisterRequest } from "../../../store/actions/auth";
import TKDatePicker from "../../AdminLayout/TKInputs/TKDatePicker/TKDatePicker";
import { formatDate, ililce } from "../../../helpers/utilities";
import TKSelect from "../../TKSelect/TKSelect";
import { getTKProfessionsRequest } from "../../../store/actions/professions";
import "./TKSignupForm.scss";
import TKModal from "../../AdminLayout/TKModal/TKModal";
import sozlesme from "../../../assets/images/sozlesme.png";
import sozlesme2 from "../../../assets/images/sozlesme2.png";
const TKSignupForm = ({ setShowModal, handleShowModal }) => {
  const initialFormData = {
    name: "",
    surname: "",
    email: "",
    tckn: "",
    telephone_number: "",
    password: "",
    birthdate: "",
    sex: "",
    profession: "",
    province: "",
    district: "",
  };

  const initialFormDataValidate = {
    name: {
      required: true,
    },
    surname: {
      required: true,
    },
    email: {
      isEmail: true,
    },
    tckn: {
      required: true,
      length: 11,
    },
    telephone_number: {
      required: true,
      phoneLength: 10,
    },
    password: {
      required: true,
      minLength: 6,
    },
    sex: {
      required: true,
    },
    birthdate: {
      required: true,
    },
    profession: {
      required: true,
    },
    province: {
      required: true,
    },
  };

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});
  const { listProfessions } = useSelector((state) => state.professions);
  const [filteredListProfessions, filteredSetListProfessions] =
    useState(listProfessions);
  const [sexState, setSexState] = useState([
    { sex: "ERKEK", _id: "ERKEK" },
    { sex: "KADIN", _id: "KADIN" },
  ]);
  const [filteredSexState, filteredSetSexState] = useState([
    { sex: "ERKEK", _id: "ERKEK" },
    { sex: "KADIN", _id: "KADIN" },
  ]);
  const [selectSearch, setSelectSearch] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  useEffect(() => {
    dispatch(getTKProfessionsRequest());
    filteredSetListProfessions(listProfessions);
  }, [dispatch]);

  const onChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, initialFormDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  function signup(e) {
    e.preventDefault();
    if (checkValidation()) {
      dispatch(
        tkRegisterRequest({
          data: { ...formData },
          handleCheckSignupModal: (show) => setShowModal(show),
          handleValidationModal: (show, field) => handleShowModal(show, field),
        })
      );
    }
  }

  const [district, setDistrict] = useState(null);

  useEffect(() => {
    if (formData?.province) {
      const found = ililce.find((element) => element._id == formData.province);
      setDistrict(found.district);
      console.log(found.district);
    }
  }, [formData.province]);

  const onSelectChange = (name, value) => {
    console.log(value);

    if (value == "Backspace") {
      filteredSetSexState(sexState);
      filteredSetListProfessions(listProfessions);

      setSelectSearch("");
    } else if (
      value == "Capslock" ||
      value == "ArrowDown" ||
      value == "ArrowUp" ||
      value == "ArrowLeft" ||
      value == "ArrowRight"
    ) {
    } else if (name === "profession") {
      filteredSetListProfessions(
        listProfessions.filter(function (item) {
          if (
            item.name
              .toLowerCase()
              .startsWith((selectSearch + value).toLowerCase())
          ) {
            setSelectSearch(selectSearch + value);

            return item;
          }
        })
      );
    }
  };

  return (
    <>
      <Card className="border-0">
        <Card.Header className="d-flex bg-primary p-3 justify-content-center align-items-center">
          <Card.Title className="m-0 d-flex justify-content-center align-items-center">
            <div>
              <TKLogo size={150} />
            </div>
            <span>
              <small>
                <i className="text-white">| Kayıt Ol</i>
              </small>
            </span>
            <div
              className="position-absolute"
              style={{ right: "10px", top: "10px", transform: "rotate(45deg)" }}
              onClick={() => setShowModal(false)}
            >
              <TKIcon name="plus" color="white" size="15" />
            </div>
          </Card.Title>
        </Card.Header>

        <Card.Body className="p-5">
          <Form onSubmit={signup}>
            <Form.Group className="mb-2" controlId="formBasicTckn">
              <Form.Label>TC Kimlik No *</Form.Label>
              <Form.Control
                placeholder="TC Kimlik No"
                value={formData.tckn}
                name="tckn"
                maxLength="11"
                onChange={(e) => {
                  onChange(e.target.name, e.target.value);
                }}
                isInvalid={formDataErrors.tckn}
              />
              <Form.Text className="text-danger">
                {formDataErrors.tckn}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicName">
              <Form.Label>İsim *</Form.Label>
              <Form.Control
                placeholder="İsim"
                value={formData.name}
                name="name"
                onChange={(e) => {
                  onChange(e.target.name, e.target.value);
                }}
                isInvalid={formDataErrors.name}
              />
              <Form.Text className="text-danger">
                {formDataErrors?.name}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicSurname">
              <Form.Label>Soyisim *</Form.Label>
              <Form.Control
                placeholder="Soyisim"
                value={formData.surname}
                name="surname"
                onChange={(e) => {
                  onChange(e.target.name, e.target.value);
                }}
                isInvalid={formDataErrors.surname}
              />
              <Form.Text className="text-danger">
                {formDataErrors?.surname}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>doğum tarihi *</Form.Label>
              <TKDatePicker
                className={formDataErrors?.birthdate && "is-invalid"}
                dateValue={formData.birthdate}
                dateFormat="P"
                placeholder={`ör: ${formatDate(new Date(), "P")}`}
                onChange={(date) => onChange("birthdate", date)}
                showTimeInput={false}
              />
              <Form.Text className="text-danger">
                {formDataErrors?.birthdate}
              </Form.Text>
            </Form.Group>
            <div className="d-flex" style={{ gap: "5px" }}>
              <Form.Group style={{ width: formData.province ? "50%" : "100%" }}>
                <Form.Label>İl *</Form.Label>
                <TKSelect
                  defaultTitle="İl Seç"
                  selectedItem={parseInt(formData?.province)}
                  itemSelector="province"
                  items={ililce}
                  itemOnClick={(id) => onChange("province", id)}
                  isInvalid={formDataErrors.province}
                  name="province"
                />
                <Form.Text className="text-danger">
                  {formDataErrors?.province}
                </Form.Text>
              </Form.Group>
              {formData.province && district && (
                <Form.Group className="flex-fill">
                  <Form.Label>İlçe *</Form.Label>
                  <TKSelect
                    defaultTitle="İlçe Seç"
                    selectedItem={parseInt(formData?.district)}
                    itemSelector="district"
                    items={district}
                    itemOnClick={(id) => onChange("district", id)}
                    isInvalid={formDataErrors.district}
                    name="district"
                  />
                  <Form.Text className="text-danger">
                    {formDataErrors?.district}
                  </Form.Text>
                </Form.Group>
              )}
            </div>
            <Form.Group>
              <Form.Label>Cinsiyet *</Form.Label>
              <TKSelect
                defaultTitle="Cinsiyet Seç"
                selectedItem={formData?.sex}
                itemSelector="sex"
                items={sexState}
                itemOnClick={(id) => onChange("sex", id)}
                isInvalid={formDataErrors.sex}
                name="sex"
              />
              <Form.Text className="text-danger">
                {formDataErrors?.sex}
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>meslek *</Form.Label>
              <TKSelect
                defaultTitle="Meslek Seç"
                selectedItem={formData?.profession}
                itemSelector="name"
                items={listProfessions}
                itemOnClick={(id) => onChange("profession", id)}
                isInvalid={formDataErrors.profession}
                //search={selectSearch}
                name="profession"
                //onChange={(value) => onSelectChange('profession',value)}
                //filter={true}
              />
              <Form.Text className="text-danger">
                {formDataErrors?.profession}
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Label>E-Posta Adresi</Form.Label>
              <Form.Control
                placeholder="E-posta"
                name="email"
                value={formData.email}
                onChange={(e) => {
                  onChange(e.target.name, e.target.value);
                }}
                isInvalid={formDataErrors.email}
              />
              <Form.Text className="text-danger">
                {formDataErrors?.email}
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-2" controlId="formBasicPhone">
              <Form.Label>Telefon Numarası *</Form.Label>
              <Form.Control
                as={InputMask}
                placeholder="5XXXXXXXXX"
                mask="5999999999"
                maskChar={null}
                value={formData.telephone_number}
                name="telephone_number"
                onChange={(e) => {
                  onChange(e.target.name, e.target.value);
                }}
              />
              <Form.Text className="text-danger">
                {formDataErrors?.telephone_number}
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Parola *</Form.Label>
              <Form.Control
                type="password"
                placeholder="Parola"
                value={formData.password}
                name="password"
                onChange={(e) => {
                  onChange(e.target.name, e.target.value);
                }}
                isInvalid={formDataErrors.password}
              />
              <Form.Text className="text-danger">
                {formDataErrors?.password}
              </Form.Text>
            </Form.Group>
            <Form.Check
              type="checkbox"
              onClick={setShow}
              required
              label={"Tuzla Kart Hizmeti Aydınlatma ve Sözleşme Metni"}
            />

            <Form.Check
              type="checkbox"
              onClick={setShow2}
              required
              label={"Tuzla Kart Sözleşmesi"}
            />

            <TKModal
              backdropClassName="tk_ccc-backdrop"
              header="Tuzla Kart Hizmeti Aydınlatma ve Sözleşme Metni"
              cancelBtnTitle="Tamam"
              saveButtonHide={true}
              show={show}
              setShow={setShow}
              size="lg"
            >
              <img src={sozlesme}></img>
            </TKModal>
            <TKModal
              backdropClassName="tk_ccc-backdrop"
              header="Tuzla Kart Sözleşmesi"
              cancelBtnTitle="Tamam"
              saveButtonHide={true}
              show={show2}
              setShow={setShow2}
              size="lg"
            >
              <img src={sozlesme2}></img>
            </TKModal>
            <div className="d-flex w-100 justify-content-end">
              <Button className="mt-2" variant="primary" type="submit">
                Tamamla
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default TKSignupForm;

TKSignupForm.defaultProps = {
  setShowModal: () => {},
};
