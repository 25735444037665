import React, { useEffect, useState } from "react";
import "./TKPreregistrationContainer.scss";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import TKMemberWorkplacesForm from "../AdminLayout/TKForm/TKMemberWorkplacesForm/TKMemberWorkplacesForm";
import TKModal from "../AdminLayout/TKModal/TKModal";
import { validate } from "../../helpers/validate";
import { preregisterTKMemberWorkplacesRequest } from "../../store/actions/memberWorkplaces";
import kurumsal_sozlesme from "../../assets/images/kurumsal_sozlesme.png";
import sozlesme_docx from "../../assets/docs/TUZLA_KART_SOZLESME.docx";
import { formatUrl } from "../../helpers/utilities";

const TKPreregistrationContainer = ({ customButton, ...props }) => {
  const DEFAULT_DISCOUNT_TYPE = "PERCENT";

  const initialFormData = {
    name: "",
    surname: "",
    personalEmail: "",
    vkn: "",
    entitle: "",
    address: "",
    phone: "",
    email: "",
    web_site: "",
    image: null,
    managerphone: "",
    category: [],
    detail: "",
    general_discount_type: DEFAULT_DISCOUNT_TYPE,
    amount: "",
    position: {},
    discounts: [],
    province: "",
    district: "",
  };

  const initialFormDataValidate = {
    name: {
      required: true,
    },
    surname: {
      required: true,
    },
    personalEmail: {
      isEmail: true,
    },
    vkn: {
      required: true,
      minLength: 10,
    },
    entitle: {
      required: true,
    },
    managerphone: {
      required: true,
      phoneLength: 10,
    },
    address: {
      required: true,
    },
    phone: {
      required: true,
      phoneLength: 10,
    },
    email: {
      isEmail: true,
    },
    web_site: {
      isWebSite: true,
    },
    image: {
      required: true,
    },
    category: {
      required: true,
    },
    detail: {},
    general_discount_type: {
      required: true,
    },
    amount: {
      required: true,
      isNumber: true,
      isPercent: "general_discount_type",
    },
    position: {
      isPosition: true,
    },
  };

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.memberWorkplaces);
  const { listCardTypes } = useSelector((state) => state.cardTypes);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [formDataErrors, setFormDataErrors] = useState({});
  const [formDataValidate, setFormDataValidate] = useState({});
  const [show, setShow] = useState(false);

  console.log(formData);

  useEffect(() => {
    createDiscounts();
  }, [listCardTypes]);

  const createDiscounts = () => {
    let discountArray = [];

    for (var i = 0; i < listCardTypes.length; i++) {
      const discount = {
        type: listCardTypes[i]._id,
        discounttype: "PERCENT",
        amount: 0,
      };

      discountArray.push(discount);
    }

    setFormData((prevState) => ({
      ...prevState,
      discounts: discountArray,
    }));
  };

  const checkValidation = () => {
    let errorMsgs = validate(formData, formDataValidate);
    console.log(errorMsgs, "hata");
    if (Object.keys(errorMsgs).length > 1) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    } else if (Object.keys(errorMsgs).length > 0) {
      if (errorMsgs.amount) {
        errorMsgs = {};
        return true;
      } else {
        setFormDataErrors({ ...errorMsgs });
        return false;
      }
    }
  };

  const handleShowModal = (show) => {
    setFormData({ ...initialFormData });
    setFormDataValidate({ ...initialFormDataValidate });
    setShowModal(show);
  };

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      // public registration request
      setShow(true);
    }
  };

  const handleSaveOnClickAggrement = () => {
    setShow(false);
    dispatch(
      preregisterTKMemberWorkplacesRequest({
        data: { ...formData, s_entitle: formatUrl(formData.entitle.trim()) },
        handleShowModal,
      })
    );
  };

  return (
    <>
      {!customButton && (
        <div className="tk_preregistration-container d-flex justify-content-start">
          <Button
            variant="outline-primary"
            onClick={() => handleShowModal(true)}
            className="tk_preregistration-container-button"
          >
            Üye İşyeri Başvurusu
          </Button>
        </div>
      )}
      {customButton && customButton({ handleShowModal })}
      <TKModal
        className="tk_preregistration-container-modal"
        show={showModal}
        setShow={handleShowModal}
        header="Üye İşyeri Başvuru Formu"
        saveBtnTitle="Başvur"
        saveOnClick={handleSaveOnClick}
        processLoading={loading}
        size="xl"
      >
        <TKMemberWorkplacesForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isPublic={true}
        />
      </TKModal>
      <TKModal
        backdropClassName="tk_ccc-backdrop"
        header="Tuzla Kart Sözleşmesi"
        cancelBtnTitle="Reddet"
        saveOnClick={handleSaveOnClickAggrement}
        saveBtnTitle="Onaylıyorum"
        downloadButtonUrl={sozlesme_docx}
        show={show}
        setShow={setShow}
        size="xl"
      >
        <img src={kurumsal_sozlesme}></img>
      </TKModal>
    </>
  );
};

export default TKPreregistrationContainer;

TKPreregistrationContainer.defaultProps = {
  setShowModal: () => {},
  customButton: null,
};
