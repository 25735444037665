import * as types from '../actionTypes';

export const getTKCategoriesRequest = () => ({
  type: types.GET_TK_CATEGORIES_REQUEST
});

export const getTKCategoriesSucceeded = data => ({
  type: types.GET_TK_CATEGORIES_SUCCEEDED,
  payload: data
});

export const getTKCategoriesFailed = () => ({
  type: types.GET_TK_CATEGORIES_FAILED
});

// ADMIN
// CREATE
export const createTKAdminCategoriesRequest = reqParams => ({
  type: types.CREATE_TK_ADMIN_CATEGORIES_REQUEST,
  payload: reqParams
});

export const setShowCategoriesAddModal = show => ({
  type: types.SET_SHOW_CATEGORIES_ADD_MODAL,
  payload: show
});

export const processTKAdminCategoriesSucceeded = () => ({
  type: types.PROCESS_TK_ADMIN_CATEGORIES_SUCCEEDED
});

export const processTKAdminCategoriesFailed = () => ({
  type: types.PROCESS_TK_ADMIN_CATEGORIES_FAILED
});

// DELETE
export const setShowCategoriesDeleteModal = show => ({
  type: types.SET_SHOW_CATEGORIES_DELETE_MODAL,
  payload: show
});

export const deleteTKAdminCategoriesRequest = reqParams => ({
  type: types.DELETE_TK_ADMIN_CATEGORIES_REQUEST,
  payload: reqParams
});
