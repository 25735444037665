import * as types from '../actionTypes';

export const getTKCardTypesRequest = () => ({
  type: types.GET_TK_CARDTYPES_REQUEST
});

export const getTKCardTypesRequestWithAllCardOption = reqParams => ({
  type: types.GET_TK_CARDTYPES_REQUEST_WITH_ALL_OPTION,
  payload: reqParams
});



export const getTKCardTypesSucceeded = data => ({
  type: types.GET_TK_CARDTYPES_SUCCEEDED,
  payload: data
});

export const getTKCardTypesFailed = () => ({
  type: types.GET_TK_CARDTYPES_FAILED
});


export const searchTKCardTypesRequest = ({search}) => ({
  type: types.SEARCH_TK_CARDTYPES_REQUEST,
  payload: {
    search
  }
});

export const searchTKCardTypesSucceeded = data => ({
  type: types.SEARCH_TK_CARDTYPES_SUCCEEDED,
  payload: data
});

export const searchTKCardTypesFailed = () => ({
  type: types.SEARCH_TK_CARDTYPES_FAILED
});

// ADMIN
// CREATE
export const createTKAdminCardTypesRequest = reqParams => ({
  type: types.CREATE_TK_ADMIN_CARDTYPES_REQUEST,
  payload: reqParams
});

export const setShowCardTypesAddModal = show => ({
  type: types.SET_SHOW_CARDTYPES_ADD_MODAL,
  payload: show
});

export const processTKAdminCardTypesSucceeded = () => ({
  type: types.PROCESS_TK_ADMIN_CARDTYPES_SUCCEEDED
});

export const processTKAdminCardTypesFailed = () => ({
  type: types.PROCESS_TK_ADMIN_CARDTYPES_FAILED
});

// DELETE
export const setShowCardTypesDeleteModal = show => ({
  type: types.SET_SHOW_CARDTYPES_DELETE_MODAL,
  payload: show
});

export const deleteTKAdminCardTypesRequest = reqParams => ({
  type: types.DELETE_TK_ADMIN_CARDTYPES_REQUEST,
  payload: reqParams
});
