import React, { useEffect } from 'react';
import './HomePage.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TKStatusContainer from '../../../../components/TKStatusContainer/TKStatusContainer';
import TKImageSlider from '../../../../components/TKImageSlider/TKImageSlider';
import TKCardsContainer from '../../../../components/TKCardsContainer/TKCardsContainer';
import TKSliderContainer from '../../../../components/TKSliderContainer/TKSliderContainer';
import TKCard from '../../../../components/TKCard/TKCard';
import { fiveCardsSliderSettings, fourCardsSliderSettings, threeCardsSliderSettings } from '../../../../constants/sliderSettings';
import { getTKHPCampsRequest, getTKHPNewsRequest, getTKHPBannersRequest, getTKHPStatusRequest } from '../../../../store/actions/homepage';
import { formatUrl, handleRedirect } from '../../../../helpers/utilities';

const HomePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    homeCamsList,
    homeCamsLoading,
    homeNewsList,
    homeNewsLoading,
    homeBannersList,
    homeBannersLoading,
    homeStatus
  } = useSelector(state => state.homepage);

  useEffect(() => {
    dispatch(getTKHPCampsRequest());
    dispatch(getTKHPNewsRequest());
    dispatch(getTKHPBannersRequest());
    dispatch(getTKHPStatusRequest())
  }, [dispatch])

  const bannerList = homeBannersList.slice(0, 5);

  const campsList = homeCamsList.map(({ _id, image, short_detail, campaign_type, amount, owner }, i) => (
    <TKCard
      size="md"
      key={i}
      shadow={false}
      badge={true}
      image={image}
      desc={owner?.entitle + '  ' + short_detail}
      onClick={() => handleRedirect(history, `/kampanyalar/${formatUrl(short_detail)}`)}
      href={`/kampanyalar/${formatUrl(short_detail)}`}
      campaign={{ campaign_type, amount }}
    />
  ));

  const newsList = homeNewsList.map(({ _id, image, caption, short_detail }, i) => (
    <TKCard
      key={i}
      shadow={false}
      size="md"
      badge={false}
      image={image}
      header={caption}
      desc={short_detail}
      onClick={() => handleRedirect(history, `/haberler/${formatUrl(short_detail)}`)}
      href={`/haberler/${formatUrl(short_detail)}`}
    />
  ));

  const notFoundDiv = (pre = "") => {
    return (
      <div
        className="h5 d-flex justify-content-center align-items-center"
        style={{ minHeight: "100px" }}
      >
        {pre} Bulunamadı
      </div>
    );
  }

  return (
    <>
      <div className='tk_hp-status-div'>
        <div className="tk_hp-status container">
          <TKStatusContainer homeStatus={homeStatus} />
        </div>
      </div>
      <div className="tk_hp-campaigns-div">
        <div
          className="tk_hp-campaigns container" >
          <TKSliderContainer
            header="kampanyalar"
            isShowAll={true}
            showAllOnClick={() => { handleRedirect(history, `/kampanyalar/`) }}
            customSettings={threeCardsSliderSettings(campsList.length)}
            sliderClassName="tk_slider-three tk_slider-padding"
            loading={homeCamsLoading}
            placeholder={notFoundDiv("Kampanya")}
          >
            {campsList}
          </TKSliderContainer>

        </div>
      </div>
      <div className="tk_hp-news-div">
        <div className="tk_hp-news container">
          <TKSliderContainer
            header="haberler"
            isShowAll={true}
            showAllOnClick={() => { handleRedirect(history, `/haberler/`) }}
            customSettings={threeCardsSliderSettings(newsList.length)}
            sliderClassName="tk_slider-three tk_slider-padding"
            loading={homeNewsLoading}
            placeholder={notFoundDiv("Haber")}
          >
            {newsList}
          </TKSliderContainer>
        </div>
      </div>
    </>
  );
};

export default HomePage;