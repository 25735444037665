import React, { useEffect, useState } from 'react';
import './TKDataTable.scss';
import TKPagination from './TKPagination/TKPagination';
import { sortData } from '../../../helpers/utilities';

const TKDataTable = ({
  columns,
  data,
  rowKey,
  sortedColumn,
  setSortedColumn,
  sortDirection,
  setSortDirection,
  pagination,
  activePage,
  totalPages,
  handleFetchData,
  searchedText
}) => {
  const ASC = 'asc';
  const DESC = 'desc';
  const directions = [ASC, DESC, null];

  const [listedData, setListedData] = useState(data);
  const [localSortDirection, setLocalSortDirection] = useState(null);
  const [localSortedColumn, setLocalSortedColumn] = useState(null);

  useEffect(() => {
    setListedData(data);
  }, [data])

  useEffect(() => {
    if(!pagination) {
      if (localSortDirection) { 
        const sortedDate = sortData(data, localSortedColumn, localSortDirection);
        setListedData([...sortedDate])
      } else {
        setListedData([...data]);
      }
    }
  }, [pagination, data, localSortedColumn, localSortDirection]);

  const handleClickColumnHead = (sortable, selector) => {
    if (sortable) {
      const direction = directions[(directions.findIndex(d => d === getSortDirection()) + 1) % directions.length]
      if (pagination) {
        // const direction = sortDirection === ASC ? DESC : ASC;
        setSortedColumn(selector);
        setSortDirection(direction);
        // handleFetchData(activePage);
      } else {
        setLocalSortedColumn(selector);
        setLocalSortDirection(direction);
      }
      
    }
  }

  const getSortDirection = () => {
    if(pagination) {
      return sortDirection;
    } else {
      return localSortDirection
    }
  }

  const getSortedColumn = () => {
    if(pagination) {
      return sortedColumn;
    } else {
      return localSortedColumn;
    }
  }

  return (
    <div className="tk_table">
      <div className="tk_table-container">
        <table>
          <thead>
            <tr>
              {columns.map((column, index) => 
                <th 
                  key={`${column.selector}-${index}`}
                  data-sortable={column.sortable}
                  data-sort-direction={column.selector === getSortedColumn() ? 
                    getSortDirection() : ''
                  }
                  onClick={() => 
                    handleClickColumnHead(column.sortable, column.selector)
                  }
                  width={column.width}
                >
                  {column.name}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {listedData.map((dataItem, index) => 
              <tr
                key={`${dataItem[rowKey]}-${index}`}
              >
                {columns.map((column, cIndex) => 
                  <td
                    key={`${dataItem[rowKey]}-${column.selector}-${cIndex}`}
                    title={
                      column.showTitle && dataItem[column.selector]
                    }
                    style={dataItem[column.selector] === "ACTIVE" ? {color:"#008C00"} :
                     dataItem[column.selector] === "PASSIVE" ? {color:"#406882"} :
                     dataItem[column.selector] === "CANCELED" ? {color:"#F05454"} :
                     dataItem[column.selector] === "WFA" ? {color:"#F0BB62"} : {color:"#8c959d"} }
                  >
                    {column.cell ? 
                      column.cell(dataItem) : 
                      dataItem[column.selector]
                    }
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {pagination && 
        <TKPagination
          pagesLength={totalPages}
          activePage={activePage + 1}
          onPageClick={(page) => handleFetchData(page - 1,searchedText)}
        />
      }
    </div>
  );
};

export default TKDataTable;

TKDataTable.defaultProps = {
  columns: [],
  data: [],
  rowKey: '_id',
  sortedColumn: '',
  setSortedColumn: () => {},
  sortDirection: '',
  setSortDirection: () => {},
  pagination: true,
  activePage: 0,
  totalPages: 0,
  handleFetchData: () => {}
}
