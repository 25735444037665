import React, { useContext } from 'react';
import { AccordionContext, useAccordionToggle } from 'react-bootstrap';
import TKIcon from '../../TKIcon/TKIcon';

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => 
    callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div 
      className={`tk_accordion-toggle ${isCurrentEventKey ? 'tk_accordion-active' : ''}`.trim()} 
      onClick={decoratedOnClick}
    >
      <div className="tk_accordion-question">
        {children}
      </div>
      <div className="tk_accordion-icon">
        <TKIcon
          name="arrow"
          color="#09072f"
          size="13"
        />
      </div>
    </div>
  )
};

export default ContextAwareToggle;