import React from "react";
import "./TKIcon.scss";

const TKIcon = ({ className, name, size, color, onClick, title, style }) => {
  return (
    <i
      className={`tk_icon tk_icon-${name} ${className}`}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: color,
        ...style
      }}
      onClick={onClick}
      title={title}
    ></i>
  );
};

export default TKIcon;

TKIcon.defaultProps = {
  className: '',
  size: "24",
  color: "#fff",
  onClick: () => {},
  title: "",
  style: {}
};
