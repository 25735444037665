import React from "react";
import { Form } from "react-bootstrap";

const TKLinksForm = ({ formData, formDataErrors, onChange, isCreate }) => {
  return (
    <Form className="tk_banner-form">
      <Form.Group>
        <Form.Label>başlık *</Form.Label>
        <Form.Control
          type="text"
          name="title"
          placeholder="Başlık"
          value={formData?.title || ""}
          maxLength={100}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          isInvalid={formDataErrors?.title}
        />
        <Form.Text className="text-danger">{formDataErrors?.title}</Form.Text>
      </Form.Group>
      <Form.Label>link *</Form.Label>
      <Form.Group>
        <Form.Control
          type="text"
          name="link"
          placeholder="ör: https://website.com/"
          value={formData?.link || ""}
          maxLength={100}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          isInvalid={formDataErrors?.link}
        />
        <Form.Text className="text-danger">{formDataErrors?.link}</Form.Text>
      </Form.Group>
    </Form>
  );
};

export default TKLinksForm;

TKLinksForm.defaultProps = {
  formData: {},
  formDataErrors: {},
  onChange: () => {},
  isCreate: false,
};
