import * as types from '../actionTypes'

const initialState = {
  list: [],
  loading: false,
  error: false
}

const transactions = (state = initialState, action) => {
  switch(action.type) {
    case types.GET_TK_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_TRANSACTIONS_SUCCEEDED:
      console.log(action.payload)
      const transactions = action.payload.map(transactionItem => {
        console.log(transactionItem)
        if (transactionItem.type == "CAMPAIGN" && transactionItem.campaign !== null ) {
          transactionItem.short_detail = transactionItem.campaign?.short_detail
          transactionItem.owner = transactionItem.campaign?.owner?.entitle
        }
        return transactionItem

      },[])
      return {
        ...state,
        loading: false,
        error: false,
        list: [...transactions]
      }
    case types.GET_TK_TRANSACTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    default:
      return state;
  }
}

export default transactions;