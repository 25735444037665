import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiCall } from '../../helpers/api';
import * as actions from '../actions/campaigns';
import {
  GET_TK_CAMPS_REQUEST,
  GET_TK_TIMED_CAMPS_REQUEST,
  GET_TK_CAMPS_BY_ID_REQUEST,
  GET_TK_ADMIN_CAMPS_REQUEST,
  CREATE_TK_ADMIN_CAMPS_REQUEST,
  EDIT_TK_ADMIN_CAMPS_REQUEST,
  DELETE_TK_ADMIN_CAMPS_REQUEST,
  POST_TK_CAMPS_EXPORT_EXCEL_REQUEST,
  GET_TK_CAMPS_PARTICIPANT_EXPORT_EXCEL_REQUEST
} from '../actionTypes';
import { customSwal } from '../../helpers/customSwal';
import { campaignCodeTypes } from '../../constants/general';
import download from 'downloadjs';
import { ContactSupportOutlined } from '@material-ui/icons';


function* watchGetTKCamps() {
  yield takeEvery(GET_TK_CAMPS_REQUEST, getTKCamps);
}

function* getTKCamps(action) {
  try {
    const res = yield call(apiCall,
      'post',
      'public/campaign/getPaginationCampaigns',
      {
        ...action.payload,
        size: 18
      }
    );
    yield put(actions.getTKCampsSucceeded({
      data: [...res.data.data],
      page: action.payload.page,
      totalCount: res.data.totalCount
    }));
  } catch (err) {
    yield put(actions.getTKCampsFailed());
  }
}

function* watchGetTKTimedCamps() {
  yield takeEvery(GET_TK_TIMED_CAMPS_REQUEST, getTKTimedCamps);
}

function* getTKTimedCamps(action) {
  try {
    const { currentRes, futureRes, oldRes } = yield all({
      currentRes: call(apiGetTKTimedCamps, 10, 'current', action.payload),
      futureRes: call(apiGetTKTimedCamps, 7, 'future', action.payload),
      oldRes: call(apiGetTKTimedCamps, 7, 'past', action.payload),
    });
    yield put(actions.getTKTimedCampsSucceeded({
      currentCamps: [...currentRes.data.data],
      futureCamps: [...futureRes.data.data],
      oldCamps: [...oldRes.data.data]
    }));
  } catch (err) {
    yield put(actions.getTKTimedCampsFailed());
  }
}

const apiGetTKTimedCamps = (size, type, reqParams) => (
  apiCall(
    'post',
    'public/campaign/getPaginationCampaigns',
    { page: 0, size, type, ...reqParams }
  )
)

function* watchGetTKCampsById() {
  yield takeEvery(GET_TK_CAMPS_BY_ID_REQUEST, getTKCampsById);
}

function* getTKCampsById(action) {
  try {
    const res = yield call(apiCall,
      'get',
      `public/campaign/shortDetail/${action.payload}`
    );
    yield put(actions.getTKCampsByIdSucceeded({ ...res.data.data }));
  } catch (err) {
    yield put(actions.getTKCampsByIdFailed());
  }
}

function* watchGetTKAdminCamps() {
  yield takeEvery(GET_TK_ADMIN_CAMPS_REQUEST, getTKAdminCamps);
}

function* getTKAdminCamps(action) {
  const SIZE = 50;
  try {
    const { page, searchFormData } = action.payload;
    const { detail, short_detail, status, owner } = searchFormData;

    const res = yield call(apiCall,
      'post',
      'api/loyalty/campaign/getPaginationCampaigns',
      {
        page,
        detail,
        short_detail,
        owner,
        status,
        size: SIZE
      }
    );
    yield put(actions.getTKAdminCampsSucceeded({
      data: [...res.data.data],
      page,
      search: '',
      status,
      totalPages: Math.ceil(res.data.totalCount / SIZE)
    }));
  } catch (err) {
    yield put(actions.getTKAdminCampsFailed());
  }
}

function* watchCreateTKAdminCamps() {
  yield takeLatest(CREATE_TK_ADMIN_CAMPS_REQUEST, createTKAdminCamps);
}

function* createTKAdminCamps(action) {
  try {
    const { data, page, searchFormData, handleSetShowCampsAddModal } = action.payload;
    const { s_short_detail, short_detail, detail, plain_detail, image, start_at, finish_at, campaign_type, amount, owner, status, campaign_code, max_participant, max_join_for_holders, cardtype } = data;
      
    let formData = new FormData();
    formData.append('type', 'campaign');
    formData.append('status', status ?? 'ACTIVE');
    formData.append('caption', 'caption');
    formData.append('short_detail', short_detail);
    formData.append('s_short_detail', s_short_detail);
    plain_detail && formData.append('plain_detail', plain_detail);
    formData.append('detail', detail);
    formData.append('image', image);
    formData.append('start_at', start_at.toISOString());
    formData.append('finish_at', finish_at.toISOString());
    formData.append('campaign_type', campaign_type);
    formData.append('amount', amount);
    formData.append('owner', owner);
    formData.append('max_participant', max_participant);
    formData.append('max_join_for_holders', max_join_for_holders);
    if (campaign_code.type === campaignCodeTypes.FIXED) {
      formData.append('is_campaign_codes_stabil', true);
    }
    else {
      formData.append('is_campaign_codes_stabil', false);
    }
    if (campaign_code.type === campaignCodeTypes.GENERATE) {
      formData.append('is_campaign_codes_generated', true);
    }
    else {
      formData.append('is_campaign_codes_generated', false);
    }
    if (campaign_code.type === campaignCodeTypes.UPLOAD) {
      formData.append('is_campaign_codes_uploaded', true);
    }
    else {
      formData.append('is_campaign_codes_uploaded', false);
    }
    if (cardtype !== '11111111111')
      formData.append('cardtype', cardtype);
    if (max_participant === '0')
      formData.append('isLimitless', true)
    else
      formData.append('isLimitless', false)

    const res = yield call(apiCall,
      'post',
      'api/loyalty/campaign',
      formData
    );

    if (campaign_code?.type && res.data.data._id) {
      if (campaign_code.type === campaignCodeTypes.GENERATE && max_participant) {
        yield call(apiCall,
          'get',
          `api/loyalty/campaign/setCampaignCodes/${res.data.data._id}/${max_participant}`
        );

      } else if (campaign_code.type === campaignCodeTypes.UPLOAD && campaign_code?.excel) {
        const ccFormData = new FormData();
        ccFormData.append('excel', campaign_code.excel);
        yield call(apiCall,
          'post',
          `api/loyalty/campaign/setCampaignCodes/${res.data.data._id}`,
          ccFormData
        );

      } else if (campaign_code.type === campaignCodeTypes.FIXED) {
        const fixed_campaign_code =  campaign_code?.fixed_code ? campaign_code.fixed_code : `TUZLA0${Math.random().toString(36).substr(2, 5).toUpperCase()}`;
        yield call(apiCall,
          'post',
          `api/loyalty/campaign/setCampaignCodes/${res.data.data._id}`,
          {
            unused_campaign_codes: [fixed_campaign_code],
          }
        );
      }
    }

    yield put(actions.processTKAdminCampsSucceeded());
    yield call(customSwal,
      'Başarılı',
      'Kampanya başarıyla oluşturuldu',
      'success'
    );
    yield call(handleSetShowCampsAddModal, false);
    yield put(actions.getTKAdminCampsRequest({ page, searchFormData, status: action.payload.status }));
  } catch (err) {
    yield put(actions.processTKAdminCampsFailed());
    yield call(customSwal,
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}

function* watchEditTKAdminCamps() {
  yield takeLatest(EDIT_TK_ADMIN_CAMPS_REQUEST, editTKAdminCamps);
}

function* editTKAdminCamps(action) {
  try {
    const { data, selectedId, page, searchFormData, handleSetShowCampsEditModal, smsFlag } = action.payload;
    const { s_short_detail, short_detail, detail, plain_detail, image, start_at, finish_at, campaign_type, amount, owner, status, campaign_code, max_participant, max_join_for_holders, cardtype } = data;

    let formData = new FormData();
    formData.append('status', status);
    formData.append('short_detail', short_detail);
    formData.append('s_short_detail', s_short_detail);
    formData.append('detail', detail);
    plain_detail && formData.append('plain_detail', plain_detail);
    image && formData.append('image', image);
    formData.append('start_at', start_at.toISOString());
    formData.append('finish_at', finish_at.toISOString());
    formData.append('campaign_type', campaign_type);
    formData.append('amount', amount);
    formData.append('owner', owner);
    formData.append('max_participant', max_participant);
    formData.append('max_join_for_holders', max_join_for_holders);
  
    if (cardtype !== undefined && cardtype !== '11111111111')
      formData.append('cardtype', cardtype);
    if (cardtype === undefined)
      formData.append('cardtype', '');

    if (max_participant === '0')
      formData.append('isLimitless', true)
    else
      formData.append('isLimitless', false)

    yield call(apiCall,
      'put',
      `api/loyalty/campaign/${selectedId}`,
      formData
    );

  
    if (smsFlag) {

      yield call(apiCall,
        'post',
        'api/sms/applicationcampaignresult',
        {
          owner,
          status
        },
      );

    }
    yield put(actions.processTKAdminCampsSucceeded());
    yield call(customSwal,
      'Başarılı',
      'Kampanya başarıyla güncellendi',
      'success'
    );
    yield call(handleSetShowCampsEditModal, false);
    yield put(actions.getTKAdminCampsRequest({ page, searchFormData, status: action.payload.status }));
  } catch (err) {
    console.log(err)
    yield put(actions.processTKAdminCampsFailed());
    yield call(customSwal,
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}

function* watchDeleteTKAdminCamps() {
  yield takeLatest(DELETE_TK_ADMIN_CAMPS_REQUEST, deleteTKAdminCamps);
}

function* deleteTKAdminCamps(action) {
  try {
    const { selectedId, page, searchFormData, status } = action.payload;
    yield call(apiCall,
      'put',
      `api/loyalty/campaign/${selectedId}/delete`
    );
    yield put(actions.processTKAdminCampsSucceeded());
    yield call(customSwal,
      'Başarılı',
      'Kampanya başarıyla silindi',
      'success'
    );
    yield put(actions.setShowCampsDeleteModal(false));
    yield put(actions.getTKAdminCampsRequest({ page, searchFormData, status }));
  } catch (err) {
    yield put(actions.processTKAdminCampsFailed());
    yield call(customSwal,
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}

function* watchPostTKCampaignsExcelExport() {
  yield takeLatest(POST_TK_CAMPS_EXPORT_EXCEL_REQUEST, postTKCampaignsExcelExport);
}


function* postTKCampaignsExcelExport(action) {

  try {
    const { searchFormData } = action.payload;
    const { detail, short_detail, status } = searchFormData;

    const res = yield call(apiCall,
      'post',
      'api/loyalty/campaign/export',
      {
        detail,
        short_detail,
        status
      },
      {}
      ,
      'blob'
    );

    download(res.data, "kampanyalar.xlsx", "text/xlsx")

  } catch (err) {
    console.log(err)
  }
}


function* watchGetTKCampaignParticipantExcelExport() {
  yield takeLatest(GET_TK_CAMPS_PARTICIPANT_EXPORT_EXCEL_REQUEST, getTKCampsParticipantExportExcel);
}


function* getTKCampsParticipantExportExcel(action) {

  try {
    const id = action.payload;


    const res = yield call(apiCall,
      'get',
      `api/report/exportCampaignParticipants/${action.payload}`,
      {
      },
      {}
      ,
      'blob'
    );

    download(res.data, "kampanyakatilim.xlsx", "text/xlsx")

  } catch (err) {
    console.log(err)
  }
}






export default function* campaignsSaga() {
  yield all([
    fork(watchGetTKCamps),
    fork(watchGetTKTimedCamps),
    fork(watchGetTKCampsById),
    fork(watchGetTKAdminCamps),
    fork(watchCreateTKAdminCamps),
    fork(watchEditTKAdminCamps),
    fork(watchDeleteTKAdminCamps),
    fork(watchPostTKCampaignsExcelExport),
    fork(watchGetTKCampaignParticipantExcelExport)
  ]);
}