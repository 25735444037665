import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TKDetailsImage from '../../../../../components/TKDetailsImage/TKDetailsImage';
import TKDetailsHeader from '../../../../../components/TKDetailsHeader/TKDetailsHeader';
import TKButton from '../../../../../components/TKButton/TKButton';
import TKDetailsContent from '../../../../../components/TKDetailsContent/TKDetailsContent';
import { getTKNewsByIdRequest } from '../../../../../store/actions/news';
import TKLoaderContainer from '../../../../../components/TKLoaderContainer/TKLoaderContainer';

const NewsDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { displayNews, loading } = useSelector(state => state.news);

  useEffect(() => {
    dispatch(getTKNewsByIdRequest(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <>
      {loading && <TKLoaderContainer />}
      {!loading &&
        <div className='container'>
          <TKDetailsImage
            src={displayNews?.image ?
              `${process.env.REACT_APP_IMAGES_URL}${displayNews.image}` :
              'https://media-cdn.tripadvisor.com/media/photo-m/1280/1b/33/f6/60/caption.jpg'
            }
          />

          <TKDetailsContent
            title={displayNews?.short_detail}
            desc={displayNews?.detail}
            isNews="true"
          />
        </div>
      }
    </>
  );
};

export default NewsDetails;