import React from "react";
import "./AuthenticationLayout.scss";

const AuthenticationLayout = ({ children }) => {
  return (
    <div className="auth-layout d-flex flex-column justify-content-center align-items-center">
      {children}
    </div>
  );
};

export default AuthenticationLayout;
