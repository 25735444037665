import * as types from '../actionTypes';

// ADMIN
// LIST
export const getTKAdminBannersRequest = reqParams => ({
  type: types.GET_TK_ADMIN_BANNERS_REQUEST,
  payload: reqParams
});

export const getTKAdminBannersSucceeded = ({data}) => ({
  type: types.GET_TK_ADMIN_BANNERS_SUCCEEDED,
  payload: {
    data,
  }
});
export const getTKAdminBannersFailed = () => ({
  type: types.GET_TK_ADMIN_BANNERS_FAILED
});


export const getTKAdminBannersContentRequest = reqParams => ({
  type: types.GET_TK_ADMIN_BANNERS_CONTENT_REQUEST,
  payload: reqParams
});

export const getTKAdminBannersContentSucceeded = ({data}) => ({
  type: types.GET_TK_ADMIN_BANNERS_CONTENT_REQUEST_SUCCEDED,
  payload: {
    data,
  }
});
export const getTKAdminBannersContentFailed = () => ({
  type: types.GET_TK_ADMIN_BANNERS_CONTENT_REQUEST_FAILED
});


// CREATE
export const createTKAdminBannersRequest = reqParams => ({
  type: types.CREATE_TK_ADMIN_BANNERS_REQUEST,
  payload: reqParams
});

export const setShowBannersAddModal = show => ({
  type: types.SET_SHOW_BANNERS_ADD_MODAL,
  payload: show
});

// DELETE
export const deleteTKAdminBannersRequest = reqParams => ({
  type: types.DELETE_TK_ADMIN_BANNERS_REQUEST,
  payload: reqParams
});

export const setShowBannersDeleteModal = show => { return {
  type: types.SET_SHOW_BANNERS_DELETE_MODAL,
  payload: show
}};


// EDIT
export const editTKAdminBannersRequest = reqParams => ({
  type: types.EDIT_TK_ADMIN_BANNERS_REQUEST,
  payload: reqParams
});

export const setShowBannersEditModal = show => ({
  type: types.SET_SHOW_BANNERS_EDIT_MODAL,
  payload: show
});



export const processTKAdminBannersSucceeded = () => ({
  type: types.PROCESS_TK_ADMIN_BANNERS_SUCCEEDED
});

export const processTKAdminBannersFailed = () => ({
  type: types.PROCESS_TK_ADMIN_BANNERS_FAILED
});
