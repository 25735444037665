import React from 'react';
import './TKModal.scss';
import { Modal } from 'react-bootstrap';
import TKButton from '../../TKButton/TKButton';
import TKLoader from '../../TKLoaderContainer/TKLoader/TKLoader';
import { Link } from 'react-router-dom';

const TKModal = ({backdropClassName, className, show, setShow, size, header, saveBtnTitle, saveBtnVariant, saveOnClick, processLoading, children, cancelButtonHide, saveButtonHide, cancelBtnTitle, downloadButtonUrl}) => {
  const handleOnHide = () => {
    !processLoading && setShow(false);
  }

  return (
    <Modal
      className={`tk_modal ${className}`}
      backdropClassName={backdropClassName}
      show={show}
    //  onHide={handleOnHide}
      onHide={() => {}}
      size={size}
      centered
      enforceFocus={false}
    >
      {header && 
        <Modal.Header>
          <Modal.Title>
            {header}
          </Modal.Title>
        </Modal.Header>
      }
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
      {downloadButtonUrl && 
        <TKButton variant="secondary" loading={processLoading}>
          <a href={downloadButtonUrl} download style={{color: "white", fontSize:"13px"}}>İndir</a>
        </TKButton>
        }
       {!cancelButtonHide && 
        <TKButton variant="light" onClick={handleOnHide} loading={processLoading}>
          {cancelBtnTitle}
        </TKButton>
        }
        {!saveButtonHide && <TKButton variant={saveBtnVariant} onClick={saveOnClick} loading={processLoading}>
          {saveBtnTitle}
          {processLoading && <TKLoader size="sm"/>}
        </TKButton>}
      </Modal.Footer>
    </Modal>
  );
};

export default TKModal;

TKModal.defaultProps = {
  backdropClassName: "",
  className: "",
  show: false,
  setShow: () => {},
  size: "md",
  header: "",
  saveBtnTitle: "kaydet",
  cancelBtnTitle: "iptal",
  processLoading: false,
  cancelButtonHide:false,
  saveButtonHide:false
}