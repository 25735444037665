import * as types from '../actionTypes';

// ADMIN
// LIST
export const getTKAdminCHSRequest = reqParams => ({
  type: types.GET_TK_ADMIN_CHS_REQUEST,
  payload: reqParams
});

export const getTKAdminCHSSucceeded = ({data, page, totalPages, searchFormData}) => ({
  type: types.GET_TK_ADMIN_CHS_SUCCEEDED,
  payload: {
    data,
    page,
    searchFormData,
    totalPages
  }
});

export const getTKAdminCHSFailed = () => ({
  type: types.GET_TK_ADMIN_CHS_FAILED
});

// CREATE
export const createTKAdminCHSRequest = reqParams => ({
  type: types.CREATE_TK_ADMIN_CHS_REQUEST,
  payload: reqParams
});

export const setShowCHSAddModal = show => ({
  type: types.SET_SHOW_CHS_ADD_MODAL,
  payload: show
});

// EDIT
export const editTKAdminCHSRequest = reqParams => ({
  type: types.EDIT_TK_ADMIN_CHS_REQUEST,
  payload: reqParams
});

export const setShowCHSEditModal = show => ({
  type: types.SET_SHOW_CHS_EDIT_MODAL,
  payload: show
});

// DELETE
export const deleteTKAdminCHSRequest = reqParams => ({
  type: types.DELETE_TK_ADMIN_CHS_REQUEST,
  payload: reqParams
});

export const setShowCHSDeleteModal = show => ({
  type: types.SET_SHOW_CHS_DELETE_MODAL,
  payload: show
});

export const processTKAdminCHSSucceeded = () => ({
  type: types.PROCESS_TK_ADMIN_CHS_SUCCEEDED
});

export const processTKAdminCHSFailed = () => ({
  type: types.PROCESS_TK_ADMIN_CHS_FAILED
});

// BALANCE
export const setShowCHSBalanceModal = show => ({
  type: types.SET_SHOW_CHS_BALANCE_MODAL,
  payload: show
});


// CARD
// SET
export const setTKAdminCardList = list => ({
  type: types.SET_TK_ADMIN_CARD_LIST,
  payload: list
});

// CREATE
export const createTKAdminCardRequest = reqParams => ({
  type: types.CREATE_TK_ADMIN_CARD_REQUEST,
  payload: reqParams
});

export const setShowCardAddModal = show => ({
  type: types.SET_SHOW_CARD_ADD_MODAL,
  payload: show
});

// EDIT
export const editTKAdminCardRequest = reqParams => ({
  type: types.EDIT_TK_ADMIN_CARD_REQUEST,
  payload: reqParams
});

export const setShowCardEditModal = show => ({
  type: types.SET_SHOW_CARD_EDIT_MODAL,
  payload: show
});

// UPDATE ALL
export const updateTKAdminAllCardsRequest = reqParams => ({
  type: types.UPDATE_TK_ADMIN_ALL_CARDS_REQUEST,
  payload: reqParams
});

// DELETE
export const deleteTKAdminCardRequest = reqParams => ({
  type: types.DELETE_TK_ADMIN_CARD_REQUEST,
  payload: reqParams
});

export const setShowCardDeleteModal = show => ({
  type: types.SET_SHOW_CARD_DELETE_MODAL,
  payload: show
});

export const processTKAdminCardSucceeded = () => ({
  type: types.PROCESS_TK_ADMIN_CARD_SUCCEEDED
});

export const processTKAdminCardFailed = () => ({
  type: types.PROCESS_TK_ADMIN_CARD_FAILED
});

// LOAD MONEY
export const setShowLoadMoneyModal = show => ({
  type: types.SET_SHOW_LOAD_MONEY_MODAL,
  payload: show
});
export const loadMoneyTKAdminCardRequest = reqParams => ({
  type: types.LOAD_MONEY_TK_ADMIN_CHS_REQUEST,
  payload: reqParams
});

export const isCardNumberAlreadyUsedRequest = reqParams => ({
  type: types.IS_CARD_NUMBER_ALREADY_USED_REQUEST,
  payload: reqParams
});

export const isCardNumberAlreadyUsedResponse = ({isUsed}) => ({
  type: types.IS_CARD_NUMBER_ALREADY_USED_RESPONSE,
  payload: {
    isUsed
  }
});

export const isCardNumberAlreadyUsedFailed = () => ({
  type: types.IS_CARD_NUMBER_ALREADY_USED_FAILED
});


export const isTcknAlreadyUsedRequest = reqParams => ({
  type: types.IS_TCKN_ALREADY_USED_REQUEST,
  payload: reqParams
});

export const isTcknAlreadyUsedResponse = ({isUsed}) => ({
  type: types.IS_TCKN_ALREADY_USED_RESPONSE,
  payload: {
    isUsed
  }
});

export const isTcknAlreadyUsedFailed = () => ({
  type: types.IS_TCKN_ALREADY_USED_FAILED
});


export const postTKCHSExportExcelRequest = reqParams => ({
  type: types.POST_TK_CHS_EXPORT_EXCEL_REQUEST,
  payload: reqParams
});

export const tkCHSForgotPasswordRequest = (data) => ({
  type: types.TK_CHS_FORGOTPASSWORD_REQUEST,
  payload: data
})

export const tkCHSForgotPasswordSucceeded = (data) => ({
  type: types.TK_CHS_FORGOTPASSWORD_SUCCEEDED,
  payload: data
})

export const tkCHSForgotPasswordFailed = () => ({
  type: types.TK_CHS_FORGOTPASSWORD_FAILED
})

export const tkCHSNewPasswordRequest = (data) => ({
  type: types.TK_CHS_NEWPASSWORD_REQUEST,
  payload: data
})

export const tkCHSNewPasswordSucceeded = () => ({
  type: types.TK_CHS_NEWPASSWORD_SUCCEEDED,

})

export const tkCHSNewPasswordFailed = () => ({
  type: types.TK_CHS_NEWPASSWORD_FAILED
})

