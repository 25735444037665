import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "@redux-saga/core/effects";
import { apiCall } from "../../helpers/api";
import * as actions from "../actions/cardHolders";
import {
  GET_TK_ADMIN_CHS_REQUEST,
  CREATE_TK_ADMIN_CHS_REQUEST,
  EDIT_TK_ADMIN_CHS_REQUEST,
  DELETE_TK_ADMIN_CHS_REQUEST,
  CREATE_TK_ADMIN_CARD_REQUEST,
  EDIT_TK_ADMIN_CARD_REQUEST,
  DELETE_TK_ADMIN_CARD_REQUEST,
  LOAD_MONEY_TK_ADMIN_CHS_REQUEST,
  UPDATE_TK_ADMIN_ALL_CARDS_REQUEST,
  IS_CARD_NUMBER_ALREADY_USED_REQUEST,
  IS_TCKN_ALREADY_USED_REQUEST,
  POST_TK_CHS_EXPORT_EXCEL_REQUEST,
  TK_CHS_FORGOTPASSWORD_REQUEST,
  TK_CHS_NEWPASSWORD_REQUEST,
} from "../actionTypes";
import { customSwal } from "../../helpers/customSwal";
import download from "downloadjs";
import { toDate } from "date-fns";

function* watchGetTKAdminCHS() {
  yield takeEvery(GET_TK_ADMIN_CHS_REQUEST, getTKAdminCHS);
}

function* getTKAdminCHS(action) {
  const SIZE = 50;
  try {
    const { page, searchFormData } = action.payload;
    const {
      tckn,
      name,
      surname,
      card,
      cardtype,
      role,
      status,
      created_start,
      created_end,
      profession,
      sex,
      organization,
    } = searchFormData;
    const res = yield call(apiCall, "post", "api/survey/users", {
      page,
      tckn,
      name,
      surname,
      role,
      card,
      cardtype,
      status,
      size: SIZE,
      created_start,
      created_end,
      profession,
      sex,
      organization,
    });
    yield put(
      actions.getTKAdminCHSSucceeded({
        data: [...res.data.data],
        page,
        searchFormData,
        totalPages: Math.ceil(res.data.totalCount / SIZE),
      })
    );
  } catch (err) {
    yield put(actions.getTKAdminCHSFailed());
  }
}

function* watchCreateTKAdminCHS() {
  yield takeLatest(CREATE_TK_ADMIN_CHS_REQUEST, createTKAdminCHS);
}

function* createTKAdminCHS(action) {
  try {
    const { data, page, searchFormData, handleSetShowCHSAddModal } =
      action.payload;
    const { number, type, magnetic_code, ...user } = data;
    const newUser = {
      ...user,
      cards: [
        {
          number,
          type,
          magnetic_code,
          status: "ACTIVE",
        },
      ],
    };
    yield call(apiCall, "post", "api/survey/users/createuser", newUser);
    yield put(actions.processTKAdminCHSSucceeded());
    yield call(
      customSwal,
      "Başarılı",
      "kart sahibi başarıyla oluşturuldu",
      "success"
    );
    yield call(handleSetShowCHSAddModal, false);
    yield put(actions.getTKAdminCHSRequest({ page, searchFormData }));
  } catch (err) {
    yield put(actions.processTKAdminCHSFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchEditTKAdminCHS() {
  yield takeLatest(EDIT_TK_ADMIN_CHS_REQUEST, editTKAdminCHS);
}

function* editTKAdminCHS(action) {
  try {
    const {
      data,
      selectedId,
      page,
      searchFormData,
      smsFlag,
      handleSetShowCHSEditModal,
    } = action.payload;

    //todo approved_at today olarak data eklenecek...

    yield call(apiCall, "post", `api/survey/users/updateUser/${selectedId}`, {
      ...data,
      approved_at: toDate,
    });
    if (smsFlag) {
      yield call(apiCall, "post", "api/sms/applicationuserresult", {
        id: selectedId,
        status: data.status,
      });
    }
    yield put(actions.processTKAdminCHSSucceeded());
    yield call(
      customSwal,
      "Başarılı",
      "kart sahibi başarıyla güncellendi",
      "success"
    );
    yield call(handleSetShowCHSEditModal, false);
    yield put(actions.getTKAdminCHSRequest({ page, searchFormData }));
  } catch (err) {
    yield put(actions.processTKAdminCHSFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchDeleteTKAdminCHS() {
  yield takeLatest(DELETE_TK_ADMIN_CHS_REQUEST, deleteTKAdminCHS);
}

function* deleteTKAdminCHS(action) {
  try {
    const { selectedId, rejectreason, currentStatus, page, searchFormData } =
      action.payload;
    yield call(apiCall, "delete", `api/survey/users/deleteUser/${selectedId}`, {
      status: currentStatus === "WFA" ? "CANCELED" : undefined,
      rejectreason,
    });
    yield put(actions.processTKAdminCHSSucceeded());
    yield call(customSwal, "Başarılı", "Kart başarıyla silindi", "success");
    yield put(actions.setShowCHSDeleteModal(false));
    yield put(actions.getTKAdminCHSRequest({ page, searchFormData }));
  } catch (err) {
    yield put(actions.processTKAdminCHSFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchCreateTKAdminCard() {
  yield takeLatest(CREATE_TK_ADMIN_CARD_REQUEST, createTKAdminCard);
}

// CARDS
function* createTKAdminCard(action) {
  try {
    const {
      data,
      userId,
      page,
      searchFormData,
      list,
      handleSetShowCardAddModal,
    } = action.payload;

    // activate new card passive active card
    let passiveCardList = list.map((cardItem) => {
      cardItem.status = "PASSIVE";
      return cardItem;
    });
    const newCards = [...passiveCardList, { ...data, status: "ACTIVE" }];

    yield call(
      apiCall,
      "post",
      `api/survey/users/addEditDeleteCardToUser/${userId}`,
      newCards
    );
    yield put(actions.processTKAdminCardSucceeded(newCards));
    yield call(customSwal, "Başarılı", "Kart başarıyla oluşturuldu", "success");
    yield call(handleSetShowCardAddModal, false);
    yield put(actions.getTKAdminCHSRequest({ page, searchFormData }));
  } catch (err) {
    console.log(err);
    yield put(actions.processTKAdminCardFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchEditTKAdminCard() {
  yield takeLatest(EDIT_TK_ADMIN_CARD_REQUEST, editTKAdminCard);
}

function* editTKAdminCard(action) {
  try {
    const {
      data,
      selectedId,
      page,
      searchFormData,
      userId,
      list,
      handleSetShowCardEditModal,
    } = action.payload;
    const newCards = list.map((card) =>
      card._id !== selectedId ? card : { ...card, ...data }
    );
    yield call(
      apiCall,
      "post",
      `api/survey/users/addEditDeleteCardToUser/${userId}`,
      newCards
    );
    yield put(actions.processTKAdminCardSucceeded());
    yield call(customSwal, "Başarılı", "Kart başarıyla güncellendi", "success");
    yield call(handleSetShowCardEditModal, false);
    yield put(actions.getTKAdminCHSRequest({ page, searchFormData }));
  } catch (err) {
    yield put(actions.processTKAdminCardFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchUpdateTKAdminAllCards() {
  yield takeLatest(UPDATE_TK_ADMIN_ALL_CARDS_REQUEST, updateTKAdminAllCards);
}

function* updateTKAdminAllCards(action) {
  try {
    const { data, page, searchFormData, userId } = action.payload;
    yield call(
      apiCall,
      "post",
      `api/survey/users/addEditDeleteCardToUser/${userId}`,
      data
    );
    yield put(actions.processTKAdminCardSucceeded());
    yield call(customSwal, "Başarılı", "Kart başarıyla güncellendi", "success");
    yield put(actions.getTKAdminCHSRequest({ page, searchFormData }));
  } catch (err) {
    yield put(actions.processTKAdminCardFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchDeleteTKAdminCard() {
  yield takeLatest(DELETE_TK_ADMIN_CARD_REQUEST, deleteTKAdminCard);
}

function* deleteTKAdminCard(action) {
  try {
    const { selectedId, userId, list, page, searchFormData } = action.payload;
    const newCards = list.filter((card) => card._id !== selectedId);
    yield call(
      apiCall,
      "post",
      `api/survey/users/addEditDeleteCardToUser/${userId}`,
      newCards
    );
    yield put(actions.processTKAdminCardSucceeded());
    yield call(customSwal, "Başarılı", "Kart başarıyla silindi", "success");
    yield put(actions.setShowCardDeleteModal(false));
    yield put(actions.getTKAdminCHSRequest({ page, searchFormData }));
  } catch (err) {
    yield put(actions.processTKAdminCardFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchLoadMoneyTKAdminCard() {
  yield takeLatest(LOAD_MONEY_TK_ADMIN_CHS_REQUEST, loadMoneyTKAdminCard);
}

function* loadMoneyTKAdminCard(action) {
  try {
    const { data, page, handleSetShowLoadMoneyModal, searchFormData } =
      action.payload;
    const { type, excel, amount, tckn, loadType, explanation } = data;

    let url = "";
    let formData = new FormData();

    if (loadType === "ORGANIZATION") {
      formData.append("type", type);
      formData.append("explanation", explanation);
    } else if (loadType === "TCKN_FILE") {
      formData.append("excel", excel);
      formData.append("explanation", explanation);
    } else if (loadType === "TCKN") {
      formData = {
        tckn,
        explanation,
      };
    }
    if (amount === "") {
      url = `api/survey/users/deposit/1`;
    } else {
      url = `api/survey/users/deposit/` + String(amount);
    }

    const res = yield call(apiCall, "post", url, formData, {}, "blob");

    if (loadType === "ORGANIZATION") {
      download(res.data, "yuklemeler.xlsx", "text/xlsx");
    }

    yield put(actions.processTKAdminCHSSucceeded());
    yield call(customSwal, "Başarılı", "Yükleme gerçekleşti", "success");
    yield put(actions.setShowCardDeleteModal(false));
    yield call(handleSetShowLoadMoneyModal, false);
    yield put(actions.getTKAdminCHSRequest({ page, searchFormData }));
  } catch (err) {
    console.log(err);
    yield put(actions.processTKAdminCHSFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchIsCardNumberAlreadyUsed() {
  yield takeEvery(IS_CARD_NUMBER_ALREADY_USED_REQUEST, isCardNumberAlreadyUsed);
}

function* isCardNumberAlreadyUsed(action) {
  const SIZE = 10;
  try {
    const { cardNumber } = action.payload;

    const res = yield call(apiCall, "post", "api/survey/users/card", {
      card: cardNumber,
    });

    yield put(
      actions.isCardNumberAlreadyUsedResponse({
        isUsed: res.data.data ? "Bu kart kullanılmaktadır" : "",
      })
    );
  } catch (err) {
    yield put(actions.getTKAdminCHSFailed());
  }
}

function* watchIsTcknAlreadyUsed() {
  yield takeEvery(IS_TCKN_ALREADY_USED_REQUEST, isTcknAlreadyUsed);
}

function* isTcknAlreadyUsed(action) {
  try {
    const { tckn } = action.payload;

    const res = yield call(apiCall, "post", "api/survey/users/checktckn", {
      tckn,
    });

    yield put(
      actions.isTcknAlreadyUsedResponse({
        isUsed: res.data.data ? "Bu tckn kullanılmaktadır" : "",
      })
    );
  } catch (err) {
    yield put(actions.getTKAdminCHSFailed());
  }
}

function* watchPostTKCHSExcelExport() {
  yield takeLatest(POST_TK_CHS_EXPORT_EXCEL_REQUEST, postTKCHSExcelExport);
}

function* postTKCHSExcelExport(action) {
  try {
    const { searchFormData } = action.payload;
    const {
      tckn,
      name,
      surname,
      status,
      cardtype,
      created_start,
      created_end,
      sex,
      profession,
    } = searchFormData;

    const res = yield call(
      apiCall,
      "post",
      "api/survey/users/export",
      {
        tckn,
        name,
        surname,
        status,
        cardtype,
        created_end,
        created_start,
        sex,
        profession,
      },
      {},
      "blob"
    );

    download(res.data, "kartsahipleri.xlsx", "text/xlsx");
  } catch (err) {
    console.log(err);
  }
}

function* watchForgotPassword() {
  yield takeLatest(TK_CHS_FORGOTPASSWORD_REQUEST, forgotpassword);
}

function* forgotpassword(action) {
  try {
    const { data, handleForgotPasswordModal, handleNewPasswordModal } =
      action.payload;
    const { tckn } = data;

    const res = yield call(apiCall, "post", "forgotpassword", {
      tckn,
    });

    yield put(
      actions.tkCHSForgotPasswordSucceeded({
        data: {
          tckn,
        },
      })
    );
    yield call(
      customSwal,
      "Başarılı",
      "Doğrulama kodu telefonunuza gönderilmiştir",
      "success"
    );
    yield call(handleForgotPasswordModal, false);
    yield call(handleNewPasswordModal, true, "newpassword");
  } catch (err) {
    console.log(err);
    yield put(actions.tkCHSForgotPasswordFailed());
    if (err.response.data.errorCode == 406) {
      yield call(
        customSwal,
        "Hata",
        "Lütfen 'Kayıt Ol' ekranından bilgilerinizi güncelleyiniz",
        "info"
      );
    } else if (err.response.data.errorCode == 455) {
      yield call(
        customSwal,
        "Hata",
        "Sayın Firma Yetkilisi, süreci devam eden başvurunuz bulunduğu için işleminiz gerçekleştirilememektedir. Başvuru sonucunuz SMS ile tarafınıza bildirilecektir. İyi günler dileriz. Detaylı bilgi için; 444 09 06",
        "info"
      );
    } else {
      yield call(customSwal, "Hata", "Doğrulama kodu gönderilemedi", "error");
    }
  }
}

function* watchNewPassword() {
  yield takeLatest(TK_CHS_NEWPASSWORD_REQUEST, newPassword);
}

function* newPassword(action) {
  try {
    const { data, handleNewPasswordModal } = action.payload;
    const { validation_code, password, tckn, vkn } = data;

    console.log(handleNewPasswordModal);

    yield call(apiCall, "post", "forgotpassword", {
      vkn,
      tckn,
      validation_code,
      password,
    });

    yield put(actions.tkCHSNewPasswordSucceeded());
    yield call(
      customSwal,
      "Başarılı",
      "Şifre yenileme tamamlanmıştır",
      "success"
    );
    yield call(handleNewPasswordModal, false);
  } catch (err) {
    console.log(err);
    yield put(actions.tkCHSNewPasswordFailed());
    yield call(
      customSwal,
      "Hata",
      "Şifre yenileme sırasında sorun oluştu",
      "error"
    );
  }
}

export default function* cardHoldersSaga() {
  yield all([
    fork(watchGetTKAdminCHS),
    fork(watchCreateTKAdminCHS),
    fork(watchEditTKAdminCHS),
    fork(watchDeleteTKAdminCHS),
    fork(watchCreateTKAdminCard),
    fork(watchEditTKAdminCard),
    fork(watchDeleteTKAdminCard),
    fork(watchLoadMoneyTKAdminCard),
    fork(watchUpdateTKAdminAllCards),
    fork(watchIsCardNumberAlreadyUsed),
    fork(watchIsTcknAlreadyUsed),
    fork(watchPostTKCHSExcelExport),
    fork(watchForgotPassword),
    fork(watchNewPassword),
  ]);
}
