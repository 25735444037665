import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import * as actions from "../actions/tkns";
import {
  GET_TK_TKN_ADMIN_REQUEST,
  DELETE_TK_ADMIN_TKN_REQUEST,
  CREATE_TK_ADMIN_TKN_REQUEST,
  EDIT_TK_ADMIN_TKN_REQUEST,
} from "../actionTypes";
import { apiCall } from "../../helpers/api";
import { customSwal } from "../../helpers/customSwal";

import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

function* watchGetTKTKNs() {
  yield takeEvery(GET_TK_TKN_ADMIN_REQUEST, getTKTKNs);
}

function* watchCreateTKTKN() {
  yield takeEvery(CREATE_TK_ADMIN_TKN_REQUEST, createTKTKN);
}

function* watchEditTKTKN() {
  yield takeEvery(EDIT_TK_ADMIN_TKN_REQUEST, editTKTKN);
}

function* watchDeleteTKTKN() {
  yield takeEvery(DELETE_TK_ADMIN_TKN_REQUEST, deleteTKTKN);
}

function* getTKTKNs(action) {
  try {
    const res = yield call(apiCall, "get", "public/tuzlakartnedir");
    yield put(
      actions.getTKTKNAdminSucceeded({
        data: [...res.data.data],
      })
    );
  } catch (err) {
    console.log(err);
    yield put(actions.getTKTKNAdminFailed());
  }
}

function* createTKTKN(action) {
  const { data } = action.payload;
  const { caption, detail, order_number } = data;
  try {
    const res = yield call(apiCall, "post", "api/loyalty/tuzlakartnedir", {
      type: "tuzlakartnedir",
      caption,
      detail: JSON.stringify(convertToRaw(data.detail.getCurrentContent())),
      order_number,
    });
    yield put(actions.setShowTKNAddModal(false));
    yield put(actions.getTKTKNAdminRequest());
  } catch (err) {
    console.log(err);
    yield put(actions.getTKTKNAdminFailed());
  }
}

function* editTKTKN(action) {
  const { data, selectedId } = action.payload;
  const { caption, detail, order_number } = data;

  try {
    const res = yield call(
      apiCall,
      "put",
      `api/loyalty/tuzlakartnedir/${selectedId}`,
      {
        type: "tuzlakartnedir",
        caption,
        detail:
          typeof data.detail == "object"
            ? JSON.stringify(convertToRaw(data.detail.getCurrentContent()))
            : data.detail,
        order_number,
      }
    );
    yield put(actions.setShowTKNEditModal(false));
    yield put(actions.getTKTKNAdminRequest());
  } catch (err) {
    console.log(err);
    yield put(actions.getTKTKNAdminFailed());
  }
}

function* deleteTKTKN(action) {
  try {
    const { selectedId } = action.payload;
    yield call(
      apiCall,
      "put",
      `api/loyalty/tuzlakartnedir/${selectedId}/delete`
    );
    yield call(customSwal, "Başarılı", "Kampanya başarıyla silindi", "success");
    yield put(actions.setShowTKNDeleteModal(false));
    yield put(actions.getTKTKNAdminRequest());
  } catch (err) {
    yield put(actions.getTKTKNAdminFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

export default function* tknsSaga() {
  yield all([
    fork(watchGetTKTKNs),
    fork(watchCreateTKTKN),
    fork(watchEditTKTKN),
    fork(watchDeleteTKTKN),
  ]);
}
