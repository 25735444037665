import * as types from '../actionTypes';


const initialState = {
    loading: false,
    admin: {
        list: [],
        page: 0,
        totalPages: 0,
        processLoading: false,
        processError: false,
        showViewModal: false
      }
  }


const complains = (state = initialState, action) => {

    switch(action.type) {

   case types.GET_TK_COMPLAIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_COMPLAIN_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: false,
        admin: {
          ...state.admin,
          list: [...action.payload.data],
          page: action.payload.page,
          totalPages: action.payload.totalPages
        }
      }
    case types.GET_TK_COMPLAIN_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case types.SET_SHOW_COMPLAIN_VIEW_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showViewModal: action.payload
        }
      }
      case types.SEEN_TK_COMPLAIN_REQUEST:
        return {
          ...state,
          loading: true,
          error: false
        }

      default:
            return state;
    
    }
}

export default complains;