import React from 'react';
import './TKBannerSlider.scss';
import TKSlider from '../TKSliderContainer/TKSlider/TKSlider';
import { useHistory } from 'react-router-dom';

const TKBannerSlider = ({banners}) => {
  let history = useHistory();

  const redirect = (type,id,link) => {
    if (type ==="campaign") {
      history.push('/kampanyalar/'+id)
    }
    if (type ==="news") {
      history.push('/haberler/'+id)
    }
    if (link !== "" && link !== null) {
      window.location.href = link; 
    }
    
  }
  
  return (
    <div className="tk_banner-slider">
      <TKSlider customSettings={{ arrows: true }}>
        {banners.map(({image, caption,link, banner}, i) => 
          <div key={i}>
            <div
              className="bg-img tk_banner-slider-img position-relative"
              style={{
                cursor:'pointer',
                backgroundImage: `url(${image ? 
                  `${process.env.REACT_APP_IMAGES_URL}${image}` : 
                  'https://media-cdn.tripadvisor.com/media/photo-m/1280/1b/33/f6/60/caption.jpg'}
                )`
              }}
              onClick={()=>{redirect(banner?.type,banner?._id,link)}}
              >            
            </div>
          </div>
        )}
      </TKSlider>
    </div>
  );
};

export default TKBannerSlider;

TKBannerSlider.defaultProps = {
  images: []
}