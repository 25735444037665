import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TKAddNewButton from "../../../AdminLayout/TKAddNewButton/TKAddNewButton";
import TKTableCard from "../../../AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../TKLoaderContainer/TKLoader/TKLoader";
import {
  getTKMWPCampsRequest,
  createTKMWPCampsRequest,
  setShowCampsAddModal,
  editTKMWPCampsRequest,
  setShowCampsEditModal,
  deleteTKMWPCampsRequest,
  setShowCampsDeleteModal,
} from "../../../../store/actions/memberWorkplaceCampaigns";
import { mwpCampaignsColumns } from "../../../../constants/columnsConfigurations";
import TKContentForm from "../../../AdminLayout/TKForm/TKContentForm/TKContentForm";
import { validate } from "../../../../helpers/validate";
import TKModal from "../../../AdminLayout/TKModal/TKModal";
import { formatUrl } from "../../../../helpers/utilities";

const TKWorkplaceCampaigns = () => {
  const DEFAULT_TYPE = "PERCENT";
  const DEFAULT_STATUS = "WFA";
  const DEFAULT_CAMPAIGN_CODE_TYPE = "GENERATE";

  const initialFormData = {
    short_detail: "",
    detail: "",
    plain_detail: "",
    image: null,
    start_at: null,
    finish_at: null,
    campaign_type: DEFAULT_TYPE,
    amount: "",
    // owner: null,
    // status: DEFAULT_STATUS,
    max_participant: "0",
    max_join_for_holders: "1",
    campaign_code: {
      type: DEFAULT_CAMPAIGN_CODE_TYPE,
      excel: null,
      fixed_code: null,
    },
  };

  const initialFormDataValidate = {
    short_detail: {
      required: true,
    },
    detail: {
      required: true,
    },
    image: {
      required: true,
    },
    start_at: {
      required: true,
    },
    finish_at: {
      required: true,
    },
    campaign_type: {
      required: true,
    },
    amount: {
      required: true,
      isNumeric: true,
      isPercent: "campaign_type",
    },
    // owner: {
    //   required: true,
    // },
    // status: {
    //   required: true,
    // },
    campaign_code: {
      isValidCampaignCodeGenerator: true,
    },
  };

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.memberWorkplaceCampaigns);
  const {
    list,
    page,
    search,
    status,
    totalPages,
    processLoading,
    showAddModal,
    showEditModal,
    showDeleteModal,
  } = useSelector((state) => state.memberWorkplaceCampaigns.mwp);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [formDataValidate, setFormDataValidate] = useState({ ...initialFormDataValidate });
  const user = useSelector(state => state.auth.userInfo);

  useEffect(() => {
    filterData(page, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doSearch = (value, stat) => {
    dispatch(
      getTKMWPCampsRequest({
        page: 0,
        search: value,
        status: stat,
      })
    );
  };

  const filterData = (page) => {
    dispatch(
      getTKMWPCampsRequest({
        page,
        search,
        status,
      })
    );
  };

  const setIfItIsBlank = () => {

    if (formData.max_participant === '') {

      setFormData(prevState => ({
        ...prevState,
        max_participant: 0
      }));

    }


    if (formData.max_join_for_holders === '') {

      setFormData(prevState => ({
        ...prevState,
        max_join_for_holders: 1
      }));

    }



  }

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkValidation = () => {
    console.log(formData);
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  const handleSetShowCampsAddModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataErrors({});
    }
    dispatch(setShowCampsAddModal(show));
  };

  const handleSaveOnClick = () => {
    const check = checkValidation();


    const company = user.company;
    if (check && company) {
/*
    //check is card type selected
    if(!formData.cardtype){
      setFormDataErrors({"hata":"Lütfen kart tipi seçiniz. Kampanyanız tüm kartlara uygulamak için TUZLA KART seçeneğini seçiniz. "} );
return
    }
*/

      dispatch(
        createTKMWPCampsRequest({
          data: {
            ...formData,
            short_detail: formData.short_detail.trim(),
            s_short_detail: formatUrl(formData.short_detail.trim()),
            owner: company._id,
          },
          page,
          search,
          handleSetShowCampsAddModal,
        })
      );
    }
  };

  const handleSetShowCampsEditModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataValidate({ ...initialFormDataValidate });
      setFormDataErrors({});
    }
    dispatch(setShowCampsEditModal(show));
  };

  const handleShowEditModalClick = (row) => {
    const {
      _id,
      short_detail,
      detail,
      start_at,
      finish_at,
      campaign_type,
      amount,
      cardtype,
      max_participant, max_join_for_holders, image, isLimitless,campaign_code,is_campaign_codes_stabil,is_campaign_codes_generated,is_campaign_codes_uploaded
    } = row;
    setSelectedId(_id);
    setFormData((prevState) => ({
      short_detail,
      detail,
      start_at: new Date(start_at),
      finish_at: new Date(finish_at),
      campaign_type: campaign_type || prevState.campaign_type,
      amount,
      max_participant,
      max_join_for_holders,
      image: null,
      isLimitless,
      cardtype,
      campaign_code,
      is_campaign_codes_stabil,
      is_campaign_codes_uploaded,
      is_campaign_codes_generated,
      previousimage: image
    }));
    setFormDataValidate((prevState) => ({
      ...prevState,
      image: {},
    }));
    handleSetShowCampsEditModal(true);
  };

  const handleEditOnClick = () => {
    const check = checkValidation();
    console.log(check);
    const company = user.company;
    if (check && company) {
      dispatch(
        editTKMWPCampsRequest({
          data: {
            ...formData,
            short_detail: formData.short_detail.trim(),
            s_short_detail: formatUrl(formData.short_detail.trim()),
            owner: company._id,
          },
          selectedId,
          page,
          search,
          handleSetShowCampsEditModal,
        })
      );
    }
  };

  // const handleSetShowCampsDeleteModal = (show) => {
  //   dispatch(setShowCampsDeleteModal(show));
  // };

  // const handleShowDeleteModalClick = (id) => {
  //   setSelectedId(id);
  //   handleSetShowCampsDeleteModal(true);
  // };

  // const handleDeleteOnClick = () => {
  //   dispatch(
  //     deleteTKMWPCampsRequest({
  //       selectedId,
  //       page,
  //       search,
  //       status,
  //     })
  //   );
  // };

  const campaignsColumns = mwpCampaignsColumns({
    onEdit: (row) => handleShowEditModalClick(row),
  });

  return (
    <>
    
      <TKAddNewButton
        showModal={showAddModal}
        setShowModal={handleSetShowCampsAddModal}
        btnTitle="kampanya"
        modalHeader="kampanya başvuru formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
        size="xl"
      >
        <TKContentForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isPublic={true}
          setIfItIsBlank={setIfItIsBlank}
        />
      </TKAddNewButton>
      <TKTableCard>
        {!loading && (
          <TKDataTable
            columns={campaignsColumns}
            data={list}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            handleFetchData={filterData}
            searchedText={search}
            pagination={false}
          />
        )}
        {loading && <TKLoader />}
      </TKTableCard>
      <TKModal
        show={showEditModal}
        setShow={handleSetShowCampsEditModal}
        header="kampanya güncelleme formu"
        saveBtnTitle="güncelle"
        saveOnClick={handleEditOnClick}
        processLoading={processLoading}
        size="xl"
      >
        <TKContentForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isPublic={true}
        />
      </TKModal>
      {/* <TKModal
        show={showDeleteModal}
        setShow={handleSetShowCampsDeleteModal}
        header="kampanya silme işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        Kampanya silmek üzeresiniz, onaylıyor musunuz?
      </TKModal> */}
    </>
  );
};

export default TKWorkplaceCampaigns;
