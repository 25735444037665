import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAccordion from "../../../../components/TKAccordion/TKAccordion";
import TKUserInfo from "../../../../components/TKLoginContainer/TKUserInfo/TKUserInfo";
import TKPageHeader from '../../../../components/TKPageHeader/TKPageHeader';
import TKTabs from '../../../../components/TKTabs/TKTabs';
import { 
  getTKGKNAdminRequest
}
from "../../../../store/actions/gkns";

const MyAccount = () => {

  return (
    <>
        <div className="">
         <TKPageHeader header="Hesabım"/>
          <div className="container">
              <TKUserInfo />
          </div>
         </div>
      </>
  );
};

export default MyAccount;