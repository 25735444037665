import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { apiCall } from "../../helpers/api";
import * as actions from "../actions/memberWorkplaces";
import {
  GET_TK_MEMBER_WORKPLACES_REQUEST,
  GET_TK_MWP_BY_ID_REQUEST,
  GET_TK_MWP_NAMES_REQUEST,
  GET_TK_ADMIN_MWP_REQUEST,
  CREATE_TK_ADMIN_MWP_REQUEST,
  EDIT_TK_ADMIN_MWP_REQUEST,
  DELETE_TK_ADMIN_MWP_REQUEST,
  PREREGISTER_TK_MEMBER_WORKPLACES_REQUEST,
  POST_TK_MWP_EXPORT_EXCEL_REQUEST,
  TK_MWP_FORGOTPASSWORD_REQUEST,
  TK_MWP_NEWPASSWORD_REQUEST,
  EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_REQUEST,
  EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_WITHOUT_FILE_REQUEST,
} from "../actionTypes";
import { customSwal } from "../../helpers/customSwal";
import download from "downloadjs";

function* watchGetTKMemberWorkplaces() {
  yield takeEvery(GET_TK_MEMBER_WORKPLACES_REQUEST, getTKMemberWorkplaces);
}

function* getTKMemberWorkplaces(action) {
  try {
    const res = yield call(
      apiCall,
      "post",
      "public/company/getPaginationCompanies",
      {
        ...action.payload,
        size: 50,
      }
    );
    yield put(
      actions.getTKMemberWorkplacesSucceeded({
        data: [...res.data.data],
        page: action.payload.page,
        totalCount: res.data.totalCount,
      })
    );
  } catch (err) {
    yield put(actions.getTKMemberWorkplacesFailed());
  }
}

function* watchPreregisterTKMemberWorkplaces() {
  yield takeEvery(
    PREREGISTER_TK_MEMBER_WORKPLACES_REQUEST,
    preregisterTKMemberWorkplaces
  );
}

function* preregisterTKMemberWorkplaces(action) {
  try {
    const { data, handleShowModal } = action.payload;
    const {
      name,
      surname,
      personalEmail,
      vkn,
      entitle,
      managerphone,
      s_entitle,
      address,
      phone,
      email,
      web_site,
      image,
      detail,
      category,
      discounts,
      amount,
      position,
      landingPhone,
      province,
      district,
      isOnline,
      rejection,
      deactivation_date,
      contract_date
    } = data;

    console.log(data);

    let formData = new FormData();

    formData.append("name", name);
    formData.append("surname", surname);
    formData.append("personalEmail", personalEmail);

    formData.append("vkn", vkn);
    formData.append("entitle", entitle);
    formData.append("s_entitle", s_entitle);
    formData.append("address", address);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("web_site", web_site);
    formData.append("image", image);
    for (let item of category) {
      formData.append("category", item);
    }
    formData.append("status", "WFA");
    formData.append("isOnline", isOnline ?? "false");
    formData.append("rejection", rejection ?? "");
    formData.append("deactivation_date", deactivation_date ?? new Date(1900,0,1));
    formData.append("contract_date", contract_date ?? new Date(1900,0,1));
    formData.append("contract_status", "no");
    formData.append("getir_yemeksepeti_link", "no");
    formData.append("socail_media_accounts", "no");
    formData.append("commercial_title", "no");
    formData.append("detail", detail);
    formData.append("amount", amount);
    formData.append("managerphone", managerphone);
    formData.append("landingPhone", landingPhone);
    formData.append("discounts", JSON.stringify(discounts));
    formData.append("province", province);
    formData.append("district", district);

    if (position?.lat !== undefined && position?.lng !== undefined) {
      formData.append("map_lat", position.lat);
      formData.append("map_long", position.lng);
    }

    yield call(apiCall, "post", "public/company", formData);

    yield call(handleShowModal, false);
    yield put(actions.preregisterTKMemberWorkplacesSucceeded());
    yield call(
      customSwal,
      "Başarılı",
      "Başvuru talebiniz alınmıştır. Kurum tarafından onay beklenilmektedir. Onay sonrası Sms ile bilgilendirme sağlanacaktır. Detaylı Bilgi İçin; 444 09 06",
      "success"
    );
  } catch (err) {
    yield put(actions.preregisterTKMemberWorkplacesFailed());
    if (err.response.data.error.indexOf("duplicate key") != -1) {
      yield call(
        customSwal,
        "Hata",
        "Bu vergi kimlik numarasıyla daha önce kayıt yapılmıştır",
        "info"
      );
    } else {
      yield call(
        customSwal,
        "Hata",
        "Hata oluştu lütfen daha sonra tekrar deneyin",
        "error"
      );
    }
  }
}

function* watchGetTKMWPById() {
  yield takeEvery(GET_TK_MWP_BY_ID_REQUEST, getTKMWPById);
}

function* getTKMWPById(action) {
  try {
    const res = yield call(
      apiCall,
      "get",
      `public/company/entitle/${action.payload}`
    );
    yield put(actions.getTKMWPByIdSucceeded({ ...res.data.data }));
  } catch (err) {
    yield put(actions.getTKMWPByIdFailed());
  }
}

function* watchGetTKMWPNames() {
  yield takeEvery(GET_TK_MWP_NAMES_REQUEST, getTKMWPNames);
}

function* getTKMWPNames(action) {
  const categories = action.payload;
  try {
    const res =
      categories && categories.length > 0
        ? yield call(
            apiCall,
            "post",
            "public/company/getCompaniesWithCategory",
            {
              categories,
            }
          )
        : yield call(apiCall, "get", "public/company/getCompanyNames");
    yield put(actions.getTKMWPNamesSucceeded([...res.data.data]));
  } catch (err) {
    yield put(actions.getTKMWPNamesFailed());
  }
}

function* watchGetTKAdminMWP() {
  yield takeEvery(GET_TK_ADMIN_MWP_REQUEST, getTKAdminMWP);
}

function* getTKAdminMWP(action) {
  const SIZE = 50;
  try {
    const { page, searchFormData } = action.payload;
    const { vkn, commercial_title, entitle, status } = searchFormData;
    const res = yield call(
      apiCall,
      "post",
      "api/loyalty/company/getPaginationCompanies",
      {
        page,
        vkn,
        entitle,
        commercial_title,
        status,
        size: SIZE,
      }
    );
    yield put(
      actions.getTKAdminMWPSucceeded({
        data: [...res.data.data],
        page,
        vkn,
        entitle,
        commercial_title,
        status,
        totalPages: Math.ceil(res.data.totalCount / SIZE),
      })
    );
  } catch (err) {
    yield put(actions.getTKAdminMWPFailed());
  }
}

function* watchCreateTKAdminMWP() {
  yield takeLatest(CREATE_TK_ADMIN_MWP_REQUEST, createTKAdminMWP);
}

function* createTKAdminMWP(action) {
  try {
    const { data, page, search, handleSetShowMWPAddModal } = action.payload;
    const {
      name,
      surname,
      personalEmail,
      vkn,
      entitle,
      s_entitle,
      address,
      managerphone,
      phone,
      email,
      web_site,
      image,
      detail,
      category,
      general_discount_type,
      status,
      isOnline,
      rejection,
      deactivation_date,
      contract_date,
      position,
      discounts,
      landingPhone,
      province,
      district,
    } = data;
    let formData = new FormData();
    formData.append("name", name);
    formData.append("surname", surname);
    formData.append("personalEmail", personalEmail);

    formData.append("vkn", vkn);
    formData.append("entitle", entitle);
    formData.append("s_entitle", s_entitle);
    formData.append("address", address);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("web_site", web_site);
    formData.append("image", image);
    for (let item of category) {
      formData.append("category", item);
    }
    formData.append("status", status ?? "ACTIVE");
    formData.append("isOnline", isOnline ?? "false");
    formData.append("rejection", rejection ?? "");
    formData.append("deactivation_date", status == "PASSIVE" ? new Date() : new Date(1900,0,1) );
    formData.append("contract_date", contract_date ?? new Date(1900,0,1));
    formData.append("general_discount_type", general_discount_type);
    formData.append("contract_status", "no");
    formData.append("getir_yemeksepeti_link", "no");
    formData.append("socail_media_accounts", "no");
    formData.append("commercial_title", "no");
    formData.append("detail", detail);
    formData.append("managerphone", managerphone);
    formData.append("landingPhone", landingPhone);
    formData.append("discounts", JSON.stringify(discounts));

    formData.append("province", province);
    formData.append("district", district);
    if (position?.lat !== undefined && position?.lng !== undefined) {
      formData.append("map_lat", position.lat);
      formData.append("map_long", position.lng);
    }

    yield call(apiCall, "post", "api/loyalty/company", formData);
    yield put(actions.processTKAdminMWPSucceeded());
    yield call(
      customSwal,
      "Başarılı",
      "Üye işyeri başarıyla oluşturuldu",
      "success"
    );
    yield call(handleSetShowMWPAddModal, false);
    yield put(
      actions.getTKAdminMWPRequest({
        page,
        status: action.payload.status,
        search,
      })
    );
  } catch (err) {
    yield put(actions.processTKAdminMWPFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchEditTKAdminMWP() {
  yield takeLatest(EDIT_TK_ADMIN_MWP_REQUEST, editTKAdminMWP);
}

function* editTKAdminMWP(action) {
  try {
    const {
      data,
      selectedId,
      page,
      searchFormData,
      handleSetShowMWPEditModal,
      smsFlag,
    } = action.payload;
    const {
      name,
      surname,
      personalEmail,
      vkn,
      entitle,
      s_entitle,
      address,
      managerphone,
      phone,
      email,
      web_site,
      image,
      detail,
      category,
      status,
      position,
      discounts,
      landingPhone,
      province,
      district,
      rejection,
      deactivation_date,
      contract_date
    } = data;

    let formData = new FormData();
    formData.append("name", name);
    formData.append("surname", surname);
    formData.append("personalEmail", personalEmail ? personalEmail : "");
    formData.append("vkn", vkn);
    formData.append("entitle", entitle);
    formData.append("s_entitle", s_entitle);
    formData.append("address", address);
    formData.append("managerphone", managerphone);
    formData.append("landingPhone", landingPhone);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("web_site", web_site ? web_site : "");
    formData.append("detail", detail);
    formData.append("status", status);
    formData.append("rejection", rejection ? rejection : "");
    formData.append("deactivation_date", status == "PASSIVE" ? new Date() : new Date(1900,0,1) );
    formData.append("contract_date", contract_date ?? new Date(1900,0,1));
    formData.append("discounts", JSON.stringify(discounts));
    formData.append("province", province.toString());
    formData.append("district", district.toString());
    image && formData.append("image", image);
    for (let item of category) {
      formData.append("category", item);
    }

    if (position?.lat !== undefined && position?.lng !== undefined) {
      formData.append("map_lat", position.lat);
      formData.append("map_long", position.lng);
    }

    yield call(apiCall, "put", `api/loyalty/company/${selectedId}`, formData);
    if (smsFlag) {
      yield call(apiCall, "post", "api/sms/applicationcompanyresult", {
        id: selectedId,
        status,
        vkn,
      });
    }
    yield put(actions.processTKAdminMWPSucceeded());
    yield call(
      customSwal,
      "Başarılı",
      "Üye işyeri başarıyla güncellendi",
      "success"
    );
    yield call(handleSetShowMWPEditModal, false);
    yield put(
      actions.getTKAdminMWPRequest({
        page,
        status: action.payload.status,
        searchFormData,
      })
    );
  } catch (err) {
    yield put(actions.processTKAdminMWPFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchDeleteTKAdminMWP() {
  yield takeLatest(DELETE_TK_ADMIN_MWP_REQUEST, deleteTKAdminMWP);
}

function* deleteTKAdminMWP(action) {
  try {
    const {
      selectedId,
      statucomment,
      vkn,
      currentStatus,
      page,
      status,
      searchFormData,
    } = action.payload;
    yield call(apiCall, "put", `api/loyalty/company/${selectedId}/delete`, {
      status: currentStatus === "WFA" ? "CANCELED" : undefined,
      statucomment,
    });

    // Sent VKN job...
    if (currentStatus === "WFA") {
      yield call(apiCall, "post", "api/sms/applicationcompanyresult", {
        id: selectedId,
        status,
        vkn,
      });
    }

    let message = "";

    if (currentStatus === "WFA") {
      message = "Üye işyeri başvurusu red edildi";
    } else {
      message = "Üye işyeri silindi";
    }

    yield put(actions.processTKAdminMWPSucceeded());
    yield call(customSwal, "Başarılı", message, "success");
    yield put(actions.setShowMWPDeleteModal(false));
    yield put(actions.getTKAdminMWPRequest({ page, status, searchFormData }));
  } catch (err) {
    yield put(actions.processTKAdminMWPFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchPostTKMWPExcelExport() {
  yield takeLatest(POST_TK_MWP_EXPORT_EXCEL_REQUEST, postTKMWPExcelExport);
}

function* postTKMWPExcelExport(action) {
  try {
    const { searchFormData } = action.payload;
    const { vkn, commercial_title, entitle, status } = searchFormData;

    const res = yield call(
      apiCall,
      "post",
      "api/loyalty/company/export",
      {
        vkn,
        commercial_title,
        entitle,
        status,
      },
      {},
      "blob"
    );

    console.log(res.data);

    download(res.data, "uyeisyerleri_export.xlsx", "text/xlsx");
  } catch (err) {
    console.log(err);
  }
}

function* watchForgotPassword() {
  yield takeLatest(TK_MWP_FORGOTPASSWORD_REQUEST, forgotpassword);
}

function* forgotpassword(action) {
  try {
    const { data, handleForgotPasswordModal, handleNewPasswordModal } =
      action.payload;
    const { vkn } = data;

    yield call(apiCall, "post", "forgotpassword", {
      vkn,
    });

    yield put(
      actions.tkMWPForgotPasswordSucceeded({
        data: {
          vkn,
        },
      })
    );
    yield call(
      customSwal,
      "Başarılı",
      "Doğrulama kodu telefonunuza gönderilmiştir",
      "success"
    );
    yield call(handleForgotPasswordModal, false);
    yield call(handleNewPasswordModal, true, "newpassword");
  } catch (err) {
    console.log(err);
    yield put(actions.tkMWPForgotPasswordFailed());
    yield call(customSwal, "Hata", "Doğrulama kodu gönderilemedi", "error");
  }
}

function* watchNewPassword() {
  yield takeLatest(TK_MWP_NEWPASSWORD_REQUEST, newPassword);
}

function* newPassword(action) {
  try {
    const { data, handleNewPasswordModal } = action.payload;
    const { validation_code, password, vkn } = data;

    console.log(handleNewPasswordModal);

    yield call(apiCall, "post", "forgotpassword", {
      vkn,
      validation_code,
      password,
    });

    yield put(actions.tkMWPNewPasswordSucceeded());
    yield call(
      customSwal,
      "Başarılı",
      "Şifre yenileme tamamlanmıştır",
      "success"
    );
    yield call(handleNewPasswordModal, false);
  } catch (err) {
    console.log(err);
    yield put(actions.tkMWPNewPasswordFailed());
    yield call(
      customSwal,
      "Hata",
      "Şifre yenileme sırasında sorun oluştu",
      "error"
    );
  }
}

function* watchExportCompanyDiscountParticipants() {
  yield takeLatest(
    EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_REQUEST,
    exportCompanyDiscountParticipants
  );
}

function* exportCompanyDiscountParticipants(action) {
  try {
    console.log(action.payload);

    let res;

    const { formDatePickerData } = action.payload;
    const { start_at, finish_at } = formDatePickerData;

    if (action.payload.selectedId !== undefined) {
      res = yield call(
        apiCall,
        "post",
        `api/report/exportCompanyDiscountParticipants/${action.payload.selectedId}`,
        {
          start_at,
          finish_at,
        },
        {},
        "blob"
      );
    } else {
      const { formDatePickerData, vkn, commercial_title, entitle, status } =
        action.payload;
      const { start_at, finish_at } = formDatePickerData;

      console.log("Requesting....");

      res = yield call(
        apiCall,
        "post",
        `api/report/exportCompanyDiscountParticipants`,
        {
          start_at,
          finish_at,
          vkn,
          commercial_title,
          entitle,
          status,
        },
        {},
        "blob"
      );

      console.log("Requesting end....");
    }

    download(res.data, "uyeisyerikatilim.xlsx", "text/xlsx");
  } catch (err) {
    console.log(err);
  }
}

function* watchExportCompanyDiscountParticipantsWithoutFile() {
  yield takeLatest(
    EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_WITHOUT_FILE_REQUEST,
    exportCompanyDiscountParticipantsWithoutFile
  );
}

function* exportCompanyDiscountParticipantsWithoutFile(action) {
  try {
    console.log(action.payload);

    let res;

    if (action.payload.selectedId !== undefined) {
      res = yield call(
        apiCall,
        "post",
        `api/report/exportCompanyDiscountParticipantsWithoutFile/${action.payload.selectedId}`,
        {
          start_at: action.payload.start_at,
          finish_at: action.payload.finish_at,
        }
      );
    }

    yield put(
      actions.exportTKCompanyDiscountExportExcelWithoutFileSucceeded({
        data: [...res.data.data],
      })
    );
  } catch (err) {
    console.log(err);
    yield put(actions.exportTKCompanyDiscountExportExcelWithoutFileFailed());
  }
}

export default function* memberWorkplacesSaga() {
  yield all([
    fork(watchGetTKMemberWorkplaces),
    fork(watchGetTKMWPById),
    fork(watchGetTKMWPNames),
    fork(watchGetTKAdminMWP),
    fork(watchCreateTKAdminMWP),
    fork(watchEditTKAdminMWP),
    fork(watchDeleteTKAdminMWP),
    fork(watchPreregisterTKMemberWorkplaces),
    fork(watchPostTKMWPExcelExport),
    fork(watchForgotPassword),
    fork(watchNewPassword),
    fork(watchExportCompanyDiscountParticipants),
    fork(watchExportCompanyDiscountParticipantsWithoutFile),
  ]);
}
