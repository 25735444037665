import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "../../helpers/api";
import * as actions from '../actions/transactions';
import { 
  GET_TK_TRANSACTIONS_REQUEST
} from '../actionTypes';
import { customSwal } from '../../helpers/customSwal';


function* watchGetTransactions() {
  yield takeLatest(GET_TK_TRANSACTIONS_REQUEST, getTransactions);
}

function* getTransactions(action) {
  try {
    const res = yield call(apiCall, 
      'get',
      'api/profile/transaction/user',
    );
    
    yield put(actions.tkGetTransactionsSucceeded([...res.data.data]));
  } catch (err) {
    console.log(err);
    yield put(actions.tkGetTransactionsFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}


export default function* transactionsSaga() {
  yield all([
    fork(watchGetTransactions),
  ])
}
