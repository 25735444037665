import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { apiCall } from "../../helpers/api";
import * as actions from "../actions/news";
import {
  GET_TK_NEWS_REQUEST,
  GET_TK_NEWS_BY_ID_REQUEST,
  GET_TK_ADMIN_NEWS_REQUEST,
  CREATE_TK_ADMIN_NEWS_REQUEST,
  EDIT_TK_ADMIN_NEWS_REQUEST,
  DELETE_TK_ADMIN_NEWS_REQUEST,
  POST_TK_NEWS_EXPORT_EXCEL_REQUEST,
} from "../actionTypes";
import { customSwal } from "../../helpers/customSwal";
import download from "downloadjs";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

function* watchGetTKNews() {
  yield takeEvery(GET_TK_NEWS_REQUEST, getTKNews);
}

function* getTKNews(action) {
  try {
    const { page } = action.payload;
    const res = yield call(apiCall, "post", "public/news/getPaginationNews", {
      page,
      size: 20,
    });
    yield put(
      actions.getTKNewsSucceeded({
        data: [...res.data.data],
        totalCount: res.data.totalCount,
      })
    );
  } catch (err) {
    console.log(err);
    yield put(actions.getTKNewsFailed());
  }
}

function* watchGetTKNewsById() {
  yield takeEvery(GET_TK_NEWS_BY_ID_REQUEST, getTKNewsById);
}

function* getTKNewsById(action) {
  try {
    const res = yield call(
      apiCall,
      "get",
      `public/news/shortDetail/${action.payload}`
    );
    yield put(actions.getTKNewsByIdSucceeded({ ...res.data.data }));
  } catch (err) {
    yield put(actions.getTKNewsByIdFailed());
  }
}

function* watchGetTKAdminNews() {
  yield takeEvery(GET_TK_ADMIN_NEWS_REQUEST, getTKAdminNews);
}

function* getTKAdminNews(action) {
  const SIZE = 50;
  try {
    const { page, searchFormData } = action.payload;
    const { caption, detail, short_detail, status } = searchFormData;
    const res = yield call(
      apiCall,
      "post",
      "api/loyalty/news/getPaginationNews",
      {
        page,
        size: SIZE,
        caption,
        detail,
        short_detail,
        ...(status ? { status } : {}),
      }
    );
    yield put(
      actions.getTKAdminNewsSucceeded({
        data: [...res.data.data],
        page,
        search: "",
        status,
        totalPages: Math.ceil(res.data.totalCount / SIZE),
      })
    );
  } catch (err) {
    console.log(err);
    yield put(actions.getTKAdminNewsFailed());
  }
}

function* watchCreateTKAdminNews() {
  yield takeLatest(CREATE_TK_ADMIN_NEWS_REQUEST, createTKAdminNews);
}

function* createTKAdminNews(action) {
  try {
    const { data, page, handleSetShowNewsAddModal } = action.payload;
    const {
      caption,
      short_detail,
      detail,
      s_short_detail,
      image,
      start_at,
      finish_at,
      status,
      plain_detail,
      order_number,
    } = data;
    let formData = new FormData();
    formData.append("type", "news");
    formData.append("status", status ? status : "ACTIVE");
    formData.append("caption", caption);
    formData.append("short_detail", short_detail);
    formData.append("s_short_detail", s_short_detail);
    formData.append(
      "detail",
      JSON.stringify(convertToRaw(data.detail.getCurrentContent()))
    );
    formData.append("image", image);
    formData.append("start_at", start_at.toISOString());
    formData.append("finish_at", finish_at.toISOString());
    formData.append("order_number", order_number);

    plain_detail && formData.append("plain_detail", plain_detail);

    yield call(apiCall, "post", "api/loyalty/news", formData);
    yield put(actions.processTKAdminNewsSucceeded());
    yield call(
      customSwal,
      "Başarılı",
      "Haber başarıyla oluşturuldu",
      "success"
    );
    yield call(handleSetShowNewsAddModal, false);
    yield put(
      actions.getTKAdminNewsRequest({
        page,
        searchFormData: action.payload.searchFormData,
        status: action.payload.status,
      })
    );
  } catch (err) {
    yield put(actions.processTKAdminNewsFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchEditTKAdminNews() {
  yield takeLatest(EDIT_TK_ADMIN_NEWS_REQUEST, editTKAdminNews);
}

function* editTKAdminNews(action) {
  try {
    const { data, selectedId, page, handleSetShowNewsEditModal } =
      action.payload;
    const {
      caption,
      short_detail,
      s_short_detail,
      detail,
      image,
      start_at,
      finish_at,
      status,
      plain_detail,
      order_number,
    } = data;
    let formData = new FormData();
    formData.append("status", status);
    formData.append("caption", caption);
    formData.append("short_detail", short_detail);
    formData.append("s_short_detail", s_short_detail);
    formData.append(
      "detail",
      typeof data.detail == "object"
        ? JSON.stringify(convertToRaw(data.detail.getCurrentContent()))
        : data.detail
    );
    image && formData.append("image", image);
    formData.append("start_at", start_at.toISOString());
    formData.append("finish_at", finish_at.toISOString());
    plain_detail && formData.append("plain_detail", plain_detail);
    formData.append("order_number", order_number);

    yield call(apiCall, "put", `api/loyalty/news/${selectedId}`, formData);
    yield put(actions.processTKAdminNewsSucceeded());
    yield call(
      customSwal,
      "Başarılı",
      "Haber başarıyla güncellendi",
      "success"
    );
    yield call(handleSetShowNewsEditModal, false);
    yield put(
      actions.getTKAdminNewsRequest({
        page,
        searchFormData: action.payload.searchFormData,
        status: action.payload.status,
      })
    );
  } catch (err) {
    yield put(actions.processTKAdminNewsFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchDeleteTKAdminNews() {
  yield takeLatest(DELETE_TK_ADMIN_NEWS_REQUEST, deleteTKAdminNews);
}

function* deleteTKAdminNews(action) {
  try {
    const { selectedId, page } = action.payload;
    yield call(apiCall, "put", `api/loyalty/news/${selectedId}/delete`);
    yield put(actions.processTKAdminNewsSucceeded());
    yield call(customSwal, "Başarılı", "Haber başarıyla silindi", "success");
    yield put(actions.setShowNewsDeleteModal(false));
    yield put(
      actions.getTKAdminNewsRequest({
        page,
        search: action.payload.searchFormData,
        status: action.payload.status,
      })
    );
  } catch (err) {
    yield put(actions.processTKAdminNewsFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchPostTKNewsExcelExport() {
  yield takeLatest(POST_TK_NEWS_EXPORT_EXCEL_REQUEST, postTKNewsExcelExport);
}

function* postTKNewsExcelExport(action) {
  try {
    const { searchFormData } = action.payload;
    const { caption, detail, short_detail, status } = searchFormData;

    const res = yield call(
      apiCall,
      "post",
      "api/loyalty/news/export",
      {
        caption,
        detail,
        short_detail,
        status,
      },
      {},
      "blob"
    );

    download(res.data, "haberler.xlsx", "text/xlsx");
  } catch (err) {
    console.log(err);
  }
}

export default function* newsSaga() {
  yield all([
    fork(watchGetTKNews),
    fork(watchGetTKNewsById),
    fork(watchGetTKAdminNews),
    fork(watchCreateTKAdminNews),
    fork(watchEditTKAdminNews),
    fork(watchDeleteTKAdminNews),
    fork(watchPostTKNewsExcelExport),
  ]);
}
