import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getTKMWPEmployeesRequest,
  createTKMWPEmployeesRequest,
  setShowMWPEmployeesAddModal,
  setShowMWPEmployeesDeleteModal,
  deleteTKMWPEmployeesRequest,
} from "../../../../store/actions/memberWorkplaceEmployees";
import TKAddNewButton from "../../../AdminLayout/TKAddNewButton/TKAddNewButton";
import TKTableCard from "../../../AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../AdminLayout/TKDataTable/TKDataTable";
import TKLoader from "../../../TKLoaderContainer/TKLoader/TKLoader";
import { mwpEmployeesColumnsConfig } from "../../../../constants/columnsConfigurations";
import TKMWPEmployeeForm from "../../../AdminLayout/TKForm/TKMWPEmployeeForm/TKMWPEmployeeForm";
import { validate } from "../../../../helpers/validate";
import TKModal from "../../../AdminLayout/TKModal/TKModal";

const TKWorkplaceEmployees = () => {
  const initialFormData = {
    tckn: "",
  };
  const formDataValidate = {
    tckn: {
      required: true,
    },
  };
  const dispatch = useDispatch();
  const { listMWPEmployees, loading } = useSelector((state) => state.memberWorkplaceEmployees);
  const { processLoading, showAddModal, showDeleteModal } = useSelector((state) => state.memberWorkplaceEmployees.mwp);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const user = useSelector(state => state.auth.userInfo);

  useEffect(() => {
    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterData = () => {
    dispatch(getTKMWPEmployeesRequest());
  };

  const handleOnChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkValidation = () => {
    const errorMsgs = validate(formData, formDataValidate);
    if (Object.keys(errorMsgs).length > 0) {
      setFormDataErrors({ ...errorMsgs });
      return false;
    }
    return true;
  };

  const handleSetShowMWPEmployeesAddModal = (show) => {
    if (!show) {
      setFormData({ ...initialFormData });
      setFormDataErrors({});
    }
    dispatch(setShowMWPEmployeesAddModal(show));
  };

  const handleSaveOnClick = () => {
    const check = checkValidation();
    if (check) {
      dispatch(
        createTKMWPEmployeesRequest({
          data: {
            ...formData,
            tckn: formData.tckn.trim(),
          },
          handleSetShowMWPEmployeesAddModal,
        })
      );
    }
  };

  const handleSetShowMWPEmployeesDeleteModal = (show) => {
    dispatch(setShowMWPEmployeesDeleteModal(show));
  };

  const handleShowDeleteModalClick = (id) => {
    if (id === user._id) return;
    setSelectedId(id);
    handleSetShowMWPEmployeesDeleteModal(true);
  };
  const handleDeleteOnClick = () => {
    const selectedTckn = listMWPEmployees.find(u => u._id === selectedId).tckn;
    if(!selectedTckn) return;
    
    dispatch(
      deleteTKMWPEmployeesRequest({
        selectedId,
        selectedTckn
      })
    );
  };

  const mwpEmployeesColumns = mwpEmployeesColumnsConfig(user, {
    onDelete: (id) => handleShowDeleteModalClick(id),
  });

  return (
    <>
      <TKAddNewButton
        showModal={showAddModal}
        setShowModal={handleSetShowMWPEmployeesAddModal}
        btnTitle="Çalışan"
        modalHeader="Çalışan ekleme formu"
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
      >
        <TKMWPEmployeeForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
        />
      </TKAddNewButton>
      <TKTableCard>
        {!loading && (
          <TKDataTable
            columns={mwpEmployeesColumns}
            data={listMWPEmployees}
            sortedColumn={sortedColumn}
            setSortedColumn={setSortedColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            pagination={false}
          />
        )}
        {loading && <TKLoader />}
      </TKTableCard>
      <TKModal
        show={showDeleteModal}
        setShow={handleSetShowMWPEmployeesDeleteModal}
        header="Çalışan silme işlemi"
        saveBtnTitle="sil"
        saveBtnVariant="danger"
        saveOnClick={handleDeleteOnClick}
        processLoading={processLoading}
      >
        Çalışanı silmek üzeresiniz, onaylıyor musunuz?
      </TKModal>
    </>
  );
};

export default TKWorkplaceEmployees;
