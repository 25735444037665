import React from "react";
import './TKUserCircleDropdown.scss';
import { useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import TKIcon from "../../TKIcon/TKIcon";
import { tkLogout } from "../../../store/actions/auth";

const TKUserCircleDropdown = ({ size, iconSize, items, customMenu, userName }) => {
  const dispatch = useDispatch();

  const TKUserCircle = React.forwardRef(({children, onClick}, ref) => (
    <button
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      className="p-0 d-flex justify-content-center align-items-center tk_user-circle-button"
    >
      { userName && <span className="mr-2 text-white"> { userName } </span> }
      <div 
        className="d-flex justify-content-center align-items-end rounded-circle tk_user_circle border-0 overflow-hidden"       
        style={{ width: size, height: size }}
      >
        <TKIcon name="arrow_down" size={iconSize} color="#8c959d" />
      </div>
    </button>
  ))

  return (
    <Dropdown className="tk_user-circle-dropdown">
      <Dropdown.Toggle as={TKUserCircle}/>
      {customMenu ? customMenu : (
        <Dropdown.Menu className="tk_user-circle-dropdown-menu">
          {items}
          <Dropdown.Item 
            onClick={() => dispatch(tkLogout())}
          >
            Çıkış Yap
          </Dropdown.Item>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default TKUserCircleDropdown;
