import React from "react";
import { FormControl } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import TKCheckbox from "../TKCheckbox/TKCheckbox";

const TKCustomFilterDropdownMenu = React.forwardRef(({ items, itemOnClick ,selectedOrganizationNames,title, selectedItems , search,itemSelector,onChange,show }, ref) => (
            <div
          ref={ref}
        >
            
          {show &&
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Filtreleme yapabilirsiniz..."          
            type="text"
            value={search}
            onKeyDown={(e) => onChange(e.key)}
            />
          }
          {show && items.map(
          (item, i) =>
            selectedItems.includes(item._id) && (
              <div key={i} className="tk_dropdown-item">
                <TKCheckbox
                  label={item[itemSelector]}
                  value={item._id}
                  checked={true}
                  onChange={() => itemOnClick(item._id)}
                />
              </div>
            )
        )}
        {Boolean(selectedItems.length) && <hr className="my-1" />}
        {show && items.map(
          (item, i) =>
            !selectedItems.includes(item._id) && (
              <div key={i} className="tk_dropdown-item">
                <TKCheckbox
                  label={item[itemSelector]}
                  value={item._id}
                  checked={false}
                  onChange={() => itemOnClick(item._id)}
                />
              </div>
            )
        )}
        </div>

    )
);

export default TKCustomFilterDropdownMenu;
