import * as types from '../actionTypes';

export const getTKHPCampsRequest = () => ({
  type: types.GET_TK_HP_CAMPS_REQUEST
});

export const getTKHPCampsSucceeded = data => ({
  type: types.GET_TK_HP_CAMPS_SUCCEEDED,
  payload: data
});

export const getTKHPCampsFailed = () => ({
  type: types.GET_TK_HP_CAMPS_FAILED
});

export const getTKHPNewsRequest = () => ({
  type: types.GET_TK_HP_NEWS_REQUEST
});

export const getTKHPNewsSucceeded = data => ({
  type: types.GET_TK_HP_NEWS_SUCCEEDED,
  payload: data
});

export const getTKHPNewsFailed = () => ({
  type: types.GET_TK_HP_NEWS_FAILED
});


export const getTKHPBannersRequest = () => ({
  type: types.GET_TK_HP_BANNERS_REQUEST
});

export const getTKHPBannersSucceeded = data => ({
  type: types.GET_TK_HP_BANNERS_SUCCEEDED,
  payload: data
});

export const getTKHPBannersFailed = () => ({
  type: types.GET_TK_HP_BANNERS_FAILED
});

export const getTKHPStatusRequest = () => ({
  type: types.GET_TK_HP_STATUS_REQUEST
});

export const getTKHPStatusSucceeded = data => ({
  type: types.GET_TK_HP_STATUS_SUCCEEDED,
  payload: data
});

export const getTKHPStatusFailed = () => ({
  type: types.GET_TK_HP_STATUS_FAILED
});

