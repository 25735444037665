import * as types from '../actionTypes';

export const getTKProfessionsRequest = () => ({
  type: types.GET_TK_PROFESSIONS_REQUEST
});

export const getTKProfessionsSucceeded = ({data}) => ({
  type: types.GET_TK_PROFESSIONS_SUCCEEDED,
  payload: {
    data
  }
});

export const getTKProfessionsFailed = () => ({
  type: types.GET_TK_PROFESSIONS_FAILED
});



export const getTKAdminProfessionsRequest = () => ({
  type: types.GET_TK_ADMIN_PROFESSIONS_REQUEST
});

export const getTKAdminProfessionsSucceeded = ({data}) => ({
  type: types.GET_TK_ADMIN_PROFESSIONS_SUCCEEDED,
  payload: {
    data
  }
});

export const getTKAdminProfessionsFailed = () => ({
  type: types.GET_TK_ADMIN_PROFESSIONS_FAILED
});

// CREATE
export const createTKAdminProfessionsRequest = reqParams => ({
  type: types.CREATE_TK_ADMIN_PROFESSIONS_REQUEST,
  payload: reqParams
});

export const setShowProfessionsAddModal = show => ({
  type: types.SET_SHOW_PROFESSIONS_ADD_MODAL,
  payload: show
});

export const processTKAdminProfessionsSucceeded = () => ({
  type: types.PROCESS_TK_ADMIN_PROFESSIONS_SUCCEEDED
});

export const processTKAdminProfessionsFailed = () => ({
  type: types.PROCESS_TK_ADMIN_PROFESSIONS_FAILED
});

// DELETE
export const setShowProfessionsDeleteModal = show => ({
  type: types.SET_SHOW_PROFESSIONS_DELETE_MODAL,
  payload: show
});

export const deleteTKAdminProfessionsRequest = reqParams => ({
  type: types.DELETE_TK_ADMIN_PROFESSIONS_REQUEST,
  payload: reqParams
});

// SEARCH
export const searchTKProfessionsRequest = ({search}) => ({
  type: types.SEARCH_TK_PROFESSIONS_REQUEST,
  payload: {
    search
  }
});

export const searchTKProfessionsSucceeded = data => ({
  type: types.SEARCH_TK_PROFESSIONS_SUCCEEDED,
  payload: data
});

export const searchTKProfessionsFailed = () => ({
  type: types.SEARCH_TK_PROFESSIONS_FAILED
});
