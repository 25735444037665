import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { cardHoldersTypeList } from '../../../../../../constants/general';
import TKCheckbox from '../../../../../TKCheckbox/TKCheckbox';
import { 
  getTKCardTypesRequest
} from "../../../../../../../src/store/actions/cardTypes";

const TKCardsConfigureForm = ({formData, formDataErrors, onChange, isEdit, checkCardNumberAlreadyUsed, cardError}) => {
 
  const {listCardTypes} = useSelector(state => state.cardTypes);
  const dispatch = useDispatch();
  const [mangeticCodeField,setMagneticCodeField] = useState(false);

  useEffect(() => {
    dispatch(getTKCardTypesRequest())
  }, [dispatch])
 
 
  const handleCardTypeOnChange = (name,id) => {
    const type = [...formData?.type];
    const idIndex = type.indexOf(id);
    if (idIndex !== -1) {
      type.splice(idIndex, 1);
      return onChange('type', type);
    }
    onChange('type', [id]);

    if (name === 'GIDA KARTI') {
      setMagneticCodeField(true)
    }
    else {
      setMagneticCodeField(false)
    }
  }
  
  return (
    <Form  onSubmit={e => { e.preventDefault()}}>
        <Form.Group>
          <Form.Label>kart no *</Form.Label>
          <Form.Control
            as={InputMask}
            name="number"
            placeholder="Kart No"
            value={formData.number || ""}
            isInvalid={formDataErrors.number || cardError}
            mask="99999999999999"
            maskChar={null}
            onBlur={(e) => {
              checkCardNumberAlreadyUsed(formData.number)
            }}
            onChange={e => {
              onChange(e.target.name, e.target.value?.replace(/ /g, ''));
            }}
            readOnly={isEdit ? true : false}
          />
          <Form.Text className="text-danger">{formDataErrors?.number || cardError}</Form.Text>
        </Form.Group>
      {(formData?.foodCard || formData?.magnetic_code || mangeticCodeField)&&
        <Form.Group>
          <Form.Label>Manyetik Kod *</Form.Label>
          <Form.Control
            placeholder="Manyetik Kod"
            value={formData.magnetic_code || ""}
            isInvalid={formDataErrors.magnetic_code || cardError}
            onChange={e => {
              onChange('magnetic_code', e.target.value);
            }}
            readOnly={isEdit ? true : false}
          />
          <Form.Text className="text-danger">{formDataErrors?.magnetic_code || cardError}</Form.Text>
      </Form.Group>
      }
      <Form.Group>
        <Form.Label>Kategori *</Form.Label>
        <div className="pl-2">
              {listCardTypes.map(({name,_id}, index) => (
                  <>
                    <Form.Check 
                      disabled={isEdit ? true : false}
                      type="checkbox"
                      id={`admin-user-listCardType-checkbox-${index}`}
                      label={name}
                      key={index}
                      name={name}
                      checked={formData?.type?._id?.includes(_id)}
                      onChange={(e) => handleCardTypeOnChange(e.target.name,_id)}
                    />
                  </>
              ))}
        </div>
        <Form.Text className="text-danger">{formDataErrors?.type}</Form.Text>
      </Form.Group>
    </Form>
  );
};

export default TKCardsConfigureForm;

TKCardsConfigureForm.defaultProps = {
  formData: {},
  formDataErrors: {},
  onChange: () => {}
}