import { format } from "date-fns";
import { tr } from "date-fns/locale";
import parse, { domToReact } from "html-react-parser";

export const formatDate = (date, formatStr = "P pp") => {
  return format(date, formatStr, {
    locale: tr,
  });
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const formDateWithNullChecker = (date, formatStr = "P pp") => {
  if (date != null) {
    return format(new Date(date), formatStr, {
      locale: tr,
    });
  } else {
    return "";
  }
};

export const formatUrl = function (str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîıòóöôùúüûñçşğ·/_,:;";
  var to = "aaaaeeeeiiiiioooouuuuncsg------";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

export const handleRedirect = (history, path, state) =>
  history.push(path, { ...state });
export const returnHandleRedirect = (history, path, state) => {
  history.push(path, { ...state });
  return history;
};

export const getRandomBgColorForCampaign = () => {
  const colors = ["red", "green", "light-blue", "yellow", "dark-blue"];
  const index = Math.floor(Math.random() * colors.length);
  return colors[index];
};

export const htmlStringToPlainTex = (htmlString) => {
  const options = {
    replace: (domNode) => {
      const { data, type, name, children } = domNode;
      if (type === "tag") {
        if (name === "p") {
          return (
            <>
              <br />
              {domToReact(children, options)}
            </>
          );
        }
        return <>{domToReact(children, options)}</>;
      } else {
        return <>{data}</>;
      }
    },
  };

  return parse(htmlString, options);
};

export const sortData = (data, selector, direction) => {
  const ASC = "asc";
  const DESC = "desc";
  if (!direction) {
    return data;
  }

  const customSort = (first, second) => {
    if (first[selector] > second[selector]) {
      return 1;
    } else if (first[selector] < second[selector]) {
      return -1;
    } else {
      return 0;
    }
  };

  const sortedData = [...data].sort(customSort);

  if (direction === DESC) {
    sortedData.reverse();
  }

  return sortedData;
};

export function subtractMonths(date, months) {
  date.setMonth(date.getMonth() - months);
  return date;
}

export const ililce = [
  {
    _id: 34,
    province: "İstanbul",
    district: [
      {
        _id: 30,
        district: "Tuzla",
      },
      {
        _id: 1,
        district: "Adalar",
      },
      {
        _id: 2,
        district: "Bakırköy",
      },
      {
        _id: 3,
        district: "Beşiktaş",
      },
      {
        _id: 4,
        district: "Beykoz",
      },
      {
        _id: 5,
        district: "Beyoğlu",
      },
      {
        _id: 6,
        district: "Çatalca",
      },
      {
        _id: 7,
        district: "Eyüp",
      },
      {
        _id: 8,
        district: "Fatih",
      },
      {
        _id: 9,
        district: "Gaziosmanpaşa",
      },
      {
        _id: 10,
        district: "Kadıköy",
      },
      {
        _id: 11,
        district: "Kartal",
      },
      {
        _id: 12,
        district: "Sarıyer",
      },
      {
        _id: 13,
        district: "Silivri",
      },
      {
        _id: 14,
        district: "Şile",
      },
      {
        _id: 15,
        district: "Şişli",
      },
      {
        _id: 16,
        district: "Üsküdar",
      },
      {
        _id: 17,
        district: "Zeytinburnu",
      },
      {
        _id: 18,
        district: "Büyükçekmece",
      },
      {
        _id: 19,
        district: "Kağıthane",
      },
      {
        _id: 20,
        district: "Küçükçekmece",
      },
      {
        _id: 21,
        district: "Pendik",
      },
      {
        _id: 22,
        district: "Ümraniye",
      },
      {
        _id: 23,
        district: "Bayrampaşa",
      },
      {
        _id: 24,
        district: "Avcılar",
      },
      {
        _id: 25,
        district: "Bağcılar",
      },
      {
        _id: 26,
        district: "Bahçelievler",
      },
      {
        _id: 27,
        district: "Güngören",
      },
      {
        _id: 28,
        district: "Maltepe",
      },
      {
        _id: 29,
        district: "Sultanbeyli",
      },
      {
        _id: 31,
        district: "Esenler",
      },
      {
        _id: 32,
        district: "Arnavutköy",
      },
      {
        _id: 33,
        district: "Ataşehir",
      },
      {
        _id: 34,
        district: "Başakşehir",
      },
      {
        _id: 35,
        district: "Beylikdüzü",
      },
      {
        _id: 36,
        district: "Çekmeköy",
      },
      {
        _id: 37,
        district: "Esenyurt",
      },
      {
        _id: 38,
        district: "Sancaktepe",
      },
      {
        _id: 39,
        district: "Sultangazi",
      },
    ],
  },
  {
    _id: 41,
    province: "Kocaeli",
    district: [
      {
        _id: 40,
        district: "Gebze",
      },
      {
        _id: 41,
        district: "Gölcük",
      },
      {
        _id: 42,
        district: "Kandıra",
      },
      {
        _id: 43,
        district: "Karamürsel",
      },
      {
        _id: 44,
        district: "Körfez",
      },
      {
        _id: 45,
        district: "Derince",
      },
      {
        _id: 46,
        district: "Başiskele",
      },
      {
        _id: 47,
        district: "Çayırova",
      },
      {
        _id: 48,
        district: "Darıca",
      },
      {
        _id: 49,
        district: "Dilovası",
      },
      {
        _id: 50,
        district: "İzmit",
      },
      {
        _id: 51,
        district: "Kartepe",
      },
    ],
  },
  {
    _id: 1,
    province: "Adana",
    district: [
      {
        _id: 52,
        district: "Aladağ",
      },
      {
        _id: 53,
        district: "Ceyhan",
      },
      {
        _id: 54,
        district: "Çukurova",
      },
      {
        _id: 55,
        district: "Feke",
      },
      {
        _id: 56,
        district: "İmamoğlu",
      },
      {
        _id: 57,
        district: "Karaisalı",
      },
      {
        _id: 58,
        district: "Karataş",
      },
      {
        _id: 59,
        district: "Kozan",
      },
      {
        _id: 60,
        district: "Pozantı",
      },
      {
        _id: 61,
        district: "Saimbeyli",
      },
      {
        _id: 62,
        district: "Sarıçam",
      },
      {
        _id: 63,
        district: "Seyhan",
      },
      {
        _id: 64,
        district: "Tufanbeyli",
      },
      {
        _id: 65,
        district: "Yumurtalık",
      },
      {
        _id: 66,
        district: "Yüreğir",
      },
    ],
  },
  {
    _id: 2,
    province: "Adıyaman",
    district: [
      {
        _id: 67,
        district: "Besni",
      },
      {
        _id: 68,
        district: "Çelikhan",
      },
      {
        _id: 69,
        district: "Gerger",
      },
      {
        _id: 70,
        district: "Gölbaşı",
      },
      {
        _id: 71,
        district: "Kahta",
      },
      {
        _id: 72,
        district: "Merkez",
      },
      {
        _id: 73,
        district: "Samsat",
      },
      {
        _id: 74,
        district: "Sincik",
      },
      {
        _id: 75,
        district: "Tut",
      },
    ],
  },
  {
    _id: 3,
    province: "Afyonkarahisar",
    district: [
      {
        _id: 76,
        district: "Başmakçı",
      },
      {
        _id: 77,
        district: "Bayat",
      },
      {
        _id: 78,
        district: "Bolvadin",
      },
      {
        _id: 79,
        district: "Çay",
      },
      {
        _id: 80,
        district: "Çobanlar",
      },
      {
        _id: 81,
        district: "Dazkırı",
      },
      {
        _id: 82,
        district: "Dinar",
      },
      {
        _id: 83,
        district: "Emirdağ",
      },
      {
        _id: 84,
        district: "Evciler",
      },
      {
        _id: 85,
        district: "Hocalar",
      },
      {
        _id: 86,
        district: "İhsaniye",
      },
      {
        _id: 87,
        district: "İscehisar",
      },
      {
        _id: 88,
        district: "Kızılören",
      },
      {
        _id: 89,
        district: "Merkez",
      },
      {
        _id: 90,
        district: "Sandıklı",
      },
      {
        _id: 91,
        district: "Sinanpaşa",
      },
      {
        _id: 92,
        district: "Sultandağı",
      },
      {
        _id: 93,
        district: "Şuhut",
      },
    ],
  },
  {
    _id: 4,
    province: "Ağrı",
    district: [
      {
        _id: 94,
        district: "Diyadin",
      },
      {
        _id: 95,
        district: "Doğubayazıt",
      },
      {
        _id: 96,
        district: "Eleşkirt",
      },
      {
        _id: 97,
        district: "Hamur",
      },
      {
        _id: 98,
        district: "Merkez",
      },
      {
        _id: 99,
        district: "Patnos",
      },
      {
        _id: 100,
        district: "Taşlıçay",
      },
      {
        _id: 101,
        district: "Tutak",
      },
    ],
  },
  {
    _id: 5,
    province: "Amasya",
    district: [
      {
        _id: 102,
        district: "Göynücek",
      },
      {
        _id: 103,
        district: "Gümüşhacıköy",
      },
      {
        _id: 104,
        district: "Hamamözü",
      },
      {
        _id: 105,
        district: "Merkez",
      },
      {
        _id: 106,
        district: "Merzifon",
      },
      {
        _id: 107,
        district: "Suluova",
      },
      {
        _id: 108,
        district: "Taşova",
      },
    ],
  },
  {
    _id: 6,
    province: "Ankara",
    district: [
      {
        _id: 109,
        district: "Altındağ",
      },
      {
        _id: 110,
        district: "Ayaş",
      },
      {
        _id: 111,
        district: "Bala",
      },
      {
        _id: 112,
        district: "Beypazarı",
      },
      {
        _id: 113,
        district: "Çamlıdere",
      },
      {
        _id: 114,
        district: "Çankaya",
      },
      {
        _id: 115,
        district: "Çubuk",
      },
      {
        _id: 116,
        district: "Elmadağ",
      },
      {
        _id: 117,
        district: "Güdül",
      },
      {
        _id: 118,
        district: "Haymana",
      },
      {
        _id: 119,
        district: "Kalecik",
      },
      {
        _id: 120,
        district: "Kızılcahamam",
      },
      {
        _id: 121,
        district: "Nallıhan",
      },
      {
        _id: 122,
        district: "Polatlı",
      },
      {
        _id: 123,
        district: "Şereflikoçhisar",
      },
      {
        _id: 124,
        district: "Yenimahalle",
      },
      {
        _id: 125,
        district: "Gölbaşı",
      },
      {
        _id: 126,
        district: "Keçiören",
      },
      {
        _id: 127,
        district: "Mamak",
      },
      {
        _id: 128,
        district: "Sincan",
      },
      {
        _id: 129,
        district: "Kazan",
      },
      {
        _id: 130,
        district: "Akyurt",
      },
      {
        _id: 131,
        district: "Etimesgut",
      },
      {
        _id: 132,
        district: "Evren",
      },
      {
        _id: 133,
        district: "Pursaklar",
      },
    ],
  },
  {
    _id: 7,
    province: "Antalya",
    district: [
      {
        _id: 134,
        district: "Akseki",
      },
      {
        _id: 135,
        district: "Alanya",
      },
      {
        _id: 136,
        district: "Elmalı",
      },
      {
        _id: 137,
        district: "Finike",
      },
      {
        _id: 138,
        district: "Gazipaşa",
      },
      {
        _id: 139,
        district: "Gündoğmuş",
      },
      {
        _id: 140,
        district: "Kaş",
      },
      {
        _id: 141,
        district: "Korkuteli",
      },
      {
        _id: 142,
        district: "Kumluca",
      },
      {
        _id: 143,
        district: "Manavgat",
      },
      {
        _id: 144,
        district: "Serik",
      },
      {
        _id: 145,
        district: "Demre",
      },
      {
        _id: 146,
        district: "İbradı",
      },
      {
        _id: 147,
        district: "Kemer",
      },
      {
        _id: 148,
        district: "Aksu",
      },
      {
        _id: 149,
        district: "Döşemealtı",
      },
      {
        _id: 150,
        district: "Kepez",
      },
      {
        _id: 151,
        district: "Konyaaltı",
      },
      {
        _id: 152,
        district: "Muratpaşa",
      },
    ],
  },
  {
    _id: 8,
    province: "Artvin",
    district: [
      {
        _id: 153,
        district: "Ardanuç",
      },
      {
        _id: 154,
        district: "Arhavi",
      },
      {
        _id: 155,
        district: "Merkez",
      },
      {
        _id: 156,
        district: "Borçka",
      },
      {
        _id: 157,
        district: "Hopa",
      },
      {
        _id: 158,
        district: "Şavşat",
      },
      {
        _id: 159,
        district: "Yusufeli",
      },
      {
        _id: 160,
        district: "Murgul",
      },
    ],
  },
  {
    _id: 9,
    province: "Aydın",
    district: [
      {
        _id: 161,
        district: "Merkez",
      },
      {
        _id: 162,
        district: "Bozdoğan",
      },
      {
        _id: 163,
        district: "Efeler",
      },
      {
        _id: 164,
        district: "Çine",
      },
      {
        _id: 165,
        district: "Germencik",
      },
      {
        _id: 166,
        district: "Karacasu",
      },
      {
        _id: 167,
        district: "Koçarlı",
      },
      {
        _id: 168,
        district: "Kuşadası",
      },
      {
        _id: 169,
        district: "Kuyucak",
      },
      {
        _id: 170,
        district: "Nazilli",
      },
      {
        _id: 171,
        district: "Söke",
      },
      {
        _id: 172,
        district: "Sultanhisar",
      },
      {
        _id: 173,
        district: "Yenipazar",
      },
      {
        _id: 174,
        district: "Buharkent",
      },
      {
        _id: 175,
        district: "İncirliova",
      },
      {
        _id: 176,
        district: "Karpuzlu",
      },
      {
        _id: 177,
        district: "Köşk",
      },
      {
        _id: 178,
        district: "Didim",
      },
    ],
  },
  {
    _id: 10,
    province: "Balıkesir",
    district: [
      {
        _id: 179,
        district: "Altıeylül",
      },
      {
        _id: 180,
        district: "Ayvalık",
      },
      {
        _id: 181,
        district: "Merkez",
      },
      {
        _id: 182,
        district: "Balya",
      },
      {
        _id: 183,
        district: "Bandırma",
      },
      {
        _id: 184,
        district: "Bigadiç",
      },
      {
        _id: 185,
        district: "Burhaniye",
      },
      {
        _id: 186,
        district: "Dursunbey",
      },
      {
        _id: 187,
        district: "Edremit",
      },
      {
        _id: 188,
        district: "Erdek",
      },
      {
        _id: 189,
        district: "Gönen",
      },
      {
        _id: 190,
        district: "Havran",
      },
      {
        _id: 191,
        district: "İvrindi",
      },
      {
        _id: 192,
        district: "Karesi",
      },
      {
        _id: 193,
        district: "Kepsut",
      },
      {
        _id: 194,
        district: "Manyas",
      },
      {
        _id: 195,
        district: "Savaştepe",
      },
      {
        _id: 196,
        district: "Sındırgı",
      },
      {
        _id: 197,
        district: "Gömeç",
      },
      {
        _id: 198,
        district: "Susurluk",
      },
      {
        _id: 199,
        district: "Marmara",
      },
    ],
  },
  {
    _id: 11,
    province: "Bilecik",
    district: [
      {
        _id: 200,
        district: "Merkez",
      },
      {
        _id: 201,
        district: "Bozüyük",
      },
      {
        _id: 202,
        district: "Gölpazarı",
      },
      {
        _id: 203,
        district: "Osmaneli",
      },
      {
        _id: 204,
        district: "Pazaryeri",
      },
      {
        _id: 205,
        district: "Söğüt",
      },
      {
        _id: 206,
        district: "Yenipazar",
      },
      {
        _id: 207,
        district: "İnhisar",
      },
    ],
  },
  {
    _id: 12,
    province: "Bingöl",
    district: [
      {
        _id: 208,
        district: "Merkez",
      },
      {
        _id: 209,
        district: "Genç",
      },
      {
        _id: 210,
        district: "Karlıova",
      },
      {
        _id: 211,
        district: "Kiğı",
      },
      {
        _id: 212,
        district: "Solhan",
      },
      {
        _id: 213,
        district: "Adaklı",
      },
      {
        _id: 214,
        district: "Yayladere",
      },
      {
        _id: 215,
        district: "Yedisu",
      },
    ],
  },
  {
    _id: 13,
    province: "Bitlis",
    district: [
      {
        _id: 216,
        district: "Adilcevaz",
      },
      {
        _id: 217,
        district: "Ahlat",
      },
      {
        _id: 218,
        district: "Merkez",
      },
      {
        _id: 219,
        district: "Hizan",
      },
      {
        _id: 220,
        district: "Mutki",
      },
      {
        _id: 221,
        district: "Tatvan",
      },
      {
        _id: 222,
        district: "Güroymak",
      },
    ],
  },
  {
    _id: 14,
    province: "Bolu",
    district: [
      {
        _id: 223,
        district: "Merkez",
      },
      {
        _id: 224,
        district: "Gerede",
      },
      {
        _id: 225,
        district: "Göynük",
      },
      {
        _id: 226,
        district: "Kıbrıscık",
      },
      {
        _id: 227,
        district: "Mengen",
      },
      {
        _id: 228,
        district: "Mudurnu",
      },
      {
        _id: 229,
        district: "Seben",
      },
      {
        _id: 230,
        district: "Dörtdivan",
      },
      {
        _id: 231,
        district: "Yeniçağa",
      },
    ],
  },
  {
    _id: 15,
    province: "Burdur",
    district: [
      {
        _id: 232,
        district: "Ağlasun",
      },
      {
        _id: 233,
        district: "Bucak",
      },
      {
        _id: 234,
        district: "Merkez",
      },
      {
        _id: 235,
        district: "Gölhisar",
      },
      {
        _id: 236,
        district: "Tefenni",
      },
      {
        _id: 237,
        district: "Yeşilova",
      },
      {
        _id: 238,
        district: "Karamanlı",
      },
      {
        _id: 239,
        district: "Kemer",
      },
      {
        _id: 240,
        district: "Altınyayla",
      },
      {
        _id: 241,
        district: "Çavdır",
      },
      {
        _id: 242,
        district: "Çeltikçi",
      },
    ],
  },
  {
    _id: 16,
    province: "Bursa",
    district: [
      {
        _id: 243,
        district: "Gemlik",
      },
      {
        _id: 244,
        district: "İnegöl",
      },
      {
        _id: 245,
        district: "İznik",
      },
      {
        _id: 246,
        district: "Karacabey",
      },
      {
        _id: 247,
        district: "Keles",
      },
      {
        _id: 248,
        district: "Mudanya",
      },
      {
        _id: 249,
        district: "Mustafakemalpaşa",
      },
      {
        _id: 250,
        district: "Orhaneli",
      },
      {
        _id: 251,
        district: "Orhangazi",
      },
      {
        _id: 252,
        district: "Yenişehir",
      },
      {
        _id: 253,
        district: "Büyükorhan",
      },
      {
        _id: 254,
        district: "Harmancık",
      },
      {
        _id: 255,
        district: "Nilüfer",
      },
      {
        _id: 256,
        district: "Osmangazi",
      },
      {
        _id: 257,
        district: "Yıldırım",
      },
      {
        _id: 258,
        district: "Gürsu",
      },
      {
        _id: 259,
        district: "Kestel",
      },
    ],
  },
  {
    _id: 17,
    province: "Çanakkale",
    district: [
      {
        _id: 260,
        district: "Ayvacık",
      },
      {
        _id: 261,
        district: "Bayramiç",
      },
      {
        _id: 262,
        district: "Biga",
      },
      {
        _id: 263,
        district: "Bozcaada",
      },
      {
        _id: 264,
        district: "Çan",
      },
      {
        _id: 265,
        district: "Merkez",
      },
      {
        _id: 266,
        district: "Eceabat",
      },
      {
        _id: 267,
        district: "Ezine",
      },
      {
        _id: 268,
        district: "Gelibolu",
      },
      {
        _id: 269,
        district: "Gökçeada",
      },
      {
        _id: 270,
        district: "Lapseki",
      },
      {
        _id: 271,
        district: "Yenice",
      },
    ],
  },
  {
    _id: 18,
    province: "Çankırı",
    district: [
      {
        _id: 272,
        district: "Merkez",
      },
      {
        _id: 273,
        district: "Çerkeş",
      },
      {
        _id: 274,
        district: "Eldivan",
      },
      {
        _id: 275,
        district: "Ilgaz",
      },
      {
        _id: 276,
        district: "Kurşunlu",
      },
      {
        _id: 277,
        district: "Orta",
      },
      {
        _id: 278,
        district: "Şabanözü",
      },
      {
        _id: 279,
        district: "Yapraklı",
      },
      {
        _id: 280,
        district: "Atkaracalar",
      },
      {
        _id: 281,
        district: "Kızılırmak",
      },
      {
        _id: 282,
        district: "Bayramören",
      },
      {
        _id: 283,
        district: "Korgun",
      },
    ],
  },
  {
    _id: 19,
    province: "Çorum",
    district: [
      {
        _id: 284,
        district: "Alaca",
      },
      {
        _id: 285,
        district: "Bayat",
      },
      {
        _id: 286,
        district: "Merkez",
      },
      {
        _id: 287,
        district: "İskilip",
      },
      {
        _id: 288,
        district: "Kargı",
      },
      {
        _id: 289,
        district: "Mecitözü",
      },
      {
        _id: 290,
        district: "Ortaköy",
      },
      {
        _id: 291,
        district: "Osmancık",
      },
      {
        _id: 292,
        district: "Sungurlu",
      },
      {
        _id: 293,
        district: "Boğazkale",
      },
      {
        _id: 294,
        district: "Uğurludağ",
      },
      {
        _id: 295,
        district: "Dodurga",
      },
      {
        _id: 296,
        district: "Laçin",
      },
      {
        _id: 297,
        district: "Oğuzlar",
      },
    ],
  },
  {
    _id: 20,
    province: "Denizli",
    district: [
      {
        _id: 298,
        district: "Acıpayam",
      },
      {
        _id: 299,
        district: "Buldan",
      },
      {
        _id: 300,
        district: "Çal",
      },
      {
        _id: 301,
        district: "Çameli",
      },
      {
        _id: 302,
        district: "Çardak",
      },
      {
        _id: 303,
        district: "Çivril",
      },
      {
        _id: 304,
        district: "Merkez",
      },
      {
        _id: 305,
        district: "Merkezefendi",
      },
      {
        _id: 306,
        district: "Pamukkale",
      },
      {
        _id: 307,
        district: "Güney",
      },
      {
        _id: 308,
        district: "Kale",
      },
      {
        _id: 309,
        district: "Sarayköy",
      },
      {
        _id: 310,
        district: "Tavas",
      },
      {
        _id: 311,
        district: "Babadağ",
      },
      {
        _id: 312,
        district: "Bekilli",
      },
      {
        _id: 313,
        district: "Honaz",
      },
      {
        _id: 314,
        district: "Serinhisar",
      },
      {
        _id: 315,
        district: "Baklan",
      },
      {
        _id: 316,
        district: "Beyağaç",
      },
      {
        _id: 317,
        district: "Bozkurt",
      },
    ],
  },
  {
    _id: 21,
    province: "Diyarbakır",
    district: [
      {
        _id: 318,
        district: "Kocaköy",
      },
      {
        _id: 319,
        district: "Çermik",
      },
      {
        _id: 320,
        district: "Çınar",
      },
      {
        _id: 321,
        district: "Çüngüş",
      },
      {
        _id: 322,
        district: "Dicle",
      },
      {
        _id: 323,
        district: "Ergani",
      },
      {
        _id: 324,
        district: "Hani",
      },
      {
        _id: 325,
        district: "Hazro",
      },
      {
        _id: 326,
        district: "Kulp",
      },
      {
        _id: 327,
        district: "Lice",
      },
      {
        _id: 328,
        district: "Silvan",
      },
      {
        _id: 329,
        district: "Eğil",
      },
      {
        _id: 330,
        district: "Bağlar",
      },
      {
        _id: 331,
        district: "Kayapınar",
      },
      {
        _id: 332,
        district: "Sur",
      },
      {
        _id: 333,
        district: "Yenişehir",
      },
      {
        _id: 334,
        district: "Bismil",
      },
    ],
  },
  {
    _id: 22,
    province: "Edirne",
    district: [
      {
        _id: 335,
        district: "Merkez",
      },
      {
        _id: 336,
        district: "Enez",
      },
      {
        _id: 337,
        district: "Havsa",
      },
      {
        _id: 338,
        district: "İpsala",
      },
      {
        _id: 339,
        district: "Keşan",
      },
      {
        _id: 340,
        district: "Lalapaşa",
      },
      {
        _id: 341,
        district: "Meriç",
      },
      {
        _id: 342,
        district: "Uzunköprü",
      },
      {
        _id: 343,
        district: "Süloğlu",
      },
    ],
  },
  {
    _id: 23,
    province: "Elazığ",
    district: [
      {
        _id: 344,
        district: "Ağın",
      },
      {
        _id: 345,
        district: "Baskil",
      },
      {
        _id: 346,
        district: "Merkez",
      },
      {
        _id: 347,
        district: "Karakoçan",
      },
      {
        _id: 348,
        district: "Keban",
      },
      {
        _id: 349,
        district: "Maden",
      },
      {
        _id: 350,
        district: "Palu",
      },
      {
        _id: 351,
        district: "Sivrice",
      },
      {
        _id: 352,
        district: "Arıcak",
      },
      {
        _id: 353,
        district: "Kovancılar",
      },
      {
        _id: 354,
        district: "Alacakaya",
      },
    ],
  },
  {
    _id: 24,
    province: "Erzincan",
    district: [
      {
        _id: 355,
        district: "Çayırlı",
      },
      {
        _id: 356,
        district: "Merkez",
      },
      {
        _id: 357,
        district: "İliç",
      },
      {
        _id: 358,
        district: "Kemah",
      },
      {
        _id: 359,
        district: "Kemaliye",
      },
      {
        _id: 360,
        district: "Refahiye",
      },
      {
        _id: 361,
        district: "Tercan",
      },
      {
        _id: 362,
        district: "Üzümlü",
      },
      {
        _id: 363,
        district: "Otlukbeli",
      },
    ],
  },
  {
    _id: 25,
    province: "Erzurum",
    district: [
      {
        _id: 364,
        district: "Aşkale",
      },
      {
        _id: 365,
        district: "Çat",
      },
      {
        _id: 366,
        district: "Hınıs",
      },
      {
        _id: 367,
        district: "Horasan",
      },
      {
        _id: 368,
        district: "İspir",
      },
      {
        _id: 369,
        district: "Karayazı",
      },
      {
        _id: 370,
        district: "Narman",
      },
      {
        _id: 371,
        district: "Oltu",
      },
      {
        _id: 372,
        district: "Olur",
      },
      {
        _id: 373,
        district: "Pasinler",
      },
      {
        _id: 374,
        district: "Şenkaya",
      },
      {
        _id: 375,
        district: "Tekman",
      },
      {
        _id: 376,
        district: "Tortum",
      },
      {
        _id: 377,
        district: "Karaçoban",
      },
      {
        _id: 378,
        district: "Uzundere",
      },
      {
        _id: 379,
        district: "Pazaryolu",
      },
      {
        _id: 380,
        district: "Köprüköy",
      },
      {
        _id: 381,
        district: "Palandöken",
      },
      {
        _id: 382,
        district: "Yakutiye",
      },
      {
        _id: 383,
        district: "Aziziye",
      },
    ],
  },
  {
    _id: 26,
    province: "Eskişehir",
    district: [
      {
        _id: 384,
        district: "Çifteler",
      },
      {
        _id: 385,
        district: "Mahmudiye",
      },
      {
        _id: 386,
        district: "Mihalıççık",
      },
      {
        _id: 387,
        district: "Sarıcakaya",
      },
      {
        _id: 388,
        district: "Seyitgazi",
      },
      {
        _id: 389,
        district: "Sivrihisar",
      },
      {
        _id: 390,
        district: "Alpu",
      },
      {
        _id: 391,
        district: "Beylikova",
      },
      {
        _id: 392,
        district: "İnönü",
      },
      {
        _id: 393,
        district: "Günyüzü",
      },
      {
        _id: 394,
        district: "Han",
      },
      {
        _id: 395,
        district: "Mihalgazi",
      },
      {
        _id: 396,
        district: "Odunpazarı",
      },
      {
        _id: 397,
        district: "Tepebaşı",
      },
    ],
  },
  {
    _id: 27,
    province: "Gaziantep",
    district: [
      {
        _id: 398,
        district: "Araban",
      },
      {
        _id: 399,
        district: "İslahiye",
      },
      {
        _id: 400,
        district: "Nizip",
      },
      {
        _id: 401,
        district: "Oğuzeli",
      },
      {
        _id: 402,
        district: "Yavuzeli",
      },
      {
        _id: 403,
        district: "Şahinbey",
      },
      {
        _id: 404,
        district: "Şehitkamil",
      },
      {
        _id: 405,
        district: "Karkamış",
      },
      {
        _id: 406,
        district: "Nurdağı",
      },
    ],
  },
  {
    _id: 28,
    province: "Giresun",
    district: [
      {
        _id: 407,
        district: "Alucra",
      },
      {
        _id: 408,
        district: "Bulancak",
      },
      {
        _id: 409,
        district: "Dereli",
      },
      {
        _id: 410,
        district: "Espiye",
      },
      {
        _id: 411,
        district: "Eynesil",
      },
      {
        _id: 412,
        district: "Merkez",
      },
      {
        _id: 413,
        district: "Görele",
      },
      {
        _id: 414,
        district: "Keşap",
      },
      {
        _id: 415,
        district: "Şebinkarahisar",
      },
      {
        _id: 416,
        district: "Tirebolu",
      },
      {
        _id: 417,
        district: "Piraziz",
      },
      {
        _id: 418,
        district: "Yağlıdere",
      },
      {
        _id: 419,
        district: "Çamoluk",
      },
      {
        _id: 420,
        district: "Çanakçı",
      },
      {
        _id: 421,
        district: "Doğankent",
      },
      {
        _id: 422,
        district: "Güce",
      },
    ],
  },
  {
    _id: 29,
    province: "Gümüşhane",
    district: [
      {
        _id: 423,
        district: "Merkez",
      },
      {
        _id: 424,
        district: "Kelkit",
      },
      {
        _id: 425,
        district: "Şiran",
      },
      {
        _id: 426,
        district: "Torul",
      },
      {
        _id: 427,
        district: "Köse",
      },
      {
        _id: 428,
        district: "Kürtün",
      },
    ],
  },
  {
    _id: 30,
    province: "Hakkari",
    district: [
      {
        _id: 429,
        district: "Çukurca",
      },
      {
        _id: 430,
        district: "Merkez",
      },
      {
        _id: 431,
        district: "Şemdinli",
      },
      {
        _id: 432,
        district: "Yüksekova",
      },
    ],
  },
  {
    _id: 31,
    province: "Hatay",
    district: [
      {
        _id: 433,
        district: "Altınözü",
      },
      {
        _id: 434,
        district: "Arsuz",
      },
      {
        _id: 435,
        district: "Defne",
      },
      {
        _id: 436,
        district: "Dörtyol",
      },
      {
        _id: 437,
        district: "Hassa",
      },
      {
        _id: 438,
        district: "Antakya",
      },
      {
        _id: 439,
        district: "İskenderun",
      },
      {
        _id: 440,
        district: "Kırıkhan",
      },
      {
        _id: 441,
        district: "Payas",
      },
      {
        _id: 442,
        district: "Reyhanlı",
      },
      {
        _id: 443,
        district: "Samandağ",
      },
      {
        _id: 444,
        district: "Yayladağı",
      },
      {
        _id: 445,
        district: "Erzin",
      },
      {
        _id: 446,
        district: "Belen",
      },
      {
        _id: 447,
        district: "Kumlu",
      },
    ],
  },
  {
    _id: 32,
    province: "Isparta",
    district: [
      {
        _id: 448,
        district: "Atabey",
      },
      {
        _id: 449,
        district: "Eğirdir",
      },
      {
        _id: 450,
        district: "Gelendost",
      },
      {
        _id: 451,
        district: "Merkez",
      },
      {
        _id: 452,
        district: "Keçiborlu",
      },
      {
        _id: 453,
        district: "Senirkent",
      },
      {
        _id: 454,
        district: "Sütçüler",
      },
      {
        _id: 455,
        district: "Şarkikaraağaç",
      },
      {
        _id: 456,
        district: "Uluborlu",
      },
      {
        _id: 457,
        district: "Yalvaç",
      },
      {
        _id: 458,
        district: "Aksu",
      },
      {
        _id: 459,
        district: "Gönen",
      },
      {
        _id: 460,
        district: "Yenişarbademli",
      },
    ],
  },
  {
    _id: 33,
    province: "Mersin",
    district: [
      {
        _id: 461,
        district: "Anamur",
      },
      {
        _id: 462,
        district: "Erdemli",
      },
      {
        _id: 463,
        district: "Gülnar",
      },
      {
        _id: 464,
        district: "Mut",
      },
      {
        _id: 465,
        district: "Silifke",
      },
      {
        _id: 466,
        district: "Tarsus",
      },
      {
        _id: 467,
        district: "Aydıncık",
      },
      {
        _id: 468,
        district: "Bozyazı",
      },
      {
        _id: 469,
        district: "Çamlıyayla",
      },
      {
        _id: 470,
        district: "Akdeniz",
      },
      {
        _id: 471,
        district: "Mezitli",
      },
      {
        _id: 472,
        district: "Toroslar",
      },
      {
        _id: 473,
        district: "Yenişehir",
      },
    ],
  },
  {
    _id: 35,
    province: "İzmir",
    district: [
      {
        _id: 474,
        district: "Aliağa",
      },
      {
        _id: 475,
        district: "Bayındır",
      },
      {
        _id: 476,
        district: "Bergama",
      },
      {
        _id: 477,
        district: "Bornova",
      },
      {
        _id: 478,
        district: "Çeşme",
      },
      {
        _id: 479,
        district: "Dikili",
      },
      {
        _id: 480,
        district: "Foça",
      },
      {
        _id: 481,
        district: "Karaburun",
      },
      {
        _id: 482,
        district: "Karşıyaka",
      },
      {
        _id: 483,
        district: "Kemalpaşa",
      },
      {
        _id: 484,
        district: "Kınık",
      },
      {
        _id: 485,
        district: "Kiraz",
      },
      {
        _id: 486,
        district: "Menemen",
      },
      {
        _id: 487,
        district: "Ödemiş",
      },
      {
        _id: 488,
        district: "Seferihisar",
      },
      {
        _id: 489,
        district: "Selçuk",
      },
      {
        _id: 490,
        district: "Tire",
      },
      {
        _id: 491,
        district: "Torbalı",
      },
      {
        _id: 492,
        district: "Urla",
      },
      {
        _id: 493,
        district: "Beydağ",
      },
      {
        _id: 494,
        district: "Buca",
      },
      {
        _id: 495,
        district: "Konak",
      },
      {
        _id: 496,
        district: "Menderes",
      },
      {
        _id: 497,
        district: "Balçova",
      },
      {
        _id: 498,
        district: "Çiğli",
      },
      {
        _id: 499,
        district: "Gaziemir",
      },
      {
        _id: 500,
        district: "Narlıdere",
      },
      {
        _id: 501,
        district: "Güzelbahçe",
      },
      {
        _id: 502,
        district: "Bayraklı",
      },
      {
        _id: 503,
        district: "Karabağlar",
      },
    ],
  },
  {
    _id: 36,
    province: "Kars",
    district: [
      {
        _id: 504,
        district: "Arpaçay",
      },
      {
        _id: 505,
        district: "Digor",
      },
      {
        _id: 506,
        district: "Kağızman",
      },
      {
        _id: 507,
        district: "Merkez",
      },
      {
        _id: 508,
        district: "Sarıkamış",
      },
      {
        _id: 509,
        district: "Selim",
      },
      {
        _id: 510,
        district: "Susuz",
      },
      {
        _id: 511,
        district: "Akyaka",
      },
    ],
  },
  {
    _id: 37,
    province: "Kastamonu",
    district: [
      {
        _id: 512,
        district: "Abana",
      },
      {
        _id: 513,
        district: "Araç",
      },
      {
        _id: 514,
        district: "Azdavay",
      },
      {
        _id: 515,
        district: "Bozkurt",
      },
      {
        _id: 516,
        district: "Cide",
      },
      {
        _id: 517,
        district: "Çatalzeytin",
      },
      {
        _id: 518,
        district: "Daday",
      },
      {
        _id: 519,
        district: "Devrekani",
      },
      {
        _id: 520,
        district: "İnebolu",
      },
      {
        _id: 521,
        district: "Merkez",
      },
      {
        _id: 522,
        district: "Küre",
      },
      {
        _id: 523,
        district: "Taşköprü",
      },
      {
        _id: 524,
        district: "Tosya",
      },
      {
        _id: 525,
        district: "İhsangazi",
      },
      {
        _id: 526,
        district: "Pınarbaşı",
      },
      {
        _id: 527,
        district: "Şenpazar",
      },
      {
        _id: 528,
        district: "Ağlı",
      },
      {
        _id: 529,
        district: "Doğanyurt",
      },
      {
        _id: 530,
        district: "Hanönü",
      },
      {
        _id: 531,
        district: "Seydiler",
      },
    ],
  },
  {
    _id: 38,
    province: "Kayseri",
    district: [
      {
        _id: 532,
        district: "Bünyan",
      },
      {
        _id: 533,
        district: "Develi",
      },
      {
        _id: 534,
        district: "Felahiye",
      },
      {
        _id: 535,
        district: "İncesu",
      },
      {
        _id: 536,
        district: "Pınarbaşı",
      },
      {
        _id: 537,
        district: "Sarıoğlan",
      },
      {
        _id: 538,
        district: "Sarız",
      },
      {
        _id: 539,
        district: "Tomarza",
      },
      {
        _id: 540,
        district: "Yahyalı",
      },
      {
        _id: 541,
        district: "Yeşilhisar",
      },
      {
        _id: 542,
        district: "Akkışla",
      },
      {
        _id: 543,
        district: "Talas",
      },
      {
        _id: 544,
        district: "Kocasinan",
      },
      {
        _id: 545,
        district: "Melikgazi",
      },
      {
        _id: 546,
        district: "Hacılar",
      },
      {
        _id: 547,
        district: "Özvatan",
      },
    ],
  },
  {
    _id: 39,
    province: "Kırklareli",
    district: [
      {
        _id: 548,
        district: "Babaeski",
      },
      {
        _id: 549,
        district: "Demirköy",
      },
      {
        _id: 550,
        district: "Merkez",
      },
      {
        _id: 551,
        district: "Kofçaz",
      },
      {
        _id: 552,
        district: "Lüleburgaz",
      },
      {
        _id: 553,
        district: "Pehlivanköy",
      },
      {
        _id: 554,
        district: "Pınarhisar",
      },
      {
        _id: 555,
        district: "Vize",
      },
    ],
  },
  {
    _id: 40,
    province: "Kırşehir",
    district: [
      {
        _id: 556,
        district: "Çiçekdağı",
      },
      {
        _id: 557,
        district: "Kaman",
      },
      {
        _id: 558,
        district: "Merkez",
      },
      {
        _id: 559,
        district: "Mucur",
      },
      {
        _id: 560,
        district: "Akpınar",
      },
      {
        _id: 561,
        district: "Akçakent",
      },
      {
        _id: 562,
        district: "Boztepe",
      },
    ],
  },
  {
    _id: 42,
    province: "Konya",
    district: [
      {
        _id: 563,
        district: "Akşehir",
      },
      {
        _id: 564,
        district: "Beyşehir",
      },
      {
        _id: 565,
        district: "Bozkır",
      },
      {
        _id: 566,
        district: "Cihanbeyli",
      },
      {
        _id: 567,
        district: "Çumra",
      },
      {
        _id: 568,
        district: "Doğanhisar",
      },
      {
        _id: 569,
        district: "Ereğli",
      },
      {
        _id: 570,
        district: "Hadim",
      },
      {
        _id: 571,
        district: "Ilgın",
      },
      {
        _id: 572,
        district: "Kadınhanı",
      },
      {
        _id: 573,
        district: "Karapınar",
      },
      {
        _id: 574,
        district: "Kulu",
      },
      {
        _id: 575,
        district: "Sarayönü",
      },
      {
        _id: 576,
        district: "Seydişehir",
      },
      {
        _id: 577,
        district: "Yunak",
      },
      {
        _id: 578,
        district: "Akören",
      },
      {
        _id: 579,
        district: "Altınekin",
      },
      {
        _id: 580,
        district: "Derebucak",
      },
      {
        _id: 581,
        district: "Hüyük",
      },
      {
        _id: 582,
        district: "Karatay",
      },
      {
        _id: 583,
        district: "Meram",
      },
      {
        _id: 584,
        district: "Selçuklu",
      },
      {
        _id: 585,
        district: "Taşkent",
      },
      {
        _id: 586,
        district: "Ahırlı",
      },
      {
        _id: 587,
        district: "Çeltik",
      },
      {
        _id: 588,
        district: "Derbent",
      },
      {
        _id: 589,
        district: "Emirgazi",
      },
      {
        _id: 590,
        district: "Güneysınır",
      },
      {
        _id: 591,
        district: "Halkapınar",
      },
      {
        _id: 592,
        district: "Tuzlukçu",
      },
      {
        _id: 593,
        district: "Yalıhüyük",
      },
    ],
  },
  {
    _id: 43,
    province: "Kütahya",
    district: [
      {
        _id: 594,
        district: "Altıntaş",
      },
      {
        _id: 595,
        district: "Domaniç",
      },
      {
        _id: 596,
        district: "Emet",
      },
      {
        _id: 597,
        district: "Gediz",
      },
      {
        _id: 598,
        district: "Merkez",
      },
      {
        _id: 599,
        district: "Simav",
      },
      {
        _id: 600,
        district: "Tavşanlı",
      },
      {
        _id: 601,
        district: "Aslanapa",
      },
      {
        _id: 602,
        district: "Dumlupınar",
      },
      {
        _id: 603,
        district: "Hisarcık",
      },
      {
        _id: 604,
        district: "Şaphane",
      },
      {
        _id: 605,
        district: "Çavdarhisar",
      },
      {
        _id: 606,
        district: "Pazarlar",
      },
    ],
  },
  {
    _id: 44,
    province: "Malatya",
    district: [
      {
        _id: 607,
        district: "Akçadağ",
      },
      {
        _id: 608,
        district: "Arapgir",
      },
      {
        _id: 609,
        district: "Arguvan",
      },
      {
        _id: 610,
        district: "Darende",
      },
      {
        _id: 611,
        district: "Doğanşehir",
      },
      {
        _id: 612,
        district: "Hekimhan",
      },
      {
        _id: 613,
        district: "Merkez",
      },
      {
        _id: 614,
        district: "Pütürge",
      },
      {
        _id: 615,
        district: "Yeşilyurt",
      },
      {
        _id: 616,
        district: "Battalgazi",
      },
      {
        _id: 617,
        district: "Doğanyol",
      },
      {
        _id: 618,
        district: "Kale",
      },
      {
        _id: 619,
        district: "Kuluncak",
      },
      {
        _id: 620,
        district: "Yazıhan",
      },
    ],
  },
  {
    _id: 45,
    province: "Manisa",
    district: [
      {
        _id: 621,
        district: "Akhisar",
      },
      {
        _id: 622,
        district: "Alaşehir",
      },
      {
        _id: 623,
        district: "Demirci",
      },
      {
        _id: 624,
        district: "Gördes",
      },
      {
        _id: 625,
        district: "Kırkağaç",
      },
      {
        _id: 626,
        district: "Kula",
      },
      {
        _id: 627,
        district: "Merkez",
      },
      {
        _id: 628,
        district: "Salihli",
      },
      {
        _id: 629,
        district: "Sarıgöl",
      },
      {
        _id: 630,
        district: "Saruhanlı",
      },
      {
        _id: 631,
        district: "Selendi",
      },
      {
        _id: 632,
        district: "Soma",
      },
      {
        _id: 633,
        district: "Şehzadeler",
      },
      {
        _id: 634,
        district: "Yunusemre",
      },
      {
        _id: 635,
        district: "Turgutlu",
      },
      {
        _id: 636,
        district: "Ahmetli",
      },
      {
        _id: 637,
        district: "Gölmarmara",
      },
      {
        _id: 638,
        district: "Köprübaşı",
      },
    ],
  },
  {
    _id: 46,
    province: "Kahramanmaraş",
    district: [
      {
        _id: 639,
        district: "Afşin",
      },
      {
        _id: 640,
        district: "Andırın",
      },
      {
        _id: 641,
        district: "Dulkadiroğlu",
      },
      {
        _id: 642,
        district: "Onikişubat",
      },
      {
        _id: 643,
        district: "Elbistan",
      },
      {
        _id: 644,
        district: "Göksun",
      },
      {
        _id: 645,
        district: "Merkez",
      },
      {
        _id: 646,
        district: "Pazarcık",
      },
      {
        _id: 647,
        district: "Türkoğlu",
      },
      {
        _id: 648,
        district: "Çağlayancerit",
      },
      {
        _id: 649,
        district: "Ekinözü",
      },
      {
        _id: 650,
        district: "Nurhak",
      },
    ],
  },
  {
    _id: 47,
    province: "Mardin",
    district: [
      {
        _id: 651,
        district: "Derik",
      },
      {
        _id: 652,
        district: "Kızıltepe",
      },
      {
        _id: 653,
        district: "Artuklu",
      },
      {
        _id: 654,
        district: "Merkez",
      },
      {
        _id: 655,
        district: "Mazıdağı",
      },
      {
        _id: 656,
        district: "Midyat",
      },
      {
        _id: 657,
        district: "Nusaybin",
      },
      {
        _id: 658,
        district: "Ömerli",
      },
      {
        _id: 659,
        district: "Savur",
      },
      {
        _id: 660,
        district: "Dargeçit",
      },
      {
        _id: 661,
        district: "Yeşilli",
      },
    ],
  },
  {
    _id: 48,
    province: "Muğla",
    district: [
      {
        _id: 662,
        district: "Bodrum",
      },
      {
        _id: 663,
        district: "Datça",
      },
      {
        _id: 664,
        district: "Fethiye",
      },
      {
        _id: 665,
        district: "Köyceğiz",
      },
      {
        _id: 666,
        district: "Marmaris",
      },
      {
        _id: 667,
        district: "Menteşe",
      },
      {
        _id: 668,
        district: "Milas",
      },
      {
        _id: 669,
        district: "Ula",
      },
      {
        _id: 670,
        district: "Yatağan",
      },
      {
        _id: 671,
        district: "Dalaman",
      },
      {
        _id: 672,
        district: "Seydikemer",
      },
      {
        _id: 673,
        district: "Ortaca",
      },
      {
        _id: 674,
        district: "Kavaklıdere",
      },
    ],
  },
  {
    _id: 49,
    province: "Muş",
    district: [
      {
        _id: 675,
        district: "Bulanık",
      },
      {
        _id: 676,
        district: "Malazgirt",
      },
      {
        _id: 677,
        district: "Merkez",
      },
      {
        _id: 678,
        district: "Varto",
      },
      {
        _id: 679,
        district: "Hasköy",
      },
      {
        _id: 680,
        district: "Korkut",
      },
    ],
  },
  {
    _id: 50,
    province: "Nevşehir",
    district: [
      {
        _id: 681,
        district: "Avanos",
      },
      {
        _id: 682,
        district: "Derinkuyu",
      },
      {
        _id: 683,
        district: "Gülşehir",
      },
      {
        _id: 684,
        district: "Hacıbektaş",
      },
      {
        _id: 685,
        district: "Kozaklı",
      },
      {
        _id: 686,
        district: "Merkez",
      },
      {
        _id: 687,
        district: "Ürgüp",
      },
      {
        _id: 688,
        district: "Acıgöl",
      },
    ],
  },
  {
    _id: 51,
    province: "Niğde",
    district: [
      {
        _id: 689,
        district: "Bor",
      },
      {
        _id: 690,
        district: "Çamardı",
      },
      {
        _id: 691,
        district: "Merkez",
      },
      {
        _id: 692,
        district: "Ulukışla",
      },
      {
        _id: 693,
        district: "Altunhisar",
      },
      {
        _id: 694,
        district: "Çiftlik",
      },
    ],
  },
  {
    _id: 52,
    province: "Ordu",
    district: [
      {
        _id: 695,
        district: "Akkuş",
      },
      {
        _id: 696,
        district: "Altınordu",
      },
      {
        _id: 697,
        district: "Aybastı",
      },
      {
        _id: 698,
        district: "Fatsa",
      },
      {
        _id: 699,
        district: "Gölköy",
      },
      {
        _id: 700,
        district: "Korgan",
      },
      {
        _id: 701,
        district: "Kumru",
      },
      {
        _id: 702,
        district: "Mesudiye",
      },
      {
        _id: 703,
        district: "Perşembe",
      },
      {
        _id: 704,
        district: "Ulubey",
      },
      {
        _id: 705,
        district: "Ünye",
      },
      {
        _id: 706,
        district: "Gülyalı",
      },
      {
        _id: 707,
        district: "Gürgentepe",
      },
      {
        _id: 708,
        district: "Çamaş",
      },
      {
        _id: 709,
        district: "Çatalpınar",
      },
      {
        _id: 710,
        district: "Çaybaşı",
      },
      {
        _id: 711,
        district: "İkizce",
      },
      {
        _id: 712,
        district: "Kabadüz",
      },
      {
        _id: 713,
        district: "Kabataş",
      },
    ],
  },
  {
    _id: 53,
    province: "Rize",
    district: [
      {
        _id: 714,
        district: "Ardeşen",
      },
      {
        _id: 715,
        district: "Çamlıhemşin",
      },
      {
        _id: 716,
        district: "Çayeli",
      },
      {
        _id: 717,
        district: "Fındıklı",
      },
      {
        _id: 718,
        district: "İkizdere",
      },
      {
        _id: 719,
        district: "Kalkandere",
      },
      {
        _id: 720,
        district: "Pazar",
      },
      {
        _id: 721,
        district: "Merkez",
      },
      {
        _id: 722,
        district: "Güneysu",
      },
      {
        _id: 723,
        district: "Derepazarı",
      },
      {
        _id: 724,
        district: "Hemşin",
      },
      {
        _id: 725,
        district: "İyidere",
      },
    ],
  },
  {
    _id: 54,
    province: "Sakarya",
    district: [
      {
        _id: 726,
        district: "Akyazı",
      },
      {
        _id: 727,
        district: "Geyve",
      },
      {
        _id: 728,
        district: "Hendek",
      },
      {
        _id: 729,
        district: "Karasu",
      },
      {
        _id: 730,
        district: "Kaynarca",
      },
      {
        _id: 731,
        district: "Sapanca",
      },
      {
        _id: 732,
        district: "Kocaali",
      },
      {
        _id: 733,
        district: "Pamukova",
      },
      {
        _id: 734,
        district: "Taraklı",
      },
      {
        _id: 735,
        district: "Ferizli",
      },
      {
        _id: 736,
        district: "Karapürçek",
      },
      {
        _id: 737,
        district: "Söğütlü",
      },
      {
        _id: 738,
        district: "Adapazarı",
      },
      {
        _id: 739,
        district: "Arifiye",
      },
      {
        _id: 740,
        district: "Erenler",
      },
      {
        _id: 741,
        district: "Serdivan",
      },
    ],
  },
  {
    _id: 55,
    province: "Samsun",
    district: [
      {
        _id: 742,
        district: "Alaçam",
      },
      {
        _id: 743,
        district: "Bafra",
      },
      {
        _id: 744,
        district: "Çarşamba",
      },
      {
        _id: 745,
        district: "Havza",
      },
      {
        _id: 746,
        district: "Kavak",
      },
      {
        _id: 747,
        district: "Ladik",
      },
      {
        _id: 748,
        district: "Terme",
      },
      {
        _id: 749,
        district: "Vezirköprü",
      },
      {
        _id: 750,
        district: "Asarcık",
      },
      {
        _id: 751,
        district: "Ondokuzmayıs",
      },
      {
        _id: 752,
        district: "Salıpazarı",
      },
      {
        _id: 753,
        district: "Tekkeköy",
      },
      {
        _id: 754,
        district: "Ayvacık",
      },
      {
        _id: 755,
        district: "Yakakent",
      },
      {
        _id: 756,
        district: "Atakum",
      },
      {
        _id: 757,
        district: "Canik",
      },
      {
        _id: 758,
        district: "İlkadım",
      },
    ],
  },
  {
    _id: 56,
    province: "Siirt",
    district: [
      {
        _id: 759,
        district: "Baykan",
      },
      {
        _id: 760,
        district: "Eruh",
      },
      {
        _id: 761,
        district: "Kurtalan",
      },
      {
        _id: 762,
        district: "Pervari",
      },
      {
        _id: 763,
        district: "Merkez",
      },
      {
        _id: 764,
        district: "Şirvan",
      },
      {
        _id: 765,
        district: "Tillo",
      },
    ],
  },
  {
    _id: 57,
    province: "Sinop",
    district: [
      {
        _id: 766,
        district: "Ayancık",
      },
      {
        _id: 767,
        district: "Boyabat",
      },
      {
        _id: 768,
        district: "Durağan",
      },
      {
        _id: 769,
        district: "Erfelek",
      },
      {
        _id: 770,
        district: "Gerze",
      },
      {
        _id: 771,
        district: "Merkez",
      },
      {
        _id: 772,
        district: "Türkeli",
      },
      {
        _id: 773,
        district: "Dikmen",
      },
      {
        _id: 774,
        district: "Saraydüzü",
      },
    ],
  },
  {
    _id: 58,
    province: "Sivas",
    district: [
      {
        _id: 775,
        district: "Divriği",
      },
      {
        _id: 776,
        district: "Gemerek",
      },
      {
        _id: 777,
        district: "Gürün",
      },
      {
        _id: 778,
        district: "Hafik",
      },
      {
        _id: 779,
        district: "İmranlı",
      },
      {
        _id: 780,
        district: "Kangal",
      },
      {
        _id: 781,
        district: "Koyulhisar",
      },
      {
        _id: 782,
        district: "Merkez",
      },
      {
        _id: 783,
        district: "Suşehri",
      },
      {
        _id: 784,
        district: "Şarkışla",
      },
      {
        _id: 785,
        district: "Yıldızeli",
      },
      {
        _id: 786,
        district: "Zara",
      },
      {
        _id: 787,
        district: "Akıncılar",
      },
      {
        _id: 788,
        district: "Altınyayla",
      },
      {
        _id: 789,
        district: "Doğanşar",
      },
      {
        _id: 790,
        district: "Gölova",
      },
      {
        _id: 791,
        district: "Ulaş",
      },
    ],
  },
  {
    _id: 59,
    province: "Tekirdağ",
    district: [
      {
        _id: 792,
        district: "Çerkezköy",
      },
      {
        _id: 793,
        district: "Çorlu",
      },
      {
        _id: 794,
        district: "Ergene",
      },
      {
        _id: 795,
        district: "Hayrabolu",
      },
      {
        _id: 796,
        district: "Malkara",
      },
      {
        _id: 797,
        district: "Muratlı",
      },
      {
        _id: 798,
        district: "Saray",
      },
      {
        _id: 799,
        district: "Süleymanpaşa",
      },
      {
        _id: 800,
        district: "Kapaklı",
      },
      {
        _id: 801,
        district: "Şarköy",
      },
      {
        _id: 802,
        district: "Marmaraereğlisi",
      },
    ],
  },
  {
    _id: 60,
    province: "Tokat",
    district: [
      {
        _id: 803,
        district: "Almus",
      },
      {
        _id: 804,
        district: "Artova",
      },
      {
        _id: 805,
        district: "Erbaa",
      },
      {
        _id: 806,
        district: "Niksar",
      },
      {
        _id: 807,
        district: "Reşadiye",
      },
      {
        _id: 808,
        district: "Merkez",
      },
      {
        _id: 809,
        district: "Turhal",
      },
      {
        _id: 810,
        district: "Zile",
      },
      {
        _id: 811,
        district: "Pazar",
      },
      {
        _id: 812,
        district: "Yeşilyurt",
      },
      {
        _id: 813,
        district: "Başçiftlik",
      },
      {
        _id: 814,
        district: "Sulusaray",
      },
    ],
  },
  {
    _id: 61,
    province: "Trabzon",
    district: [
      {
        _id: 815,
        district: "Akçaabat",
      },
      {
        _id: 816,
        district: "Araklı",
      },
      {
        _id: 817,
        district: "Arsin",
      },
      {
        _id: 818,
        district: "Çaykara",
      },
      {
        _id: 819,
        district: "Maçka",
      },
      {
        _id: 820,
        district: "Of",
      },
      {
        _id: 821,
        district: "Ortahisar",
      },
      {
        _id: 822,
        district: "Sürmene",
      },
      {
        _id: 823,
        district: "Tonya",
      },
      {
        _id: 824,
        district: "Vakfıkebir",
      },
      {
        _id: 825,
        district: "Yomra",
      },
      {
        _id: 826,
        district: "Beşikdüzü",
      },
      {
        _id: 827,
        district: "Şalpazarı",
      },
      {
        _id: 828,
        district: "Çarşıbaşı",
      },
      {
        _id: 829,
        district: "Dernekpazarı",
      },
      {
        _id: 830,
        district: "Düzköy",
      },
      {
        _id: 831,
        district: "Hayrat",
      },
      {
        _id: 832,
        district: "Köprübaşı",
      },
    ],
  },
  {
    _id: 62,
    province: "Tunceli",
    district: [
      {
        _id: 833,
        district: "Çemişgezek",
      },
      {
        _id: 834,
        district: "Hozat",
      },
      {
        _id: 835,
        district: "Mazgirt",
      },
      {
        _id: 836,
        district: "Nazımiye",
      },
      {
        _id: 837,
        district: "Ovacık",
      },
      {
        _id: 838,
        district: "Pertek",
      },
      {
        _id: 839,
        district: "Pülümür",
      },
      {
        _id: 840,
        district: "Merkez",
      },
    ],
  },
  {
    _id: 63,
    province: "Şanlıurfa",
    district: [
      {
        _id: 841,
        district: "Akçakale",
      },
      {
        _id: 842,
        district: "Birecik",
      },
      {
        _id: 843,
        district: "Bozova",
      },
      {
        _id: 844,
        district: "Ceylanpınar",
      },
      {
        _id: 845,
        district: "Eyyübiye",
      },
      {
        _id: 846,
        district: "Halfeti",
      },
      {
        _id: 847,
        district: "Haliliye",
      },
      {
        _id: 848,
        district: "Hilvan",
      },
      {
        _id: 849,
        district: "Karaköprü",
      },
      {
        _id: 850,
        district: "Siverek",
      },
      {
        _id: 851,
        district: "Suruç",
      },
      {
        _id: 852,
        district: "Viranşehir",
      },
      {
        _id: 853,
        district: "Harran",
      },
    ],
  },
  {
    _id: 64,
    province: "Uşak",
    district: [
      {
        _id: 854,
        district: "Banaz",
      },
      {
        _id: 855,
        district: "Eşme",
      },
      {
        _id: 856,
        district: "Karahallı",
      },
      {
        _id: 857,
        district: "Sivaslı",
      },
      {
        _id: 858,
        district: "Ulubey",
      },
      {
        _id: 859,
        district: "Merkez",
      },
    ],
  },
  {
    _id: 65,
    province: "Van",
    district: [
      {
        _id: 860,
        district: "Başkale",
      },
      {
        _id: 861,
        district: "Çatak",
      },
      {
        _id: 862,
        district: "Erciş",
      },
      {
        _id: 863,
        district: "Gevaş",
      },
      {
        _id: 864,
        district: "Gürpınar",
      },
      {
        _id: 865,
        district: "İpekyolu",
      },
      {
        _id: 866,
        district: "Muradiye",
      },
      {
        _id: 867,
        district: "Özalp",
      },
      {
        _id: 868,
        district: "Tuşba",
      },
      {
        _id: 869,
        district: "Bahçesaray",
      },
      {
        _id: 870,
        district: "Çaldıran",
      },
      {
        _id: 871,
        district: "Edremit",
      },
      {
        _id: 872,
        district: "Saray",
      },
    ],
  },
  {
    _id: 66,
    province: "Yozgat",
    district: [
      {
        _id: 873,
        district: "Akdağmadeni",
      },
      {
        _id: 874,
        district: "Boğazlıyan",
      },
      {
        _id: 875,
        district: "Çayıralan",
      },
      {
        _id: 876,
        district: "Çekerek",
      },
      {
        _id: 877,
        district: "Sarıkaya",
      },
      {
        _id: 878,
        district: "Sorgun",
      },
      {
        _id: 879,
        district: "Şefaatli",
      },
      {
        _id: 880,
        district: "Yerköy",
      },
      {
        _id: 881,
        district: "Merkez",
      },
      {
        _id: 882,
        district: "Aydıncık",
      },
      {
        _id: 883,
        district: "Çandır",
      },
      {
        _id: 884,
        district: "Kadışehri",
      },
      {
        _id: 885,
        district: "Saraykent",
      },
      {
        _id: 886,
        district: "Yenifakılı",
      },
    ],
  },
  {
    _id: 67,
    province: "Zonguldak",
    district: [
      {
        _id: 887,
        district: "Çaycuma",
      },
      {
        _id: 888,
        district: "Devrek",
      },
      {
        _id: 889,
        district: "Ereğli",
      },
      {
        _id: 890,
        district: "Merkez",
      },
      {
        _id: 891,
        district: "Alaplı",
      },
      {
        _id: 892,
        district: "Gökçebey",
      },
    ],
  },
  {
    _id: 68,
    province: "Aksaray",
    district: [
      {
        _id: 893,
        district: "Ağaçören",
      },
      {
        _id: 894,
        district: "Eskil",
      },
      {
        _id: 895,
        district: "Gülağaç",
      },
      {
        _id: 896,
        district: "Güzelyurt",
      },
      {
        _id: 897,
        district: "Merkez",
      },
      {
        _id: 898,
        district: "Ortaköy",
      },
      {
        _id: 899,
        district: "Sarıyahşi",
      },
    ],
  },
  {
    _id: 69,
    province: "Bayburt",
    district: [
      {
        _id: 900,
        district: "Merkez",
      },
      {
        _id: 901,
        district: "Aydıntepe",
      },
      {
        _id: 902,
        district: "Demirözü",
      },
    ],
  },
  {
    _id: 70,
    province: "Karaman",
    district: [
      {
        _id: 903,
        district: "Ermenek",
      },
      {
        _id: 904,
        district: "Merkez",
      },
      {
        _id: 905,
        district: "Ayrancı",
      },
      {
        _id: 906,
        district: "Kazımkarabekir",
      },
      {
        _id: 907,
        district: "Başyayla",
      },
      {
        _id: 908,
        district: "Sarıveliler",
      },
    ],
  },
  {
    _id: 71,
    province: "Kırıkkale",
    district: [
      {
        _id: 909,
        district: "Delice",
      },
      {
        _id: 910,
        district: "Keskin",
      },
      {
        _id: 911,
        district: "Merkez",
      },
      {
        _id: 912,
        district: "Sulakyurt",
      },
      {
        _id: 913,
        district: "Bahşili",
      },
      {
        _id: 914,
        district: "Balışeyh",
      },
      {
        _id: 915,
        district: "Çelebi",
      },
      {
        _id: 916,
        district: "Karakeçili",
      },
      {
        _id: 917,
        district: "Yahşihan",
      },
    ],
  },
  {
    _id: 72,
    province: "Batman",
    district: [
      {
        _id: 918,
        district: "Merkez",
      },
      {
        _id: 919,
        district: "Beşiri",
      },
      {
        _id: 920,
        district: "Gercüş",
      },
      {
        _id: 921,
        district: "Kozluk",
      },
      {
        _id: 922,
        district: "Sason",
      },
      {
        _id: 923,
        district: "Hasankeyf",
      },
    ],
  },
  {
    _id: 73,
    province: "Şırnak",
    district: [
      {
        _id: 924,
        district: "Beytüşşebap",
      },
      {
        _id: 925,
        district: "Cizre",
      },
      {
        _id: 926,
        district: "İdil",
      },
      {
        _id: 927,
        district: "Silopi",
      },
      {
        _id: 928,
        district: "Merkez",
      },
      {
        _id: 929,
        district: "Uludere",
      },
      {
        _id: 930,
        district: "Güçlükonak",
      },
    ],
  },
  {
    _id: 74,
    province: "Bartın",
    district: [
      {
        _id: 931,
        district: "Merkez",
      },
      {
        _id: 932,
        district: "Kurucaşile",
      },
      {
        _id: 933,
        district: "Ulus",
      },
      {
        _id: 934,
        district: "Amasra",
      },
    ],
  },
  {
    _id: 75,
    province: "Ardahan",
    district: [
      {
        _id: 935,
        district: "Merkez",
      },
      {
        _id: 936,
        district: "Çıldır",
      },
      {
        _id: 937,
        district: "Göle",
      },
      {
        _id: 938,
        district: "Hanak",
      },
      {
        _id: 939,
        district: "Posof",
      },
      {
        _id: 940,
        district: "Damal",
      },
    ],
  },
  {
    _id: 76,
    province: "Iğdır",
    district: [
      {
        _id: 941,
        district: "Aralık",
      },
      {
        _id: 942,
        district: "Merkez",
      },
      {
        _id: 943,
        district: "Tuzluca",
      },
      {
        _id: 944,
        district: "Karakoyunlu",
      },
    ],
  },
  {
    _id: 77,
    province: "Yalova",
    district: [
      {
        _id: 945,
        district: "Merkez",
      },
      {
        _id: 946,
        district: "Altınova",
      },
      {
        _id: 947,
        district: "Armutlu",
      },
      {
        _id: 948,
        district: "Çınarcık",
      },
      {
        _id: 949,
        district: "Çiftlikköy",
      },
      {
        _id: 950,
        district: "Termal",
      },
    ],
  },
  {
    _id: 78,
    province: "Karabük",
    district: [
      {
        _id: 951,
        district: "Eflani",
      },
      {
        _id: 952,
        district: "Eskipazar",
      },
      {
        _id: 953,
        district: "Merkez",
      },
      {
        _id: 954,
        district: "Ovacık",
      },
      {
        _id: 955,
        district: "Safranbolu",
      },
      {
        _id: 956,
        district: "Yenice",
      },
    ],
  },
  {
    _id: 79,
    province: "Kilis",
    district: [
      {
        _id: 957,
        district: "Merkez",
      },
      {
        _id: 958,
        district: "Elbeyli",
      },
      {
        _id: 959,
        district: "Musabeyli",
      },
      {
        _id: 960,
        district: "Polateli",
      },
    ],
  },
  {
    _id: 80,
    province: "Osmaniye",
    district: [
      {
        _id: 961,
        district: "Bahçe",
      },
      {
        _id: 962,
        district: "Kadirli",
      },
      {
        _id: 963,
        district: "Merkez",
      },
      {
        _id: 964,
        district: "Düziçi",
      },
      {
        _id: 965,
        district: "Hasanbeyli",
      },
      {
        _id: 966,
        district: "Sumbas",
      },
      {
        _id: 967,
        district: "Toprakkale",
      },
    ],
  },
  {
    _id: 81,
    province: "Düzce",
    district: [
      {
        _id: 968,
        district: "Akçakoca",
      },
      {
        _id: 969,
        district: "Merkez",
      },
      {
        _id: 970,
        district: "Yığılca",
      },
      {
        _id: 971,
        district: "Cumayeri",
      },
      {
        _id: 972,
        district: "Gölyaka",
      },
      {
        _id: 973,
        district: "Çilimli",
      },
      {
        _id: 974,
        district: "Gümüşova",
      },
      {
        _id: 975,
        district: "Kaynaşlı",
      },
    ],
  },
];
