import { Button } from "react-bootstrap";
import { useState } from "react";
import { Container, Row, Col, Card, Form} from "react-bootstrap";
import { Modal } from "react-bootstrap";
import "./TKWorkplaceManagement.scss";
import TKWorkplaceCampaigns from "./TKWorkplaceCampaigns/TKWorkplaceCampaigns";
import TKWorkplaceEmployees from "./TKWorkplaceEmployees/TKWorkplaceEmployees";
import { useSelector, useDispatch } from "react-redux";
import parse from "html-react-parser";

const TKWorkplaceManagement = () => {
  const CAMPAIGNS = "CAMPAIGNS";
  const EMPLOYEES = "EMPLOYEES";

  const [showModal, setShowModal] = useState(false);
  const [field, setField] = useState("CAMPAIGNS"); // CAMPAIGNS / EMPLOYEES
  const dispatch = useDispatch();
  const info = useSelector((state) => state.auth.userInfo);
  const handleSetShowModal = (show, field) => {
    setShowModal(show);
    setField(field);
  };

  return (
    <div className="tk_workplace_management text-center mt-4">
      {/*<div className="tk_display-l">İşyeri Yönetimi</div>*/}
      <Row className="tk_user-row">
              <div className="user-col col-sm-12 col-md-4">
                <div className="text-dark">İsim Soyisim</div>
                <div className="text-dark tk_balance-modal-name">{`${info?.name} ${info?.surname}`}</div>
              </div>
              <div className="user-col col-sm-12 col-md-4"> 
                <div className="text-dark">VKN</div>
                <div className="text-dark tk_balance-modal-balance">{`${info?.vkn}`}</div>
              </div>
              <div className="user-col col-sm-12 col-md-4">
                <div className="text-dark">E-mail</div>
                <div className="text-dark tk_balance-modal-balance">{`${info?.email}`}</div>
              </div>
              </Row>
          <Row className="tk_user-row">
              <div className="user-col col-sm-12 col-md-4">
                <div className="text-dark">Firma Adı</div>
                <div className="text-dark tk_balance-modal-balance">{`${info?.company?.entitle}`}</div>
              </div>
              <div className="user-col col-sm-12 col-md-4">
                <div className="text-dark">Firma Detayı</div>
                <div className="text-dark tk_balance-modal-balance">{parse(`${info?.company?.detail}`)}</div>
              </div>
              <div className="user-col col-sm-12 col-md-4">
                <div className="text-dark">Yönetici Numarası</div>
                <div className="text-dark tk_balance-modal-balance">{`${info?.company?.managerphone}`}</div>
              </div>
            </Row>
      <div className="d-flex justify-content-center m-2 tk_workplace_management-buttons">
        <Button
          className="mx-2"
          onClick={() => handleSetShowModal(true, CAMPAIGNS)}
        >
          Kampanyalar
        </Button>
        <Button
          className="mx-2"
          onClick={() => handleSetShowModal(true, EMPLOYEES)}
        >
          Çalışanlarım
        </Button>
      </div>

      <Modal
        className="tk_workplace_management-modal"
        show={showModal}
        onHide={(h) => setShowModal(h)}
        size="xl"
        centered
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          {field === CAMPAIGNS && <Modal.Title>Kampanyalarım</Modal.Title>}
          {field === EMPLOYEES && <Modal.Title>Çalışanlarım</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          {field === CAMPAIGNS && <TKWorkplaceCampaigns />}
          {field === EMPLOYEES && <TKWorkplaceEmployees />}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TKWorkplaceManagement;
