import React, { useEffect } from 'react';
import './News.scss';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import TKImageSlider from '../../../../components/TKImageSlider/TKImageSlider';
import TKCardsContainer from '../../../../components/TKCardsContainer/TKCardsContainer';
import TKCard from '../../../../components/TKCard/TKCard';
import { clearTKNews, getTKNewsRequest } from '../../../../store/actions/news';
import { formatUrl, handleRedirect } from '../../../../helpers/utilities';
import TKLoaderContainer from '../../../../components/TKLoaderContainer/TKLoaderContainer';
import TKPageHeader from '../../../../components/TKPageHeader/TKPageHeader';

const News = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    displayList,
    page,
    hasMore,
    loading
  } = useSelector(state => state.news);

  useEffect(() => {
    fetchData();
    return () => dispatch(clearTKNews());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = () => {
    dispatch(getTKNewsRequest(page));
  }

  const imageList = displayList.slice(0, 5).map(news => news.image);

  const output = displayList.map(({ _id, image, caption, short_detail }, i) => (
    <TKCard
      size="md"
      key={i}
      shadow={false}
      image={image}
      header={caption}
      desc={short_detail}
      href={`/haberler/${formatUrl(short_detail)}`}
      onClick={() => handleRedirect(history, `/haberler/${formatUrl(short_detail)} `)}
    />
  ));

  const notFoundDiv = (pre = "") => {
    return (
      <div
        className="h5 d-flex justify-content-center align-items-center"
        style={{ minHeight: "100px" }}
      >
        {pre} Haber Bulunamadı
      </div>
    );
  }

  return (
    loading && !hasMore ?
      <TKLoaderContainer /> :
      <>
        {/* <div className="tk_news-image-slider">
          <TKImageSlider images={imageList}/>
        </div> */}
        <TKPageHeader header="Haberler" />
        <div className='container'>
          <div className="tk_news-cards-container">
            <TKCardsContainer
              header="Haberler"
              dataLength={displayList.length}
              fetchData={fetchData}
              hasMore={hasMore}
            >
              {output?.length ? output : notFoundDiv()}
            </TKCardsContainer>
          </div>
        </div>
      </>
  );
};

export default News;