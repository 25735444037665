import React from 'react';
import './TKDetailsAddress.scss';
import TKButton from '../TKButton/TKButton';
import TKMap from '../TKMap/TKMap';

const TKDetailsAddress = ({ company, map }) => {
  const { address, map_lat, map_long, phone, landingPhone, web_site } = { ...company };
  const position = {
    lat: map_lat,
    lng: map_long,
  }

  let newLandingPhone = landingPhone;

  const onShareClick = (address, phone, email, web_site, landingPhone) => {
    var formattedBody = `Adres:${address} \n\nTelefon:${landingPhone}\n\nMail:${email}\n\nWeb Sitesi:${web_site} `;
    var mailToLink = "mailto:?body=" + encodeURIComponent(formattedBody);
    window.location.href = mailToLink;

  }

  if(newLandingPhone == "undefined" || ""){
    newLandingPhone = "-"
  }


  return (
    <div className="tk_details-address mt-4">
      <div className="mb-4">
        <div className="row">
          <div className="col">
            <div>
              <div className="tk_display-m text-capitalize my-1">telefon: <span>{newLandingPhone}</span></div>
            </div>
            <div>
              <div className="tk_display-m text-capitalize my-1">adres: <span className="text-capitalize">{address}</span></div>
            </div>

          </div>
          <div className="col">
            <div>
              <div className="tk_display-m text-capitalize my-1">web sitesi: <a href={web_site} className="text-lowercase">{web_site}</a></div>
            </div>
          </div>
        </div>
      </div>

      {map && !isNaN(position?.lat) && !isNaN(position?.lng) &&
        <div className="tk_details-address-map">
          <TKMap
            position={position}
            center={position}
          />
        </div>
      }
    </div>
  );
};

export default TKDetailsAddress;

TKMap.defaultProps = {
  position: {},
  adress: "",
}