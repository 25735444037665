import React from 'react';
import './TKAddNewButton.scss';
import TKButton from '../../TKButton/TKButton';
import TKIcon from '../../TKIcon/TKIcon';
import TKModal from '../TKModal/TKModal';

const TKAddNewButton = ({backdropClassName, showModal, setShowModal, btnTitle, modalHeader, handleSaveOnClick, processLoading, children, size}) => {
  return (
    <div className="tk_add-new-btn">
      <TKButton 
        onClick={(e) => {
          e.preventDefault();
          setShowModal(true)
        }}
      >
        {`${btnTitle} ekle`}
        <TKIcon
          name="plus"
          size="16"
        />
      </TKButton>
      <TKModal
        backdropClassName={backdropClassName}
        show={showModal}
        setShow={setShowModal}
        header={modalHeader}
        saveOnClick={handleSaveOnClick}
        processLoading={processLoading}
        size={size}
      >
        {children}
      </TKModal>
    </div>
  );
};

export default TKAddNewButton;

TKAddNewButton.defaultProps = {
  setShowModal: () => {},
  btnTitle: ""
}