import * as types from '../actionTypes';
import {convertBannerType} from '../../constants/general';

const initialState = {
  displayList: [],
  page: 0,
  hasMore: false,
  loading: false,
  error: false,
  admin: {
    list: [],
    contentlist:[],
    // page: 0,
    // totalPages: 0,
    processLoading: false,
    processError: false,
    showAddModal: false,
    showEditModal: false,
    showDeleteModal: false
  }
}

const banners = (state = initialState, action) => {
  
  switch(action.type) {
    case types.GET_TK_ADMIN_BANNERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_ADMIN_BANNERS_SUCCEEDED:
      const banners = action.payload.data.map(bannerItem => {
        if (bannerItem.link !== null && bannerItem.link !== "" ){
          bannerItem.banner ="Link"
        }
        else if (bannerItem.banner !== null ) {
          bannerItem.link = bannerItem.banner._id
          bannerItem.banner = convertBannerType(bannerItem.banner.type)
        } 
        else {
          bannerItem.banner ="Afiş"

        }
        return bannerItem

      },[])
      
      return {
        ...state,
        loading: false,
        error: false,
        admin: {
          ...state.admin,
          list: [...banners]

          // page: action.payload.page,
          // totalPages: action.payload.totalPages
        }
      }
    case types.GET_TK_ADMIN_BANNERS_CONTENT_REQUEST:
      return {
        ...state,
        admin:{
          ...state.admin
        }
      }
      case types.GET_TK_ADMIN_BANNERS_CONTENT_REQUEST_SUCCEDED:
        return {
          ...state,
          admin:{
           ...state.admin,
           contentlist:[...action.payload.data]
          }
        }
      case types.GET_TK_ADMIN_BANNERS_CONTENT_REQUEST_FAILED:
        return {
          ...state,
          admin:{
           contentlist:[...action.payload.data]
          }
        }
    case types.GET_TK_ADMIN_BANNERS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case types.SET_SHOW_BANNERS_ADD_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showAddModal: action.payload
        }
      }
    case types.CREATE_TK_ADMIN_BANNERS_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.PROCESS_TK_ADMIN_BANNERS_SUCCEEDED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: false
        }
      }
    case types.PROCESS_TK_ADMIN_BANNERS_FAILED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: true
        }
      }
    case types.DELETE_TK_ADMIN_BANNERS_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_BANNERS_DELETE_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showDeleteModal: action.payload
        }
      }
      case types.EDIT_TK_ADMIN_BANNERS_REQUEST:
        return {
          ...state,
          admin: {
            ...state.admin,
            processLoading: true,
            processError: false
          }
        }
      case types.SET_SHOW_BANNERS_EDIT_MODAL:
        return {
          ...state,
          admin: {
            ...state.admin,
            showEditModal: action.payload
          }
        }
    default:
      return state;
  }
}

export default banners;