import React, { useEffect } from "react";
import "./TKFAQForm.scss";
import { Form, Row, Col } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TKEditor from "../../../TKEditor/TKEditor";
import viewToPlainText from "@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext";

const TKFAQForm = ({ formData, formDataErrors, onChange, isEdit, isFAQ }) => {
  return (
    <Form>
      <Form.Group>
        <Form.Label>Başlık</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="caption"
          value={formData.caption}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          isInvalid={formDataErrors.caption}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Detay</Form.Label>
        {!isFAQ && (typeof formData?.detail === "object" || !isEdit) ? (
          <TKEditor editorState={formData?.detail} setEditorState={onChange} />
        ) : (
          <CKEditor
            config={{
              language: "tr",
              toolbar: {
                items: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "numberedList",
                  "bulletedList",
                  "|",
                  "blockQuote",
                  "link",
                  "|",
                  "undo",
                  "redo",
                ],
                shouldNotGroupWhenFull: true,
              },
              heading: {
                options: [
                  {
                    model: "paragraph",
                    title: "Paragraph",
                    class: "ck-heading_paragraph",
                  },
                  {
                    model: "heading1",
                    view: "h3",
                    title: "Heading 1",
                    class: "ck-heading_heading1",
                  },
                  {
                    model: "heading2",
                    view: "h4",
                    title: "Heading 2",
                    class: "ck-heading_heading2",
                  },
                  {
                    model: "heading3",
                    view: "h5",
                    title: "Heading 3",
                    class: "ck-heading_heading3",
                  },
                ],
              },
              link: {
                defaultProtocol: "http://",
              },
            }}
            editor={ClassicEditor}
            data={formData.detail}
            onChange={(e, editor) => {
              const data = editor.getData();
              onChange("detail", data);
            }}
            onBlur={(e, editor) => {
              const text = viewToPlainText(
                editor.editing.view.document.getRoot()
              );
              onChange("plain_detail", text);
            }}
          />
        )}
      </Form.Group>
      <Form.Group>
        <Form.Label>Sıra</Form.Label>
        <Form.Control
          as="input"
          name="order_number"
          value={formData.order_number}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Form.Group>
    </Form>
  );
};

export default TKFAQForm;

TKFAQForm.defaultProps = {
  formData: {},
};
