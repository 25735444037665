import * as types from '../actionTypes'

const initialState = {
  loading: false,
  error: false,
  admin: {
    list: [],
    page: 0,
    search: '',
    searchFormData:{
      tckn:"",
      card:"",
    },
    totalPages: 0,
  }
}

const allTransactions = (state = initialState, action) => {
  switch(action.type) {
    case types.POST_TK_ALL_TRANSACTIONS_EXPORT_EXCEL_REQUEST:
       return {
         ...state
       }
    case types.GET_TK_ALL_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        admin: {
          ...state.admin,
          searchFormData:action.payload.searchFormData
        }
      }
    case types.GET_TK_ALL_TRANSACTIONS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: false,
        admin: {
          ...state.admin,
          list: [...action.payload.data],
          page: action.payload.page,
          search: action.payload.search,
          totalPages: action.payload.totalPages
        }
      }
    case types.GET_TK_ALL_TRANSACTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    default:
      return state;
  }
}

export default allTransactions;