import React, { useEffect, useState } from "react";
import "./TKContentForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/tr";
import TKFileInput from "../../TKInputs/TKFileInput/TKFileInput";
import TKDatePicker from "../../TKInputs/TKDatePicker/TKDatePicker";
import { add, sub } from "date-fns";
import { formatDate, isJsonString } from "../../../../helpers/utilities";
import { checkOnChangeNumeric } from "../../../../helpers/validate";
import { getTKMWPNamesRequest } from "../../../../store/actions/memberWorkplaces";
import { getTKCardTypesRequestWithAllCardOption } from "../../../../store/actions/cardTypes";
import TKSelect from "../../../TKSelect/TKSelect";
import { statusLabel, campaignCodeTypes } from "../../../../constants/general";
import viewToPlainText from "@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext";
import TKEditor from "../../../TKEditor/TKEditor";

const TKContentForm = ({
  formData,
  formDataErrors,
  onChange,
  isNews,
  isPublic,
  isEdit,
  searchStatus,
  setIfItIsBlank,
}) => {
  const PERCENT = "PERCENT";
  const FIXED = "FIXED";
  const amountLabel = {
    PERCENT: "Oranı",
    FIXED: "Miktarı",
  };
  const dispatch = useDispatch();
  const { namesList } = useSelector((state) => state.memberWorkplaces);
  const [filteredNamesList, filteredSetNamesList] = useState(namesList);
  const { listCardTypes } = useSelector((state) => state.cardTypes);

  const [status, setStatus] = useState([
    { status: "AKTİF", _id: "ACTIVE" },
    { status: "PASİF", _id: "PASSIVE" },
  ]);
  const [statusWithOtherStatus, setStatusWithOtherStatus] = useState([
    { status: "AKTİF", _id: "ACTIVE" },
    { status: "PASİF", _id: "PASSIVE" },
    { status: "ONAY BEKLİYOR", _id: "WFA" },
    { status: "RED", _id: "CANCELED" },
  ]);

  const [selectSearch, setSelectSearch] = useState("");

  const handleChangeCampaignCode = (type) => {
    if (formData?.campaign_code?.type === type) {
      onChange("campaign_code", {});
      return;
    }

    if (type === campaignCodeTypes.GENERATE) {
      onChange("campaign_code", { type });
    } else if (type === campaignCodeTypes.UPLOAD) {
      onChange("campaign_code", { type });
    } else if (type === campaignCodeTypes.FIXED) {
      onChange("campaign_code", { type });
    } else {
      onChange("campaign_code", {});
    }
  };

  useEffect(() => {
    if (!isNews) {
      dispatch(getTKMWPNamesRequest());
      dispatch(
        getTKCardTypesRequestWithAllCardOption({ cardtype: formData.cardtype })
      );
    }
  }, [dispatch, isNews]);

  const filteredCardTypes = listCardTypes.filter(function (item) {
    if (!item.name.toLowerCase().startsWith("TUZLA KART")) {
      return item;
    }
  });

  const handleAmountOnChange = (e) => {
    let valid = true;
    if (e.target.value) {
      valid = checkOnChangeNumeric(e.target.value);
    }
    return valid && onChange(e.target.name, e.target.value);
  };

  const onSelectChange = (name, value) => {
    if (value == "Backspace" && name === "owner") {
      setSelectSearch("");
      filteredSetNamesList(namesList);
      onChange("owner", "");
    }
    if (name === "owner") {
      let searchparam = selectSearch.replace("i", "İ");

      filteredSetNamesList(
        namesList.filter(function (item) {
          let entitle = item.entitle.replace("i", "İ");

          if (
            entitle
              .toUpperCase()
              .startsWith((searchparam + value).toUpperCase())
          ) {
            return item;
          }
        })
      );

      if (value == "Backspace") {
        setSelectSearch("");
      } else if (
        value == "CapsLock" ||
        value == "ArrowDown" ||
        value == "ArrowUp" ||
        value == "ArrowLeft" ||
        value == "ArrowRight"
      ) {
      } else {
        setSelectSearch(searchparam + value);
      }
    }
  };

  return (
    <Form className="tk_content-form p-2">
      <Row>
        <Col>
          {isNews && (
            <Form.Group>
              <Form.Label>başlık *</Form.Label>
              <Form.Control
                type="text"
                name="caption"
                placeholder="Başlık"
                value={formData.caption || ""}
                maxLength={60}
                onChange={(e) => onChange(e.target.name, e.target.value)}
                isInvalid={formDataErrors.caption}
              />
              <Form.Text className="text-danger">
                {formDataErrors?.caption}
              </Form.Text>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>kısa detay *</Form.Label>
            <Form.Control
              type="text"
              name="short_detail"
              placeholder="Kısa Detay"
              value={formData.short_detail || ""}
              maxLength={45}
              onChange={(e) => onChange(e.target.name, e.target.value)}
              isInvalid={formDataErrors.short_detail}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.short_detail}
            </Form.Text>
          </Form.Group>
          <Form.Group
            className={formDataErrors?.detail ? "tk_ck-editor-error" : ""}
          >
            <Form.Label>detay *</Form.Label>
            {(isNews && typeof formData?.detail === "object") ||
            (isNews && !isEdit) ? (
              <TKEditor
                editorState={formData?.detail}
                setEditorState={onChange}
              />
            ) : (
              <CKEditor
                config={{
                  language: "tr",
                  toolbar: {
                    items: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "|",
                      "numberedList",
                      "bulletedList",
                      "|",
                      "blockQuote",
                      "link",
                      "|",
                      "undo",
                      "redo",
                    ],
                    shouldNotGroupWhenFull: true,
                  },
                  heading: {
                    options: [
                      {
                        model: "paragraph",
                        title: "Paragraph",
                        class: "ck-heading_paragraph",
                      },
                      {
                        model: "heading1",
                        view: "h3",
                        title: "Heading 1",
                        class: "ck-heading_heading1",
                      },
                      {
                        model: "heading2",
                        view: "h4",
                        title: "Heading 2",
                        class: "ck-heading_heading2",
                      },
                      {
                        model: "heading3",
                        view: "h5",
                        title: "Heading 3",
                        class: "ck-heading_heading3",
                      },
                    ],
                  },
                  link: {
                    defaultProtocol: "http://",
                  },
                }}
                editor={ClassicEditor}
                data={formData.detail}
                onChange={(e, editor) => {
                  const data = editor.getData();
                  onChange("detail", data);
                }}
                onBlur={(e, editor) => {
                  const text = viewToPlainText(
                    editor.editing.view.document.getRoot()
                  );
                  onChange("plain_detail", text);
                }}
              />
            )}
            {console.log(isEdit)}
            <Form.Text className="text-danger">
              {formDataErrors?.detail}
            </Form.Text>
          </Form.Group>
          {!isPublic && !(searchStatus === "CANCELED") && (
            <Form.Group>
              <Form.Label>Durum *</Form.Label>
              <TKSelect
                defaultTitle="Durum Seç"
                selectedItem={formData?.status}
                itemSelector="status"
                items={searchStatus === "WFA" ? statusWithOtherStatus : status}
                itemOnClick={(id) => onChange("status", id)}
                isInvalid={formDataErrors.status}
                name="status"
              />
              <Form.Text className="text-danger">
                {formDataErrors?.status}
              </Form.Text>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>
              {formData.previousimage && <> Yüklü </>} Fotoğraf{" "}
              {formData.previousimage && (
                <> : {formData.previousimage.slice(25)} </>
              )}
            </Form.Label>
            <TKFileInput
              selectedFile={formData.image}
              onChange={(file) => onChange("image", file)}
              className={formDataErrors?.image ? "tk_file-input-error" : ""}
              imgsrc={formData.previousimage}
              isEdit={isEdit}
            />
            <Form.Text className="text-danger">
              {formDataErrors?.image}
            </Form.Text>
          </Form.Group>
          {isNews && (
            <Form.Group className="d-flex flex-column d-md-block">
              <Form.Label>başlama tarihi *</Form.Label>
              <TKDatePicker
                className={formDataErrors?.start_at && "is-invalid"}
                dateValue={formData.start_at}
                maxDate={
                  formData.finish_at &&
                  sub(new Date(formData.finish_at), { days: 1 })
                }
                onChange={(date) => onChange("start_at", date)}
              />
              <Form.Text className="text-danger">
                {formDataErrors?.start_at}
              </Form.Text>
            </Form.Group>
          )}
          {isNews && (
            <Form.Group className="d-flex flex-column d-md-block">
              <Form.Label>bitiş tarihi *</Form.Label>
              <TKDatePicker
                className={formDataErrors?.finish_at && "is-invalid"}
                placeholder={`ör: ${formatDate(
                  add(new Date(), { days: 1 }),
                  "P p"
                )}`}
                dateValue={formData.finish_at}
                minDate={
                  formData.start_at &&
                  add(new Date(formData.start_at), { days: 1 })
                }
                onChange={(dateValue) => onChange("finish_at", dateValue)}
              />
              <Form.Text className="text-danger">
                {formDataErrors?.finish_at}
              </Form.Text>
            </Form.Group>
          )}
          {isNews && (
            <Form.Group>
              <Form.Label>sıra seçimi</Form.Label>
              <Form.Control
                type="number"
                name="order_number"
                value={formData.order_number || ""}
                onChange={(e) => onChange(e.target.name, e.target.value)}
                //isInvalid={formDataErrors?.order_number}
              />
              {/* 
                <Form.Text className="text-danger">
                  {formDataErrors?.order_number}
                </Form.Text>
                */}
            </Form.Group>
          )}
        </Col>
        {!isNews && (
          <Col>
            <Row xs={1} md={2}>
              <Col>
                <Form.Group className="d-flex flex-column d-md-block">
                  <Form.Label>başlama tarihi *</Form.Label>
                  <TKDatePicker
                    className={formDataErrors?.start_at && "is-invalid"}
                    dateValue={formData.start_at}
                    maxDate={
                      formData.finish_at &&
                      sub(new Date(formData.finish_at), { days: 1 })
                    }
                    onChange={(date) => onChange("start_at", date)}
                  />
                  <Form.Text className="text-danger">
                    {formDataErrors?.start_at}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="d-flex flex-column d-md-block">
                  <Form.Label>bitiş tarihi *</Form.Label>
                  <TKDatePicker
                    className={formDataErrors?.finish_at && "is-invalid"}
                    placeholder={`ör: ${formatDate(
                      add(new Date(), { days: 1 }),
                      "P p"
                    )}`}
                    dateValue={formData.finish_at}
                    minDate={
                      formData.start_at &&
                      add(new Date(formData.start_at), { days: 1 })
                    }
                    onChange={(dateValue) => onChange("finish_at", dateValue)}
                  />
                  <Form.Text className="text-danger">
                    {formDataErrors?.finish_at}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label>
                kampanya {amountLabel[formData.campaign_type]} *
              </Form.Label>
              <Form.Group className="mb-0">
                <Form.Check
                  inline
                  className="mb-2"
                  type="radio"
                  id="percent-discount"
                  label="yüzde indirim"
                  name="campaign_type"
                  value={PERCENT}
                  defaultChecked={formData.campaign_type === PERCENT}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
                <Form.Check
                  inline
                  className="mb-2"
                  type="radio"
                  id="fixed-discount"
                  label="sabit indirim"
                  name="campaign_type"
                  value={FIXED}
                  defaultChecked={formData.campaign_type === FIXED}
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                />
              </Form.Group>
              <Form.Control
                type="text"
                name="amount"
                placeholder={`Kampanya ${amountLabel[formData.campaign_type]}`}
                value={formData.amount || ""}
                onChange={handleAmountOnChange}
                isInvalid={formDataErrors.amount}
              />
              <Form.Text className="text-danger">
                {formDataErrors?.amount}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Group>
                <Form.Label>
                  Katılımcı Sayısı (Sınırsız Katılımcı için sıfır bırakınız)
                </Form.Label>
                <Form.Control
                  type="text"
                  name="max_participant"
                  placeholder={`Katılımcı Sayısı`}
                  value={formData.max_participant}
                  onChange={handleAmountOnChange}
                  onBlur={() => {
                    setIfItIsBlank();
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Tek Kart İçin Maximum Katılım Sayısı</Form.Label>
                <Form.Control
                  type="text"
                  name="max_join_for_holders"
                  placeholder={`Katılım Sayısı`}
                  value={formData.max_join_for_holders}
                  onChange={handleAmountOnChange}
                  onBlur={() => {
                    setIfItIsBlank();
                  }}
                />
              </Form.Group>
              <Form.Label>Kampanya Kodu</Form.Label>
              <Form.Group className="mb-0">
                <Form.Check
                  inline
                  defaultChecked="true"
                  className="my-2"
                  type="radio"
                  id="generate-campaign-code"
                  label="Kampanya Kodu Otomatik Üret"
                  name="campaign_code_type"
                  value={campaignCodeTypes.GENERATE}
                  checked={formData.is_campaign_codes_generated}
                  onClick={(e) =>
                    handleChangeCampaignCode(campaignCodeTypes.GENERATE)
                  }
                />
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div>
                    <Form.Check
                      inline
                      className="d-flex"
                      type="radio"
                      id="upload-campaign-code"
                      label="Kampanya Kodlarım Var"
                      name="campaign_code_type"
                      value={campaignCodeTypes.UPLOAD}
                      checked={formData.is_campaign_codes_uploaded}
                      onClick={(e) =>
                        handleChangeCampaignCode(campaignCodeTypes.UPLOAD)
                      }
                      disabled={formData.is_campaign_codes_generated}
                    />
                  </div>
                  <div className="w-50">
                    <TKFileInput
                      id="excel"
                      selectedFile={formData.excel}
                      onChange={(file) =>
                        onChange("campaign_code", {
                          ...formData?.campaign_code,
                          excel: file,
                        })
                      }
                      className={
                        formDataErrors?.campaign_code &&
                        formData.campaign_code.type === campaignCodeTypes.UPLOAD
                          ? "tk_file-input-error"
                          : ""
                      }
                      fileType="spreadsheet"
                      disabled={
                        formData.is_campaign_codes_generated ||
                        formData.is_campaign_codes_stabil ||
                        formData.is_campaign_codes_generated
                      }
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div>
                    <Form.Check
                      inline
                      className="mb-2"
                      type="radio"
                      id="fixed-campaign-code"
                      label="Sabit Kod"
                      name="campaign_code_type"
                      value={campaignCodeTypes.FIXED}
                      checked={formData.is_campaign_codes_stabil}
                      onClick={(e) =>
                        handleChangeCampaignCode(campaignCodeTypes.FIXED)
                      }
                      disabled={formData.is_campaign_codes_generated}
                    />
                  </div>
                  <div className="w-50">
                    <Form.Control
                      type="text"
                      name="campaign_fixed_code"
                      placeholder={`Sabit Kod`}
                      value={
                        formData
                          ? formData.unused_campaign_codes &&
                            formData.is_campaign_codes_stabil
                            ? formData.unused_campaign_codes[0]
                            : formData?.campaign_code?.fixed_code
                            ? formData?.campaign_code?.fixed_code
                            : ""
                          : ""
                      }
                      onChange={(e) =>
                        onChange("campaign_code", {
                          ...formData?.campaign_code,
                          fixed_code: e.target.value,
                        })
                      }
                      isInvalid={formDataErrors?.campaign_code}
                      disabled={
                        formData.is_campaign_codes_generated ||
                        formData?.unused_campaign_codes
                          ? [0] !== campaignCodeTypes.FIXED
                          : ""
                      }
                    />
                  </div>
                </div>
                <Form.Text className="text-danger">
                  {formDataErrors?.campaign_code}
                </Form.Text>
              </Form.Group>
            </Form.Group>
            {!isPublic && (
              <>
                <Form.Group>
                  <Form.Label>üye işyeri *</Form.Label>
                  <TKSelect
                    defaultTitle={formData?.ownerentitle}
                    selectedItem={formData?.owner}
                    itemSelector="entitle"
                    items={filteredNamesList}
                    itemOnClick={(id) => onChange("owner", id)}
                    isInvalid={formDataErrors.owner}
                    name="owner"
                    onChange={(value) => onSelectChange("owner", value)}
                    filter={true}
                    search={selectSearch}
                  />
                  <Form.Text className="text-danger">
                    {formDataErrors?.owner}
                  </Form.Text>
                </Form.Group>
              </>
            )}
            <Form.Group>
              <Form.Label>Kart Tipine Uygula</Form.Label>
              <TKSelect
                defaultTitle="Lütfen Kart Tipi Seçiniz"
                selectedItem={formData?.cardtype}
                itemSelector="name"
                items={filteredCardTypes}
                itemOnClick={(id) => onChange("cardtype", id)}
                name="cardtype"
              />
            </Form.Group>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default TKContentForm;

TKContentForm.defaultProps = {
  formData: {},
  formDataErrors: {},
  onChange: () => {},
  setIfItIsBlank: () => {},
  isNews: false,
};
