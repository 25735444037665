import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import * as actions from "../actions/faqs";
import {
  GET_TK_FAQ_ADMIN_REQUEST,
  DELETE_TK_ADMIN_FAQ_REQUEST,
  CREATE_TK_ADMIN_FAQ_REQUEST,
  EDIT_TK_ADMIN_FAQ_REQUEST,
} from "../actionTypes";
import { apiCall } from "../../helpers/api";
import { customSwal } from "../../helpers/customSwal";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

function* watchGetTKFAQs() {
  yield takeEvery(GET_TK_FAQ_ADMIN_REQUEST, getTKFAQs);
}

function* watchCreateTKFAQ() {
  yield takeEvery(CREATE_TK_ADMIN_FAQ_REQUEST, createTKFAQ);
}

function* watchEditTKFAQ() {
  yield takeEvery(EDIT_TK_ADMIN_FAQ_REQUEST, editTKFAQ);
}

function* watchDeleteTKFAQ() {
  yield takeEvery(DELETE_TK_ADMIN_FAQ_REQUEST, deleteTKFAQ);
}

function* getTKFAQs(action) {
  try {
    const res = yield call(apiCall, "get", "public/faq");
    yield put(
      actions.getTKFAQAdminSucceeded({
        data: [...res.data.data],
      })
    );
  } catch (err) {
    yield put(actions.getTKFAQAdminFailed());
  }
}

function* createTKFAQ(action) {
  const { data } = action.payload;
  const { caption, detail, order_number } = data;
  console.log(detail);
  try {
    const res = yield call(apiCall, "post", "api/loyalty/faq", {
      type: "faq",
      caption,
      detail,
      order_number,
    });
    yield put(actions.setShowFAQAddModal(false));
    yield put(actions.getTKFAQAdminRequest());
  } catch (err) {
    yield put(actions.getTKFAQAdminFailed());
  }
}

function* editTKFAQ(action) {
  const { data, selectedId } = action.payload;
  const { caption, detail, order_number } = data;

  try {
    const res = yield call(apiCall, "put", `api/loyalty/faq/${selectedId}`, {
      type: "faq",
      caption,
      detail,
      order_number,
    });
    yield put(actions.setShowFAQEditModal(false));
    yield put(actions.getTKFAQAdminRequest());
  } catch (err) {
    yield put(actions.getTKFAQAdminFailed());
  }
}

function* deleteTKFAQ(action) {
  try {
    const { selectedId } = action.payload;
    yield call(apiCall, "put", `api/loyalty/faq/${selectedId}/delete`);
    yield call(customSwal, "Başarılı", "Kampanya başarıyla silindi", "success");
    yield put(actions.setShowFAQDeleteModal(false));
    yield put(actions.getTKFAQAdminRequest());
  } catch (err) {
    yield put(actions.getTKFAQAdminFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

export default function* faqsSaga() {
  yield all([
    fork(watchGetTKFAQs),
    fork(watchCreateTKFAQ),
    fork(watchEditTKFAQ),
    fork(watchDeleteTKFAQ),
  ]);
}
