import * as types from '../actionTypes';

const initialState = {
  displayList: [],
  page: 0,
  hasMore: false,
  timedCamps: {
    currentCamps: [],
    futureCamps: [],
    oldCamps: []
  },
  displayCamps: {},
  loading: false,
  error: false,
  admin: {
    list: [],
    page: 0,
    search: '',
    status: 'ACTIVE',
    totalPages: 0,
    processLoading: false,
    processError: false,
    showAddModal: false,
    showEditModal: false,
    showDeleteModal: false
  }
}

const campaigns = (state = initialState, action) => {
  switch(action.type) {
    case types.POST_TK_CAMPS_EXPORT_EXCEL_REQUEST:
       return {
         ...state
       }
    case types.GET_TK_CAMPS_PARTICIPANT_EXPORT_EXCEL_REQUEST:
        return {
          ...state
        }
    case types.GET_TK_CAMPS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_CAMPS_SUCCEEDED:
      const displayList = action.payload.page > 0 ? 
        [...state.displayList, ...action.payload.data] : 
        [...action.payload.data];
      const hasMore = displayList.length < action.payload.totalCount;
      return {
        ...state,
        displayList,
        page: hasMore ? action.payload.page + 1 : action.payload.page,
        hasMore,
        loading: false,
        error: false
      }
    case types.GET_TK_CAMPS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case types.GET_TK_TIMED_CAMPS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_TIMED_CAMPS_SUCCEEDED:
      return {
        ...state,
        timedCamps: {...action.payload},
        loading: false,
        error: false
      }
    case types.GET_TK_TIMED_CAMPS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case types.GET_TK_CAMPS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_CAMPS_BY_ID_SUCCEEDED:
      return {
        ...state,
        displayCamps: {...action.payload},
        loading: false,
        error: false
      }
    case types.GET_TK_CAMPS_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case types.GET_TK_ADMIN_CAMPS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.GET_TK_ADMIN_CAMPS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: false,
        admin: {
          ...state.admin,
          list: [...action.payload.data],
          page: action.payload.page,
          search: action.payload.search,
          status: action.payload.status,
          totalPages: action.payload.totalPages
        }
      }
    case types.GET_TK_ADMIN_CAMPS_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case types.CREATE_TK_ADMIN_CAMPS_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_CAMPS_ADD_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showAddModal: action.payload
        }
      }
    case types.EDIT_TK_ADMIN_CAMPS_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_CAMPS_EDIT_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showEditModal: action.payload
        }
      }
    case types.DELETE_TK_ADMIN_CAMPS_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false
        }
      }
    case types.SET_SHOW_CAMPS_DELETE_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showDeleteModal: action.payload
        }
      }
    case types.PROCESS_TK_ADMIN_CAMPS_SUCCEEDED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: false
        }
      }
    case types.PROCESS_TK_ADMIN_CAMPS_FAILED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: true
        }
      }
    case types.CLEAR_TK_CAMPS:
      return { ...initialState }
    default:
      return state;
  }
}

export default campaigns;