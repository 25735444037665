import * as types from '../actionTypes';

// cams -> campaigns
const initialState = {
  homeCamsList: [],
  homeCamsLoading: false,
  homeCamsError: false,
  homeNewsList: [],
  homeNewsLoading: false,
  homeNewsError: false,
  homeBannersList: [],
  homeBannersLoading: false,
  homeBannersError: false,
  homeStatus:{},
  homeStatusLoading: false,
  homeStatusError: false,
}

const homepage = (state = initialState, action) => {


  switch(action.type) {
    case types.GET_TK_HP_CAMPS_REQUEST:
      return {
        ...state,
        homeCamsLoading: true,
        homeCamsError: false,
      }
    case types.GET_TK_HP_CAMPS_SUCCEEDED:
      return {
        ...state,
        homeCamsList: [...action.payload],
        homeCamsLoading: false,
        homeCamsError: false,
      }
    case types.GET_TK_HP_CAMPS_FAILED:
      return {
        ...state,
        homeCamsLoading: false,
        homeCamsError: true,
      }
    case types.GET_TK_HP_NEWS_REQUEST:
      return {
        ...state,
        homeNewsLoading: true,
        homeNewsError: false,
      }
    case types.GET_TK_HP_NEWS_SUCCEEDED:
      return {
        ...state,
        homeNewsList: [...action.payload],
        homeNewsLoading: false,
        homeNewsError: false,
      }
    case types.GET_TK_HP_NEWS_FAILED:
      return {
        ...state,
        homeNewsLoading: false,
        homeNewsError: true,
      }
    case types.GET_TK_HP_BANNERS_REQUEST:
      return {
        ...state,
        homeBannersLoading: true,
        homeBannersError: false,
      }
    case types.GET_TK_HP_BANNERS_SUCCEEDED:
      return {
        ...state,
        homeBannersList: [...action.payload],
        homeBannersLoading: false,
        homeBannersError: false,
      }
    case types.GET_TK_HP_BANNERS_FAILED:
      return {
        ...state,
        homeBannersLoading: false,
        homeBannersError: true,
      }
      case types.GET_TK_HP_STATUS_REQUEST:
        return {
          ...state,
          homeStatusLoading: true,
          homeStatusError: false,
        }
      case types.GET_TK_HP_STATUS_SUCCEEDED:
        return {
          ...state,
          homeStatus: action.payload,
          homeBannersLoading: false,
          homeBannersError: false,
        }
      case types.GET_TK_HP_STATUS_FAILED:
        return {
          ...state,
          homeStatusLoading: false,
          homeStatusError: true,
        }
    default:
      return state;
  }
}

export default homepage;