import React, { useEffect, useState } from 'react';
import './AllCampaigns.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TKFilterContainer from '../../../../../components/TKFilterContainer/TKFilterContainer';
import TKCardsContainer from '../../../../../components/TKCardsContainer/TKCardsContainer';
import TKCard from '../../../../../components/TKCard/TKCard';
import {
  clearTKCamps,
  getTKCampsRequest,
} from '../../../../../store/actions/campaigns';
import { formatUrl, handleRedirect } from '../../../../../helpers/utilities';
import TKLoaderContainer from '../../../../../components/TKLoaderContainer/TKLoaderContainer';
import { getRandomBgColorForCampaign } from '../../../../../helpers/utilities';

const AllCampaigns = () => {
  const typeLabels = {
    current: 'Devam Eden',
    future: 'Gelecek',
    past: 'Geçmiş',
  };

  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { displayList, page, hasMore, loading } = useSelector(
    (state) => state.campaigns
  );

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  useEffect(() => {
    fetchData(null, null, page);
    return () => dispatch(clearTKCamps());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (list, filterType, page) => {
    let reqParams = {
      page,
      type: state?.type || 'current',
    };
    if (list && list.length > 0) {
      reqParams = {
        ...reqParams,
        [filterType]: list,
      };
    }
    dispatch(getTKCampsRequest(reqParams));
  };

  const output = displayList.map(
    ({ _id, image, short_detail, campaign_type, amount }, i) => (
      <TKCard
        key={i}
        shadow={false}
        size="lg"
        bgColor="white"
        badge={true}
        image={image}
        desc={short_detail}
        href={`/kampanyalar/${formatUrl(short_detail)}`}
        onClick={() =>
          handleRedirect(history, `/kampanyalar/${formatUrl(short_detail)}`, {
            bgColor: getRandomBgColorForCampaign(),
          })
        }
        campaign={{ campaign_type, amount }}
      />
    )
  );

  return (
    <div className="container mt-5">
      <TKFilterContainer
        filterTitle="kampanya"
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        brand={true}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
        filterHandler={(list, filterType) => fetchData(list, filterType, 0)}
      />
      {loading && !hasMore ? (
        <TKLoaderContainer />
      ) : (
        <div className="tk_camps-cards-container">
          <TKCardsContainer
            header={`${
              typeLabels[state?.type] ? typeLabels[state?.type] : ''
            } kampanyalar`}
            dataLength={displayList.length}
            fetchData={() => fetchData(selectedCategories, 'categories', page)}
            hasMore={hasMore}
          >
            {output}
          </TKCardsContainer>
        </div>
      )}
    </div>
  );
};

export default AllCampaigns;
