import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TKAddNewButton from '../AdminLayout/TKAddNewButton/TKAddNewButton';
import TKExcelExportButton from '../AdminLayout/TKExcelExportButton/TKExcelExportButton';
import TKContentForm from '../AdminLayout/TKForm/TKContentForm/TKContentForm';
import TKMemberWorkplacesForm from '../AdminLayout/TKForm/TKMemberWorkplacesForm/TKMemberWorkplacesForm';
import './TKAddExportContainer.scss';

const TKAddExportContainer = ({ type ,formData, showAddModal, handleSetShowAddModal, formDataErrors,
     handleSaveOnClick, processLoading, handleOnChange,handleExportClick,setIfItIsBlank}) => {
  return (
    <Container>
        <Row>
            <Col>     
            <TKExcelExportButton 
                onClick={handleExportClick} 
                btnTitle="Dışa Aktar">
             </TKExcelExportButton>
            </Col>
            <Col sm={2}> 
            
            <TKAddNewButton 
        showModal={showAddModal}
        setShowModal={handleSetShowAddModal}
        btnTitle={type}
        modalHeader={`${type} ekleme Formu`} 
        handleSaveOnClick={handleSaveOnClick}
        processLoading={processLoading}
        size="xl"
      >
            
             { type ==='Üye İş Yeri' && 
        <>
          <TKMemberWorkplacesForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
        />
      </>
     }
     { type ==='Kampanya' && 
        <>
        <TKContentForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          setIfItIsBlank={setIfItIsBlank}
        />
      </>
     }

     { type ==='Haber' && 
        <>
        <TKContentForm
          formData={formData}
          formDataErrors={formDataErrors}
          onChange={handleOnChange}
          isNews={true}
        />
      </>
     }
     
     </TKAddNewButton>
      </Col>
        </Row>     
  </Container>
    );
};

export default TKAddExportContainer;
TKAddExportContainer.defaultProps = {
  setIfItIsBlank: () => {}
}