import * as types from '../actionTypes';

export const getTKComplainsRequest = reqParams => ({
    type: types.GET_TK_COMPLAIN_REQUEST,
    payload: reqParams
  });

  export const getTKComplainsSucceeded = ({data}) => ({
    type: types.GET_TK_COMPLAIN_SUCCEEDED,
    payload: {
      data
    }
  });
  
  export const getTKComplainsFailed = () => ({
    type: types.GET_TK_COMPLAIN_FAILED
  });

  export const setShowComplainViewModal = show => ({
    type: types.SET_SHOW_COMPLAIN_VIEW_MODAL,
    payload: show
  });
  

  export const seenTKComplainRequest = reqParams => ({
    type: types.SEEN_TK_COMPLAIN_REQUEST,
    payload: reqParams
  });