import React from 'react';
import { Form } from 'react-bootstrap';
import TKDatePicker from '../../TKInputs/TKDatePicker/TKDatePicker'; 
import { add, sub } from 'date-fns';
import { formatDate } from '../../../../helpers/utilities';


const TKReportDatePicker = ({formDatePickerData, formDatePickerDataErrors, onChange}) => {
  return (
    <Form> 
         <Form.Group className="d-flex flex-column d-sm-block">
              <Form.Label>başlama tarihi *</Form.Label>
              <TKDatePicker
                className={formDatePickerDataErrors?.start_at && "is-invalid"}
                dateValue={formDatePickerData.start_at}
                maxDate={formDatePickerData.finish_at && sub(new Date(formDatePickerData.finish_at), {days: 1})}
                onChange={(date) => onChange('start_at', date)}
              />
             <Form.Text className="text-danger">{formDatePickerDataErrors?.start_at}</Form.Text>
          </Form.Group>
          <Form.Group className="d-flex flex-column d-sm-block">
          <Form.Label>bitiş tarihi *</Form.Label>
          <TKDatePicker
            className={formDatePickerDataErrors?.finish_at && "is-invalid"}
            placeholder={`ör: ${formatDate(add(new Date(), {days: 1}), "P p")}`}
            dateValue={formDatePickerData.finish_at}
            minDate={formDatePickerData.start_at && add(new Date(formDatePickerData.start_at), {days: 1})}
            onChange={(dateValue) => onChange('finish_at', dateValue)}
          />
          <Form.Text className="text-danger">{formDatePickerDataErrors?.finish_at}</Form.Text>
        </Form.Group>
    </Form>
  );
};

export default TKReportDatePicker;

TKReportDatePicker.defaultProps = {
  formDatePickerData: {},
  formDatePickerDataErrors: {},
  onChange: () => {}
}