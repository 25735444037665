import * as types from '../actionTypes';

// PUBLIC
export const getTKCampsRequest = reqParams => ({
  type: types.GET_TK_CAMPS_REQUEST,
  payload: reqParams
});

export const getTKCampsSucceeded = data => ({
  type: types.GET_TK_CAMPS_SUCCEEDED,
  payload: data
});

export const getTKCampsFailed = () => ({
  type: types.GET_TK_CAMPS_FAILED
});

export const getTKTimedCampsRequest = reqParams => ({
  type: types.GET_TK_TIMED_CAMPS_REQUEST,
  payload: reqParams
});

export const getTKTimedCampsSucceeded = data => ({
  type: types.GET_TK_TIMED_CAMPS_SUCCEEDED,
  payload: data
});

export const getTKTimedCampsFailed = () => ({
  type: types.GET_TK_TIMED_CAMPS_FAILED
});

export const getTKCampsByIdRequest = id => ({
  type: types.GET_TK_CAMPS_BY_ID_REQUEST,
  payload: id
});

export const getTKCampsByIdSucceeded = data => ({
  type: types.GET_TK_CAMPS_BY_ID_SUCCEEDED,
  payload: data
});

export const getTKCampsByIdFailed = () => ({
  type: types.GET_TK_CAMPS_BY_ID_FAILED
});

export const clearTKCamps = () => ({
  type: types.CLEAR_TK_CAMPS
});

// ADMIN
// LIST
export const getTKAdminCampsRequest = reqParams => ({
  type: types.GET_TK_ADMIN_CAMPS_REQUEST,
  payload: reqParams
});

export const getTKAdminCampsSucceeded = ({data, page, search, status, totalPages}) => ({
  type: types.GET_TK_ADMIN_CAMPS_SUCCEEDED,
  payload: {
    data,
    page,
    search,
    status,
    totalPages
  }
});

export const getTKAdminCampsFailed = () => ({
  type: types.GET_TK_ADMIN_CAMPS_FAILED
});

// CREATE
export const createTKAdminCampsRequest = reqParams => ({
  type: types.CREATE_TK_ADMIN_CAMPS_REQUEST,
  payload: reqParams
});

export const setShowCampsAddModal = show => ({
  type: types.SET_SHOW_CAMPS_ADD_MODAL,
  payload: show
});

// EDIT
export const editTKAdminCampsRequest = reqParams => ({
  type: types.EDIT_TK_ADMIN_CAMPS_REQUEST,
  payload: reqParams
});

export const setShowCampsEditModal = show => ({
  type: types.SET_SHOW_CAMPS_EDIT_MODAL,
  payload: show
});

// DELETE
export const deleteTKAdminCampsRequest = reqParams => ({
  type: types.DELETE_TK_ADMIN_CAMPS_REQUEST,
  payload: reqParams
});

export const setShowCampsDeleteModal = show => ({
  type: types.SET_SHOW_CAMPS_DELETE_MODAL,
  payload: show
});

export const processTKAdminCampsSucceeded = () => ({
  type: types.PROCESS_TK_ADMIN_CAMPS_SUCCEEDED
});

export const processTKAdminCampsFailed = () => ({
  type: types.PROCESS_TK_ADMIN_CAMPS_FAILED
});

export const postTKCampsExportExcelRequest = reqParams => ({
  type: types.POST_TK_CAMPS_EXPORT_EXCEL_REQUEST,
  payload: reqParams
});


export const getTKCampsParticipantExportExcelRequest = reqParams => ({
  type: types.GET_TK_CAMPS_PARTICIPANT_EXPORT_EXCEL_REQUEST,
  payload: reqParams
});