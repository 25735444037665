import * as types from '../actionTypes'

const initialState = {
  user: {},
  userInfo: null,
  loading: false,
  telephone_number:'',
  tckn:'',
  name:'',
  surname:'',
  error: false
}

const auth = (state = initialState, action) => {


  switch(action.type) {
    case types.TK_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      }
    case types.TK_LOGIN_SUCCEEDED:
      return {
        ...state,
        user: {...action.payload},
        loading: false,
        error: false
      }
    case types.TK_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case types.TK_LOGOUT:
      localStorage.clear();
      return {...initialState}
    case types.TK_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case types.TK_USER_INFO_SUCCEEDED:
      return {
        ...state,
        userInfo: action.payload,
        loading: false,
        error: false
      }
    case types.TK_USER_INFO_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case types.TK_REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case types.TK_REGISTER_SUCCEEDED:
      return {
        ...state,
        telephone_number:action.payload.data.telephone_number,
        name:action.payload.data.name,
        surname:action.payload.data.surname,
        tckn:action.payload.data.tckn,
        loading: false,
        error: false,
      }
    case types.TK_REGISTER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case types.TK_UPDATE_LINKS:
        return {
          ...state,
          user: {
            ...state.user,
            links: action.payload.data.links,
          },
          loading: false,
          error: false
        }
    default:
      return state;
  }
}

export default auth;
