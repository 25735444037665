import React from "react";
import "./TKDetailsContent.scss";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import parse from "html-react-parser";
import { isJsonString } from "../../helpers/utilities";

const TKDetailsContent = ({
  title,
  desc,
  cardtype,
  max_participant,
  max_join_for_holders,
  isNews,
}) => {
  console.log(cardtype);

  return (
    <div className="tk_details-content">
      <div className="tk_display-m">{title}</div>
      <div className="tk_details-content-desc">
        {cardtype !== undefined && (
          <div>Bu kampanya sadece {cardtype} lılar için geçerlidir.</div>
        )}
        {isJsonString(desc) && isNews ? (
          <Editor
            toolbarHidden
            readOnly
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={EditorState.createWithContent(
              convertFromRaw(JSON.parse(desc))
            )}
          />
        ) : (
          parse(desc)
        )}
        {max_participant === "0" && <div>Kişi sınırı yoktur.</div>}
        {max_participant !== undefined && max_participant !== "0" && (
          <div>Katılabilecek maksimum kişi sayısı {max_participant}</div>
        )}
        {max_join_for_holders !== undefined && (
          <div>Bir katılımcı {max_join_for_holders} kez katılabilir.</div>
        )}
      </div>
    </div>
  );
};

export default TKDetailsContent;

TKDetailsContent.defaultProps = {
  title: "",
  desc: "",
};
