import * as types from "../actionTypes";

const initialState = {
  displayList: [],
  page: 0,
  hasMore: false,
  displayMWP: {},
  loading: false,
  error: false,
  namesList: [],
  exportList: [],
  namesloading: false,
  namesError: false,
  vkn: "",
  admin: {
    list: [],
    page: 0,
    totalPages: 0,
    search: "",
    status: "ACTIVE",
    processLoading: false,
    processError: false,
    showAddModal: false,
    showEditModal: false,
    showDeleteModal: false,
    showTransModal: false,
  },
};

const memberWorkplaces = (state = initialState, action) => {
  switch (action.type) {
    case types.EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_WITHOUT_FILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_WITHOUT_FILE_SUCCEEDED:
      return {
        ...state,
        exportList: [...action.payload.data],
      };
    case types.EXPORT_TK_COMPANY_DISCOUNT_EXPORT_EXCEL_WITHOUT_FILE_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.POST_TK_MWP_EXPORT_EXCEL_REQUEST:
      return {
        ...state,
      };
    case types.GET_TK_MEMBER_WORKPLACES_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_TK_MEMBER_WORKPLACES_SUCCEEDED:
      const displayList =
        action.payload.page > 0
          ? [...state.displayList, ...action.payload.data]
          : [...action.payload.data];
      const hasMore = displayList.length < action.payload.totalCount;
      return {
        ...state,
        displayList,
        page: hasMore ? action.payload.page + 1 : action.payload.page,
        hasMore,
        loading: false,
        error: false,
      };
    case types.GET_TK_MEMBER_WORKPLACES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.PREREGISTER_TK_MEMBER_WORKPLACES_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.PREREGISTER_TK_MEMBER_WORKPLACES_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case types.PREREGISTER_TK_MEMBER_WORKPLACES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.GET_TK_MWP_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_TK_MWP_BY_ID_SUCCEEDED:
      return {
        ...state,
        displayMWP: { ...action.payload },
        loading: false,
        error: false,
      };
    case types.GET_TK_MWP_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.GET_TK_MWP_NAMES_REQUEST:
      return {
        ...state,
        namesLoading: true,
        namesError: false,
      };
    case types.GET_TK_MWP_NAMES_SUCCEEDED:
      return {
        ...state,
        namesList: [...action.payload],
        namesLoading: false,
        namesError: false,
      };
    case types.GET_TK_MWP_NAMES_FAILED:
      return {
        ...state,
        namesLoading: false,
        namesError: true,
      };
    case types.GET_TK_ADMIN_MWP_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_TK_ADMIN_MWP_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: false,
        admin: {
          ...state.admin,
          list: [...action.payload.data],
          page: action.payload.page,
          search: action.payload.search,
          status: action.payload.status,
          totalPages: action.payload.totalPages,
        },
      };
    case types.GET_TK_ADMIN_MWP_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.CREATE_TK_ADMIN_MWP_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false,
        },
      };
    case types.SET_SHOW_MWP_ADD_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showAddModal: action.payload,
        },
      };
    case types.EDIT_TK_ADMIN_MWP_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false,
        },
      };
    case types.SET_SHOW_MWP_EDIT_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showEditModal: action.payload,
        },
      };
    case types.SET_SHOW_MWP_TRANS_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showTransModal: action.payload,
        },
      };
    case types.DELETE_TK_ADMIN_MWP_REQUEST:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: true,
          processError: false,
        },
      };
    case types.SET_SHOW_MWP_DELETE_MODAL:
      return {
        ...state,
        admin: {
          ...state.admin,
          showDeleteModal: action.payload,
        },
      };
    case types.PROCESS_TK_ADMIN_MWP_SUCCEEDED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: false,
        },
      };
    case types.PROCESS_TK_ADMIN_MWP_FAILED:
      return {
        ...state,
        admin: {
          ...state.admin,
          processLoading: false,
          processError: true,
        },
      };
    case types.TK_MWP_FORGOTPASSWORD_SUCCEEDED: {
      return {
        ...state,
        vkn: action.payload.data.vkn,
      };
    }
    case types.CLEAR_TK_MEMBER_WORKPLACES:
      return { ...initialState };
    default:
      return state;
  }
};

export default memberWorkplaces;
