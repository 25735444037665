import * as types from '../actionTypes';

// REPORTS
// LIST
export const getTKAdminReportsTkartRequest = (reqParams) => ({
  type: types.GET_TK_ADMIN_REPORTS_TKART_REQUEST,
  payload: reqParams,
});

export const getTKAdminReportsTkartSucceeded = (data) => ({
  type: types.GET_TK_ADMIN_REPORTS_TKART_SUCCEEDED,
  payload: data,
});

export const getTKAdminReportsTkartFailed = () => ({
  type: types.GET_TK_ADMIN_REPORTS_TKART_FAILED,
});

export const getTKAdminReportsGencKartRequest = (reqParams) => ({
  type: types.GET_TK_ADMIN_REPORTS_GENCKART_REQUEST,
  payload: reqParams,
});

export const getTKAdminReportsGencKartSucceeded = (data) => ({
  type: types.GET_TK_ADMIN_REPORTS_GENCKART_SUCCEEDED,
  payload: data,
});

export const getTKAdminReportsGencKartFailed = () => ({
  type: types.GET_TK_ADMIN_REPORTS_GENCKART_FAILED,
});

export const getTKAdminReportsGaziKartRequest = (reqParams) => ({
  type: types.GET_TK_ADMIN_REPORTS_GAZIKART_REQUEST,
  payload: reqParams,
});

export const getTKAdminReportsGaziKartSucceeded = (data) => ({
  type: types.GET_TK_ADMIN_REPORTS_GAZIKART_SUCCEEDED,
  payload: data,
});

export const getTKAdminReportsGaziKartFailed = () => ({
  type: types.GET_TK_ADMIN_REPORTS_GAZIKART_FAILED,
});

export const getTKAdminReportsActiveUsersRequest = reqParams => ({
  type: types.GET_TK_ADMIN_REPORTS_ACTIVEUSERS_REQUEST,
  payload: reqParams
});

export const getTKAdminReportsActiveCompaniesRequest = reqParams => ({
  type: types.GET_TK_ADMIN_REPORTS_ACTIVECOMPANIES_REQUEST,
  payload: reqParams
});

export const getTKAdminReportsMostCampaignsRequest = reqParams => ({
  type: types.GET_TK_ADMIN_REPORTS_MOSTCAMPAIGNS_REQUEST,
  payload: reqParams
});