import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { apiCall } from "../../helpers/api";
import * as actions from "../actions/memberWorkplaceCampaigns";
import {
  GET_TK_MWP_CAMPS_REQUEST,
  CREATE_TK_MWP_CAMPS_REQUEST,
  EDIT_TK_MWP_CAMPS_REQUEST,
  DELETE_TK_MWP_CAMPS_REQUEST,
} from "../actionTypes";
import { customSwal } from "../../helpers/customSwal";
import { campaignCodeTypes } from "../../constants/general";

function* watchGetTKMWPCamps() {
  yield takeEvery(GET_TK_MWP_CAMPS_REQUEST, getTKMWPCamps);
}

function* getTKMWPCamps(action) {
  const SIZE = 50;
  try {
    const { page, search, status } = action.payload;
    const res = yield call(
      apiCall,
      "get",
      "api/companyuser/campaigns"
      // {
      //   page,
      //   ...(search ? {search} : {}),
      //   ...(status ? {status} : {}),
      //   size: SIZE
      // }
    );
    yield put(
      actions.getTKMWPCampsSucceeded({
        data: [...res.data.data],
        page,
        search,
        status,
        totalPages: Math.ceil(res.data.totalCount / SIZE),
      })
    );
  } catch (err) {
    yield put(actions.getTKMWPCampsFailed());
  }
}

function* watchCreateTKMWPCamps() {
  yield takeLatest(CREATE_TK_MWP_CAMPS_REQUEST, createTKMWPCamps);
}

function* createTKMWPCamps(action) {
  try {
    const { data, page, search, handleSetShowCampsAddModal } = action.payload;
    const {
      s_short_detail,
      short_detail,
      detail,
      plain_detail,
      image,
      start_at,
      finish_at,
      campaign_type,
      amount,
      owner,
      campaign_code,
      max_participant,
      cardtype,
      max_join_for_holders,
      district,
      province,
    } = data;
    let formData = new FormData();
    formData.append("type", "campaign");
    formData.append("status", "WFA"); // Always passive on create and update
    formData.append("caption", "caption");
    formData.append("short_detail", short_detail);
    formData.append("s_short_detail", s_short_detail);
    plain_detail && formData.append("plain_detail", plain_detail);
    formData.append("detail", detail);
    formData.append("image", image);
    formData.append("start_at", start_at.toISOString());
    formData.append("finish_at", finish_at.toISOString());
    formData.append("campaign_type", campaign_type);
    formData.append("amount", amount);
    formData.append("cardtype", cardtype);
    formData.append("owner", owner);
    formData.append("max_participant", max_participant);
    formData.append("max_join_for_holders", max_join_for_holders);

    if (campaign_code.type === campaignCodeTypes.FIXED) {
      formData.append("is_campaign_codes_stabil", true);
    } else {
      formData.append("is_campaign_codes_stabil", false);
    }
    if (campaign_code.type === campaignCodeTypes.GENERATE) {
      formData.append("is_campaign_codes_generated", true);
    } else {
      formData.append("is_campaign_codes_generated", false);
    }
    if (campaign_code.type === campaignCodeTypes.UPLOAD) {
      formData.append("is_campaign_codes_uploaded", true);
    } else {
      formData.append("is_campaign_codes_uploaded", false);
    }

    const res = yield call(apiCall, "post", "api/loyalty/campaign", formData);

    if (campaign_code?.type && res.data.data._id) {
      if (
        campaign_code.type === campaignCodeTypes.GENERATE &&
        max_participant
      ) {
        yield call(
          apiCall,
          "get",
          `api/loyalty/campaign/setCampaignCodes/${res.data.data._id}/${max_participant}`
        );
      } else if (
        campaign_code.type === campaignCodeTypes.UPLOAD &&
        campaign_code?.excel
      ) {
        const ccFormData = new FormData();
        ccFormData.append("excel", campaign_code.excel);
        yield call(
          apiCall,
          "post",
          `api/loyalty/campaign/setCampaignCodes/${res.data.data._id}`,
          ccFormData
        );
      } else if (campaign_code.type === campaignCodeTypes.FIXED) {
        const fixed_campaign_code = campaign_code?.fixed_code
          ? campaign_code.fixed_code
          : `TUZLA0${Math.random().toString(36).substr(2, 5).toUpperCase()}`;
        yield call(
          apiCall,
          "post",
          `api/loyalty/campaign/setCampaignCodes/${res.data.data._id}`,
          {
            unused_campaign_codes: [fixed_campaign_code],
          }
        );
      }
    }

    yield put(actions.processTKMWPCampsSucceeded());
    yield call(
      customSwal,
      "Başarılı",
      "Kampanya başarıyla oluşturuldu",
      "success"
    );
    yield call(handleSetShowCampsAddModal, false);
    yield put(
      actions.getTKMWPCampsRequest({
        page,
        search,
        status: action.payload.status,
      })
    );
  } catch (err) {
    yield put(actions.processTKMWPCampsFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchEditTKMWPCamps() {
  yield takeLatest(EDIT_TK_MWP_CAMPS_REQUEST, editTKMWPCamps);
}

function* editTKMWPCamps(action) {
  try {
    const { data, selectedId, page, search, handleSetShowCampsEditModal } =
      action.payload;
    const {
      s_short_detail,
      short_detail,
      detail,
      plain_detail,
      image,
      start_at,
      finish_at,
      campaign_type,
      amount,
      owner,
      campaign_code,
      max_participant,
      max_join_for_holders,
      cardtype,
    } = data;

    let formData = new FormData();
    formData.append("status", "WFA"); // Always passive on create and update
    formData.append("short_detail", short_detail);
    formData.append("s_short_detail", s_short_detail);
    formData.append("detail", detail);
    plain_detail && formData.append("plain_detail", plain_detail);
    image && formData.append("image", image);
    formData.append("start_at", start_at.toISOString());
    formData.append("finish_at", finish_at.toISOString());
    formData.append("campaign_type", campaign_type);
    formData.append("amount", amount);
    formData.append("owner", owner);
    formData.append("max_participant", max_participant);
    formData.append("max_join_for_holders", max_join_for_holders);
    formData.append("cardtype", cardtype);

    yield call(apiCall, "put", `api/loyalty/campaign/${selectedId}`, formData);

    yield put(actions.processTKMWPCampsSucceeded());
    yield call(
      customSwal,
      "Başarılı",
      "Kampanya başarıyla güncellendi",
      "success"
    );
    yield call(handleSetShowCampsEditModal, false);
    yield put(
      actions.getTKMWPCampsRequest({
        page,
        search,
        status: action.payload.status,
      })
    );
  } catch (err) {
    yield put(actions.processTKMWPCampsFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchDeleteTKMWPCamps() {
  yield takeLatest(DELETE_TK_MWP_CAMPS_REQUEST, deleteTKMWPCamps);
}

function* deleteTKMWPCamps(action) {
  try {
    const { selectedId, page, search, status } = action.payload;
    yield call(apiCall, "put", `api/loyalty/campaign/${selectedId}/delete`);
    yield put(actions.processTKMWPCampsSucceeded());
    yield call(customSwal, "Başarılı", "Kampanya başarıyla silindi", "success");
    yield put(actions.setShowCampsDeleteModal(false));
    yield put(actions.getTKMWPCampsRequest({ page, search, status }));
  } catch (err) {
    yield put(actions.processTKMWPCampsFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

export default function* memberWorkplaceCampaignsSaga() {
  yield all([
    fork(watchGetTKMWPCamps),
    fork(watchCreateTKMWPCamps),
    fork(watchEditTKMWPCamps),
    fork(watchDeleteTKMWPCamps),
  ]);
}
