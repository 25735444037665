import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAccordion from "../../../../components/TKAccordion/TKAccordion";
import TKPageHeader from '../../../../components/TKPageHeader/TKPageHeader';
import TKTabs from '../../../../components/TKTabs/TKTabs';
import { 
  getTKGKNAdminRequest
}
from "../../../../store/actions/gkns";

const GencKartInfo = () => {

  const {list} = useSelector(state => state.gkns.admin);
  const dispatch = useDispatch();
  const numRows = list.length


  useEffect(() => {
    getGKNList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getGKNList = () => {
    dispatch(getTKGKNAdminRequest({}));
  }

  return (
    <>
          <div className="">
         <TKPageHeader header="Genç Kart Nedir?"/>
          <div className="container">
            <div className="tk-tab-res">
              <TKTabs list={list}/>
            </div>
            <div className="tk-acc-res">
              <TKAccordion listFAQ={list}/>
            </div>
          </div>
         </div>
      </>
  );
};

export default GencKartInfo;