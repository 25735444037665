import React from 'react';
import './TKLoader.scss';

const TKLoader = ({size}) => {
  return (
    <div className={`tk_loader ${size ? `tk_loader-${size}` : ""}`.trim()}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default TKLoader;