import React from "react";
import "./TKTransactionsContainer.scss";
import { formatDate } from "../../../../helpers/utilities";
import { handleRedirect } from "../../../../helpers/utilities";
import { useHistory } from "react-router-dom";
import {
  UsersPaymentConfig,
  UsersCampaignConfig,
} from "../../../../constants/columnsConfigurations";
import TKTableCard from "../../../AdminLayout/TKTableCard/TKTableCard";
import TKDataTable from "../../../AdminLayout/TKDataTable/TKDataTable";

const TKTransactionsContainer = ({ list, loading, error }) => {
  const history = useHistory();
  const payments = list.filter(
    (t) => t.type === "PAYMENT" || t.type === "DEPOSIT"
  );
  const campaigns = list.filter((t) => t.type === "CAMPAIGN");

  const paymentsColumns = UsersPaymentConfig({});

  const campaignsColumns = UsersCampaignConfig({});

  return (
    <div className="pb-1 pt-3">
      <div className="my-2 p-1 px-3">
        <div className="tk_display-m mt-2">Yararlandığım Kampanyalar</div>
        <div className="overflow-auto" style={{ height: "250px" }}>
          <TKTableCard>
            <TKDataTable
              columns={campaignsColumns}
              data={campaigns}
              pagination={false}
            />
          </TKTableCard>
        </div>
      </div>
      <div className="my-2 p-1 px-3">
        <div className="tk_display-m mt-2">Tuzla Kart Kullanım Hareketleri</div>
        <div className="overflow-auto" style={{ height: "250px" }}>
          <TKTableCard>
            <TKDataTable
              columns={paymentsColumns}
              data={payments}
              pagination={false}
            />
          </TKTableCard>
        </div>
      </div>
    </div>
  );
};

export default TKTransactionsContainer;

TKTransactionsContainer.defaultProps = {
  list: [],
};
