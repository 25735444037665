import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { apiCall } from "../../helpers/api";
import * as actions from "../actions/links";
import {
  GET_TK_ADMIN_LINKS_REQUEST,
  CREATE_TK_ADMIN_LINKS_REQUEST,
  DELETE_TK_ADMIN_LINKS_REQUEST,
  EDIT_TK_ADMIN_LINKS_REQUEST,
  GET_TK_ADMIN_LINKS_CONTENT_REQUEST,
} from "../actionTypes";
import { customSwal } from "../../helpers/customSwal";

// ADMIN
function* watchGetTKAdminLinks() {
  yield takeEvery(GET_TK_ADMIN_LINKS_REQUEST, getTKAdminLinks);
}

function* getTKAdminLinks(action) {
  try {
    const res = yield call(apiCall, "get", "public/link");

    yield put(
      actions.getTKAdminLinksSucceeded({
        data: [...res.data.data],
      })
    );
  } catch (err) {
    yield put(actions.getTKAdminLinksFailed());
  }
}

function* watchCreateTKAdminLinks() {
  yield takeLatest(CREATE_TK_ADMIN_LINKS_REQUEST, createTKAdminLinks);
}

function* createTKAdminLinks(action) {
  try {
    const { data } = action.payload;
    const { title, link } = data;
    let formData = new FormData();
    formData.append("title", title);
    formData.append("link", link);
    yield call(apiCall, "put", "api/link", data);
    yield put(actions.processTKAdminLinksSucceeded());
    yield call(customSwal, "Başarılı", "Link başarıyla oluşturuldu", "success");
    yield put(actions.getTKAdminLinksRequest({}));
  } catch (err) {
    yield put(actions.processTKAdminLinksFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

// DELETE
function* watchDeleteTKAdminLinks() {
  yield takeLatest(DELETE_TK_ADMIN_LINKS_REQUEST, deleteTKAdminLinks);
}

function* deleteTKAdminLinks(action) {
  try {
    const { selectedId, page } = action.payload;

    yield call(apiCall, "delete", `api/link/${selectedId}`);

    yield put(actions.processTKAdminLinksSucceeded());
    yield call(customSwal, "Başarılı", "Link başarıyla silindi", "success");
    yield put(actions.getTKAdminLinksRequest({}));
  } catch (err) {
    yield put(actions.processTKAdminLinksFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

// EDIT
function* watchEditTKAdminLinks() {
  yield takeLatest(EDIT_TK_ADMIN_LINKS_REQUEST, editTKAdminLinks);
}

function* editTKAdminLinks(action) {
  try {
    const { data, selectedId } = action.payload;
    const { title, link } = data;

    let formData = new FormData();
    if (title) {
      formData.append("title", title);
    }
    if (link) {
      formData.append("link", link);
    }

    yield call(apiCall, "put", `api/loyalty/banner/${selectedId}`, formData);
    yield put(actions.processTKAdminLinksSucceeded());
    yield call(customSwal, "Başarılı", "Link başarıyla güncellendi", "success");
    yield put(actions.getTKAdminLinksRequest({}));
  } catch (err) {
    yield put(actions.processTKAdminLinksFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

export default function* linksSaga() {
  yield all([
    fork(watchGetTKAdminLinks),
    fork(watchCreateTKAdminLinks),
    fork(watchDeleteTKAdminLinks),
    fork(watchEditTKAdminLinks),
  ]);
}
