import * as types from '../actionTypes';

export const getTKMWPEmployeesRequest = () => ({
  type: types.GET_TK_MWP_EMPLOYEES_REQUEST
});

export const getTKMWPEmployeesSucceeded = data => ({
  type: types.GET_TK_MWP_EMPLOYEES_SUCCEEDED,
  payload: data
});

export const getTKMWPEmployeesFailed = () => ({
  type: types.GET_TK_MWP_EMPLOYEES_FAILED
});

// ADMIN
// CREATE
export const createTKMWPEmployeesRequest = reqParams => ({
  type: types.CREATE_TK_MWP_EMPLOYEES_REQUEST,
  payload: reqParams
});

export const setShowMWPEmployeesAddModal = show => ({
  type: types.SET_SHOW_MWP_EMPLOYEES_ADD_MODAL,
  payload: show
});

export const processTKMWPEmployeesSucceeded = () => ({
  type: types.PROCESS_TK_MWP_EMPLOYEES_SUCCEEDED
});

export const processTKMWPEmployeesFailed = () => ({
  type: types.PROCESS_TK_MWP_EMPLOYEES_FAILED
});

// DELETE
export const setShowMWPEmployeesDeleteModal = show => ({
  type: types.SET_SHOW_MWP_EMPLOYEES_DELETE_MODAL,
  payload: show
});

export const deleteTKMWPEmployeesRequest = reqParams => ({
  type: types.DELETE_TK_MWP_EMPLOYEES_REQUEST,
  payload: reqParams
});
