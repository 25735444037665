import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { apiCall } from "../../helpers/api";
import * as actions from "../actions/allTransactions";
import {
  GET_TK_ALL_TRANSACTIONS_REQUEST,
  POST_TK_ALL_TRANSACTIONS_EXPORT_EXCEL_REQUEST,
} from "../actionTypes";
import { customSwal } from "../../helpers/customSwal";
import download from "downloadjs";

function* watchGetAllTransactions() {
  yield takeLatest(GET_TK_ALL_TRANSACTIONS_REQUEST, getAllTransactions);
}

function* getAllTransactions(action) {
  const SIZE = 50;
  try {
    const { page, searchFormData } = action.payload;
    const { tckn, card, type, cardtype, created_end, created_start } =
      searchFormData;

    const res = yield call(
      apiCall,
      "post",
      "api/report/getPaginationTransactions",
      {
        page,
        card,
        tckn,
        type,
        size: SIZE,
        cardtype,
        created_end,
        created_start,
      }
    );

    yield put(
      actions.tkGetAllTransactionsSucceeded({
        data: [...res.data.data],
        page,
        searchFormData,
        totalPages: Math.ceil(res.data.totalCount / SIZE),
      })
    );
  } catch (err) {
    console.log(err);
    yield put(actions.tkGetAllTransactionsFailed());
    yield call(
      customSwal,
      "Hata",
      "Yanlış bir şey oldu, lütfen tekrar deneyiniz",
      "error"
    );
  }
}

function* watchPostTKTransactionsExcelExport() {
  yield takeLatest(
    POST_TK_ALL_TRANSACTIONS_EXPORT_EXCEL_REQUEST,
    postTKTransactionsExcelExport
  );
}

function* postTKTransactionsExcelExport(action) {
  try {
    const { searchFormData } = action.payload;
    const { tckn, card, type, cardtype, created_end, created_start } =
      searchFormData;

    const res = yield call(
      apiCall,
      "post",
      "api/report/exportTransactionsForCardHolders",
      {
        tckn,
        card,
        type,
        cardtype,
        created_end,
        created_start,
      },
      {},
      "blob"
    );

    download(res.data, "islemler.xlsx", "text/xlsx");
  } catch (err) {
    console.log(err);
  }
}

export default function* allTransactionsSaga() {
  yield all([
    fork(watchGetAllTransactions),
    fork(watchPostTKTransactionsExcelExport),
  ]);
}
