import { combineReducers } from 'redux';
import alerts from './alerts';
import homepage from './homepage';
import auth from './auth';
import cardHolders from './cardHolders';
import news from './news';
import campaigns from './campaigns';
import memberWorkplaces from './memberWorkplaces';
import categories from './categories';
import banners from './banners';
import complains from './complains';
import faqs from './faqs';
import tkns from './tkns';
import gkns from './gkns';
import adminUsers from './adminUsers';
import apiUsers from './apiUsers';
import transactions from './transactions';
import allTransactions from './allTransactions';
import organizations from './organizations';
import cardTypes from './cardTypes';
import memberWorkplaceCampaigns from './memberWorkplaceCampaigns';
import memberWorkplaceEmployees from './memberWorkplaceEmployees';
import professions from './professions';
import dashboard from './dashboard';
import links from './links';
import reports from './reports';

const rootReducer = combineReducers({
  alerts,
  homepage,
  auth,
  cardHolders,
  news,
  campaigns,
  memberWorkplaces,
  categories,
  banners,
  complains,
  faqs,
  tkns,
  gkns,
  adminUsers,
  apiUsers,
  transactions,
  allTransactions,
  organizations,
  cardTypes,
  memberWorkplaceCampaigns,
  memberWorkplaceEmployees,
  professions,
  dashboard,
  links,
  reports,
});

export default rootReducer;
