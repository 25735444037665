import React from 'react';
import './TKCardsContainer.scss';
import TKSectionHeader from '../TKSectionHeader/TKSectionHeader';
import InfiniteScroll from 'react-infinite-scroll-component';
import TKLoader from '../TKLoaderContainer/TKLoader/TKLoader';

const TKCardsContainer = ({ header, isShowAll, showAllOnClick, dataLength, fetchData, hasMore, loading, children }) => {
  return (
    <div className="tk_cards-container">
      {header &&
        <TKSectionHeader header={header} isShowAll={isShowAll} showAllOnClick={showAllOnClick} />
      }
      {loading && <TKLoader />}
      <InfiniteScroll
        className="row"
        dataLength={dataLength}
        next={fetchData}
        hasMore={hasMore}
        loader={<TKLoader />}
      >
        {children}
      </InfiniteScroll>
    </div>
  );
};

export default TKCardsContainer;

TKCardsContainer.defaultProps = {
  header: "",
  dataLength: 0,
  fetchData: null,
  hasMore: false,
  loading: false
}