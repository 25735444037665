import React, { useEffect, useState } from "react";
import "./MemberWorkplaces.scss";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TKFilterContainer from "../../../../components/TKFilterContainer/TKFilterContainer";
import TKCardsContainer from "../../../../components/TKCardsContainer/TKCardsContainer";
import TKCard from "../../../../components/TKCard/TKCard";
import {
  clearTKMemberWorkplaces,
  getTKMemberWorkplacesRequest,
} from "../../../../store/actions/memberWorkplaces";
import {
  formatUrl,
  handleRedirect,
  returnHandleRedirect,
} from "../../../../helpers/utilities";
import TKLoaderContainer from "../../../../components/TKLoaderContainer/TKLoaderContainer";
import TKPreregistrationContainer from "../../../../components/TKPreregistrationContainer/TKPreregistrationContainer";
import TKPageHeader from "../../../../components/TKPageHeader/TKPageHeader";
const MemberWorkplaces = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { displayList, page, hasMore, loading } = useSelector(
    (state) => state.memberWorkplaces
  );

  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    fetchData(null, null, page);
    return () => dispatch(clearTKMemberWorkplaces());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (list, filterType, page) => {
    setFormData({});
    let reqParams = {
      page,
    };
    if (list && list.length > 0) {
      reqParams = {
        ...reqParams,
        [filterType]: list,
      };
    }
    dispatch(getTKMemberWorkplacesRequest(reqParams));
  };
  const output = displayList.map(
    ({ _id, image, entitle, detail, discounts }, i) => (
      <TKCard
        key={i}
        shadow={false}
        size="md"
        image={image}
        header={entitle}
        onClick={() => {
          handleRedirect(history, `/uye-firmalar/${formatUrl(entitle)}`);
        }}
        href={`/uye-firmalar/${formatUrl(entitle)}`}
        discounts={discounts}
        badge="memberworkplace"
        style={{ textTransform: "none !important" }}
      />
    )
  );

  const notFoundDiv = (pre = "") => {
    if (selectedCategories?.length) {
      pre = "Aranan Kriterlere Uygun";
    }
    return (
      <div
        className="h5 d-flex justify-content-center align-items-center"
        style={{ minHeight: "100px" }}
      >
        {pre} Üye İşyeri Bulunamadı
      </div>
    );
  };

  const initialFormData = {
    province: "",
    district: "",
  };

  const [formData, setFormData] = useState({});

  const handleOnChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (formData.province && formData.district) {
      dispatch(
        getTKMemberWorkplacesRequest({
          page: 0,
          size: 50,
          province: formData?.province,
          district: formData?.district,
        })
      );
    }
  }, [formData?.province, formData?.district]);

  return (
    <>
      <TKPageHeader header="Üyeler" />
      <div>
        <TKFilterContainer
          filterTitle="üye işyeri"
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          filterHandler={(list, filterType) => fetchData(list, filterType, 0)}
          search={true}
          onChange={handleOnChange}
          formData={formData}
          provincedistrict={true}
        />
        <div className="container">
          <div>
            <TKPreregistrationContainer />
          </div>
          {loading && !hasMore ? (
            <TKLoaderContainer />
          ) : (
            <div className="tk_mw-cards-container">
              <TKCardsContainer
                header="üye işyerleri"
                dataLength={displayList.length}
                fetchData={() =>
                  fetchData(selectedCategories, "categories", page)
                }
                hasMore={hasMore}
              >
                {output?.length ? output : notFoundDiv()}
              </TKCardsContainer>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MemberWorkplaces;
