import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Nav, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { tkLoginRequest } from "../../../store/actions/auth";
import TKIcon from "../../TKIcon/TKIcon";
import TKLogo from "../../TKLogo/TKLogo";
import "./TKLoginForm.scss";
import TKPreregistrationContainer from "../../TKPreregistrationContainer/TKPreregistrationContainer";
import { useHistory } from "react-router-dom";

const TKLoginForm = ({ setShowModal, handleShowModal }) => {
  const history = useHistory();
  const initialFormData = {
    tckn: "",
    vkn: "",
    password: "",
  };

  const INDIVIDUAL = "INDIVIDUAL";
  const CORPORATE = "CORPORATE";

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ ...initialFormData });
  const [formDataErrors, setFormDataErrors] = useState({});
  const [loginType, setLoginType] = useState(INDIVIDUAL);

  const onChange = (name, value) => {
    formDataErrors[name] &&
      setFormDataErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function control() {
    const { password, tckn, vkn } = formData;

    if (loginType === INDIVIDUAL) {
      let tcknError = null;
      let passwordError = null;
      // const regexp = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);

      if (tckn === "") {
        tcknError = "TC kimlik numarası boş bırakılamaz.";
      }
      if (password === "") {
        passwordError = "Parola boş bırakılamaz.";
      }

      if (password !== "" || tckn !== "") {
        if (password.length < 5) {
          passwordError = "Geçersiz parola.";
        }
        if (tckn !== "") {
          // if (!regexp.test(email)) {
          //   emailError = "Geçersiz e-posta.";
          // }
        }
      } else {
        if (password === "") {
          passwordError = "Parola girin.";
        }
        if (tckn === "") {
          tcknError = "TC kimlik numarası girin.";
        }
      }

      if (tcknError || passwordError) {
        setFormDataErrors({
          tckn: tcknError,
          password: passwordError,
        });
        return false;
      }
      return true;
    } else {
      let vknError = null;
      let passwordError = null;
      // const regexp = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);

      if (vknError === "") {
        vknError = "Vergi Kimlik Numarası boş bırakılamaz.";
      }
      if (password === "") {
        passwordError = "Parola boş bırakılamaz.";
      }

      if (password !== "" || vkn !== "") {
        if (password.length < 5) {
          passwordError = "Geçersiz parola.";
        }
      } else {
        if (password === "") {
          passwordError = "Parola girin.";
        }
        if (vkn === "") {
          vknError = "Vergi Kimlik Numarası girin.";
        }
      }

      if (vknError || passwordError) {
        setFormDataErrors({
          vkn: vknError,
          password: passwordError,
        });
        return false;
      }
      return true;
    }
  }

  function login(e) {
    e.preventDefault();
    if (control()) {
      dispatch(
        tkLoginRequest(
          null,
          null,
          formData.password,
          formData.tckn,
          formData.vkn,
          loginType,
          history
        )
      );
    }
  }

  return (
    <>
      <Card className="border-0 tk-login-form">
        <Card.Header className="d-flex bg-primary p-3 justify-content-center align-items-center">
          <Card.Title className="m-0 d-flex justify-content-center align-items-center">
            <div>
              <TKLogo size={150} />
            </div>
            <span>
              <small>
                <i className="text-white">| Giriş Yap</i>
              </small>
            </span>
            <div
              className="position-absolute"
              style={{ right: "10px", top: "10px", transform: "rotate(45deg)" }}
              onClick={() => setShowModal(false)}
            >
              <TKIcon name="plus" color="white" size="15" />
            </div>
          </Card.Title>
        </Card.Header>
        <Tab.Container
          defaultActiveKey={INDIVIDUAL}
          onSelect={(key) => setLoginType(key)}
        >
          <Row>
            <Col>
              <Nav variant="tabs" className="flex-row pt-1" justify>
                <Nav.Item>
                  <Nav.Link eventKey={INDIVIDUAL}>Bireysel</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={CORPORATE}>Kurumsal</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tab.Content>
                <Tab.Pane eventKey={INDIVIDUAL}>
                  <Card.Body className="p-5">
                    <Form onSubmit={login}>
                      <Form.Group className="mb-2" controlId="formBasicTckn">
                        <Form.Label>TC Kimlik Numarası</Form.Label>
                        <Form.Control
                          placeholder="TC Kimlik Numarası"
                          value={formData.tckn}
                          name="tckn"
                          maxLength="11"
                          onChange={(e) => {
                            onChange(e.target.name, e.target.value);
                          }}
                          isInvalid={formDataErrors.tckn}
                          maxLength="11"
                        />
                        <Form.Text className="text-danger">
                          {formDataErrors.tckn}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Parola</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Parola"
                          value={formData.password}
                          name="password"
                          onChange={(e) => {
                            onChange(e.target.name, e.target.value);
                          }}
                          isInvalid={formDataErrors.password}
                        />
                        <Form.Text className="text-danger">
                          {formDataErrors.password}
                        </Form.Text>
                      </Form.Group>
                      <div className="d-flex w-100 justify-content-between">
                        <div className="d-flex align-items-center">
                          <Button
                            variant="link"
                            size="sm"
                            className="mt-2 mr-2"
                            onClick={() => handleShowModal(true, "signup")}
                          >
                            Kayıt Ol
                          </Button>
                        </div>
                        <div>
                          <Button
                            variant="link"
                            size="sm"
                            className="mt-2 mr-2"
                            onClick={() =>
                              handleShowModal(true, "forgotpassword")
                            }
                          >
                            Şifremi Unuttum
                          </Button>
                          <Button
                            className="mt-2"
                            variant="primary"
                            type="submit"
                          >
                            Giriş
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Card.Body>
                </Tab.Pane>

                <Tab.Pane eventKey={CORPORATE}>
                  <Card.Body className="p-5">
                    <Form onSubmit={login}>
                      <Form.Group className="mb-2" controlId="formBasicVkn">
                        <Form.Label>Vergi Kimlik Numarası</Form.Label>
                        <Form.Control
                          placeholder="Vergi Kimlik Numarası"
                          value={formData.vkn}
                          name="vkn"
                          maxLength="11"
                          onChange={(e) => {
                            onChange(e.target.name, e.target.value);
                          }}
                          isInvalid={formDataErrors.vkn}
                        />
                        <Form.Text className="text-danger">
                          {formDataErrors.vkn}
                        </Form.Text>
                      </Form.Group>

                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Parola</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Parola"
                          value={formData.password}
                          name="password"
                          onChange={(e) => {
                            onChange(e.target.name, e.target.value);
                          }}
                          isInvalid={formDataErrors.password}
                        />
                        <Form.Text className="text-danger">
                          {formDataErrors.password}
                        </Form.Text>
                      </Form.Group>
                      <div className="d-flex w-100 justify-content-between">
                        <div className="d-flex align-items-center">
                          <TKPreregistrationContainer
                            customButton={({ handleShowModal }) => (
                              <Button
                                variant="link"
                                size="sm"
                                className="mt-2 mr-2"
                                onClick={() => handleShowModal(true)}
                              >
                                İşyeri Başvurusu Yap
                              </Button>
                            )}
                          />
                        </div>
                        <div>
                          <Button
                            variant="link"
                            size="sm"
                            className="mt-2 mr-2"
                            onClick={() =>
                              handleShowModal(true, "mwpforgotpassword")
                            }
                          >
                            Şifremi Unuttum
                          </Button>
                          <Button
                            className="mt-2"
                            variant="primary"
                            type="submit"
                          >
                            Giriş
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Card.Body>{" "}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Card>
    </>
  );
};

export default TKLoginForm;

TKLoginForm.defaultProps = {
  setShowModal: () => {},
  handleShowModal: () => {},
};
