import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiCall } from '../../helpers/api';
import * as actions from '../actions/adminUsers';
import { 
  GET_TK_ADMIN_AU_REQUEST, 
  CREATE_TK_ADMIN_AU_REQUEST, 
  EDIT_TK_ADMIN_AU_REQUEST, 
  DELETE_TK_ADMIN_AU_REQUEST
} from '../actionTypes';
import { customSwal } from '../../helpers/customSwal';
import { userRoles } from '../../constants/general';

function* watchGetTKAdminAU() {
  yield takeEvery(GET_TK_ADMIN_AU_REQUEST, getTKAdminAU);
}

function* getTKAdminAU(action) {
  const SIZE = 50;
  try {
    const {page} = action.payload;
    const res = yield call(apiCall, 
      'post', 
      'api/survey/users', 
      {
        role: userRoles.ADMIN,
        status:"ACTIVE"

      }
      // {
      //   page,
      //   size: SIZE,
      // }
    );
    yield put(actions.getTKAdminAUSucceeded({
      data: [...res.data.data],
      // page,
      // totalPages: Math.ceil(res.data.totalCount/SIZE)
      page: 1,
      totalPages: 1,
    }));
  } catch (err) {
    yield put(actions.getTKAdminAUFailed());
  }
}

function* watchCreateTKAdminAU() {
  yield takeLatest(CREATE_TK_ADMIN_AU_REQUEST, createTKAdminAU);
}

function* createTKAdminAU(action) {
  try {
    const {data, page, handleSetShowAUAddModal} = action.payload;
    yield call(apiCall, 
      'post', 
      'api/survey/users/createuser', 
      {
        ...data,
        role: userRoles.ADMIN,
        status:"ACTIVE"
      }
    );
    yield put(actions.processTKAdminAUSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Kullanıcı başarıyla oluşturuldu',
      'success'
    );
    yield call(handleSetShowAUAddModal, false);
    yield put(actions.getTKAdminAURequest({page}));
  } catch (err) {
    yield put(actions.processTKAdminAUFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}

function* watchEditTKAdminAU() {
  yield takeLatest(EDIT_TK_ADMIN_AU_REQUEST, editTKAdminAU);
}

function* editTKAdminAU(action) {
  try {
    const {data, selectedId, page, handleSetShowAUEditModal} = action.payload;
    if (!data.password) {
      delete data.password;
    }
    if (data.links.includes("balance") && !data.links.includes("card-holders")) {
      yield put(actions.processTKAdminAUFailed());
      yield call(customSwal, 
        'Hata',
        'Yükleme Yetkisi Kart Sahipleri Ekran Yetkisi ile beraber verilmelidir.',
        'error'
      );

    }
    else {
      yield call(apiCall, 
        'post', 
        `api/survey/users/updateUser/${selectedId}`, 
        { ...data, role: "admin" }
      );
      yield put(actions.processTKAdminAUSucceeded());
      yield call(customSwal, 
        'Başarılı',
        'Kullanıcı başarıyla güncellendi',
        'success'
      );
      yield call(handleSetShowAUEditModal, false);
      yield put(actions.getTKAdminAURequest({page}));
      // son kullanıcı kontrolü yapılıp tekrar kontrol edilecek
    //  yield put(actions.tkUpdateLinks({data}));
      }
    } 
  catch (err) {
    yield put(actions.processTKAdminAUFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}

function* watchDeleteTKAdminAU() {
  yield takeLatest(DELETE_TK_ADMIN_AU_REQUEST, deleteTKAdminAU);
}

function* deleteTKAdminAU(action) {
  try {
    const {selectedId, page} = action.payload;
    yield call(apiCall, 
      'delete', 
      `api/survey/users/deleteUser/${selectedId}`
    );
    yield put(actions.processTKAdminAUSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Kullanıcı başarıyla silindi',
      'success'
    );
    yield put(actions.setShowAUDeleteModal(false));
    yield put(actions.getTKAdminAURequest({page}));
  } catch (err) {
    yield put(actions.processTKAdminAUFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}

export default function* adminUsersSaga() {
  yield all([
    fork(watchGetTKAdminAU),
    fork(watchCreateTKAdminAU),
    fork(watchEditTKAdminAU),
    fork(watchDeleteTKAdminAU)
  ]);
}