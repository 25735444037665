export const cardHoldersTypeList = [
  {
    title: "belediye çalışanı",
    id: "BELEDIYE_CALISANI",
  },
  {
    title: "memur",
    id: "MEMUR",
  },
  {
    title: "öğretmen",
    id: "TEACHER",
  },
  {
    title: "polis",
    id: "POLIS",
  },
  {
    title: "genç kart",
    id: "GENC_KART",
  },
  {
    title: "üniversite öğrencisi",
    id: "UNIVERSITE_OGRENCISI",
  },
  {
    title: "65+",
    id: "PLUS_65",
  },
  {
    title: "sağlık çalışanı",
    id: "SAGLIK_CALISANI",
  },
  {
    title: "asker",
    id: "ASKER",
  },
];

export const adminUserLinks = [
  {
    title: "Ana Sayfa",
    linkTitle: "Ana Sayfa",
    id: "mainpage",
    isPage: true,
  },
  {
    title: "Kart Sahipleri Yönetimi",
    linkTitle: "Kart Sahipleri",
    id: "card-holders",
    isPage: true,
  },
  {
    title: "Kart Sahipleri Yönetimi Yükleme Yetkisi",
    linkTitle: "Yükleme Yetkisi",
    id: "balance",
    isPage: false,
  },
  {
    title: "Üye İş Yerleri Yönetimi",
    linkTitle: "Üye İşyerleri",
    id: "member-workplaces",
    isPage: true,
  },
  {
    title: "Kampanyalar Yönetimi",
    linkTitle: "Kampanyalar",
    id: "campaigns",
    isPage: true,
  },
  {
    title: "Haberler Yönetimi",
    linkTitle: "Haberler",
    id: "news",
    isPage: true,
  },
  {
    title: "Afiş Yönetimi",
    linkTitle: "Afişler",
    id: "banners",
    isPage: true,
  },
  {
    title: "Kategori Yönetimi",
    linkTitle: "Kategoriler",
    id: "categories",
    isPage: true,
  },
  {
    title: "Şikayet Yönetimi",
    linkTitle: "Şikayetler",
    id: "complains",
    isPage: true,
  },
  {
    title: "SSS Yönetimi",
    linkTitle: "SSS",
    id: "faq",
    isPage: true,
  },
  {
    title: "Tuzla Kart Nedir Yönetimi",
    linkTitle: "Tuzla Kart Nedir",
    id: "tkn",
    isPage: true,
  },
  {
    title: "Genç Kart Nedir Yönetimi",
    linkTitle: "Genç Kart Nedir",
    id: "gkn",
    isPage: true,
  },
  {
    title: "Kullanıcı Yönetimi",
    linkTitle: "Admin Kullanıcılar",
    id: "admin-users",
    isPage: true,
  },
  {
    title: "Api Kullanıcı Yönetimi",
    linkTitle: "Api Kullanıcılar",
    id: "api-users",
    isPage: true,
  },
  {
    title: "Organizasyon Yönetimi",
    linkTitle: "Organizasyonlar",
    id: "organizations",
    isPage: true,
  },
  {
    title: "Kart Tipleri Yönetimi",
    linkTitle: "Kart Tipleri",
    id: "cardtypes",
    isPage: true,
  },
  {
    title: "Meslekler Yönetimi",
    linkTitle: "Meslekler",
    id: "professions",
    isPage: true,
  },
  {
    title: "İşlemler",
    linkTitle: "İşlemler",
    id: "transactions",
    isPage: true,
  },
  {
    title: "Yararlı Linkler",
    linkTitle: "Yararlı Linkler",
    id: "links",
    isPage: true,
  },
];

export const statusLabel = {
  PASSIVE: "Pasif",
  WFA: "Onay Bekliyor",
  ACTIVE: "Aktif",
  CANCELED: "Ret",
};

export const roleLabel = {
  user: "Kart Sahipleri",
  company: "İşyeri Admin Kullanıcıları",
};

export const campaignCodeTypes = {
  GENERATE: "GENERATE",
  FIXED: "FIXED",
  UPLOAD: "UPLOAD",
};

export const userRoles = {
  ADMIN: "admin",
  USER: "user",
  API: "api",
  COMPANY: "company",
};

export const cardStatus = ["ACTIVE", "PASSSIVE"];

export const cardStatusLabel = {
  ACTIVE: "AKTİF",
  PASSIVE: "PASİF",
};

export const linkOrder = [
  "mainpage",
  "card-holders",
  "member-workplaces",
  "campaigns",
  "news",
  "banners",
  "categories",
  "complains",
  "faq",
  "tkn",
  "gkn",
  "admin-users",
  "api-users",
  "organizations",
  "cardtypes",
  "professions",
  "transactions",
];

export const bannertypes = {
  Haber: "news",
  Kampanya: "campaign",
  Link: "link",
  Empty: "empty",
};

export const bannertypesmapper = {
  Haber: { dbName: "news", columnName: "Haber" },
  Kampanya: { dbName: "campaign", columnName: "Kampanya" },
};

export const convertBannerType = (bannertype) => {
  if (bannertypesmapper.Haber.dbName === bannertype) {
    return bannertypesmapper.Haber.columnName;
  } else {
    return bannertypesmapper.Kampanya.columnName;
  }
};
