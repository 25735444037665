import React from 'react';
import './TKStatusCard.scss';
import { Col } from 'react-bootstrap';
import TKIcon from '../../TKIcon/TKIcon';
import CountUp from 'react-countup';

const TKStatusCard = ({ iconName, title, amount, admin, block }) => {
  return (
    <>
      <Col className="tk_status-card text-center mb-2 mb-sm-0">
        <div
          className="tk_status-info"
          style={{ display: block ? block : 'inline-block' }}
        >
          <div className="tk_status-title">
            {!admin && <TKIcon name={iconName} color="#000" size="21" />}
            {title}
          </div>
          <div className="text-dark tk_status-amount">
            <CountUp end={amount} separator="." />
          </div>
        </div>
      </Col>
    </>
  );
};

export default TKStatusCard;
