import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiCall } from '../../helpers/api';
import * as actions from '../actions/banners';
import { 
  GET_TK_ADMIN_BANNERS_REQUEST,
  CREATE_TK_ADMIN_BANNERS_REQUEST,
  DELETE_TK_ADMIN_BANNERS_REQUEST,
  EDIT_TK_ADMIN_BANNERS_REQUEST,
  GET_TK_ADMIN_BANNERS_CONTENT_REQUEST
} from '../actionTypes';
import { customSwal } from '../../helpers/customSwal';


// ADMIN
function* watchGetTKAdminBanners() {
  yield takeEvery(GET_TK_ADMIN_BANNERS_REQUEST, getTKAdminBanners);

}

function* getTKAdminBanners(action) {
  try {
    const res = yield call(apiCall, 
      'get', 
      'api/loyalty/banner', 
    );
    
    console.log(res)

    yield put(actions.getTKAdminBannersSucceeded({
      data: [...res.data.data],
    }));
  } catch (err) {
    yield put(actions.getTKAdminBannersFailed());
  }
}

// ADMIN CONTENT
function* watchGetTKAdminBannersContent() {
  yield takeEvery(GET_TK_ADMIN_BANNERS_CONTENT_REQUEST, getTKAdminBannersContent);

}


function* getTKAdminBannersContent(action) {

  const banner = action.payload;

  if (banner === "news") {

    const res = yield call(apiCall, 
      'get', 
      'api/loyalty/news/getShortDetails'
    );

    yield put(actions.getTKAdminBannersContentSucceeded({data: [...res.data.data]}));

  }
  else {
    
    const res = yield call(apiCall, 
      'get', 
      'api/loyalty/campaign/getShortDetails'
    );


    yield put(actions.getTKAdminBannersContentSucceeded({data: [...res.data.data]}));


  }
 

}



function* watchCreateTKAdminBanners() {
  yield takeLatest(CREATE_TK_ADMIN_BANNERS_REQUEST, createTKAdminBanners);
}

function* createTKAdminBanners(action) {
  try {
    const {data, imageName,  handleSetShowBannersAddModal} = action.payload;
    const {caption, image,banner,link, order_number} = data;
    let formData = new FormData();
    formData.append('type', 'banner');
    formData.append('link', link);
    formData.append('status', 'ACTIVE');
    formData.append('banner',banner);
    formData.append('caption', caption);
    formData.append('image', image, imageName);
    formData.append('order_number', order_number);

    yield call(apiCall, 
      'post', 
      'api/loyalty/banner',
      formData
    );
    yield put(actions.processTKAdminBannersSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Afiş başarıyla oluşturuldu',
      'success'
    );
    yield call(handleSetShowBannersAddModal, false);
    yield put(actions.getTKAdminBannersRequest({}));
  } catch (err) {
    yield put(actions.processTKAdminBannersFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}


// DELETE
function* watchDeleteTKAdminBanners() {
  yield takeLatest(DELETE_TK_ADMIN_BANNERS_REQUEST, deleteTKAdminBanners);
}

function* deleteTKAdminBanners(action) {
  try {

    const {selectedId, page} = action.payload;

    yield call(apiCall, 
      'put', 
      `api/loyalty/banner/${selectedId}/delete`, 
    );
    
    yield put(actions.processTKAdminBannersSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Afiş başarıyla silindi',
      'success'
    );
    yield put(actions.setShowBannersDeleteModal(false));
    yield put(actions.getTKAdminBannersRequest({}));
  } catch (err) {
    yield put(actions.processTKAdminBannersFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}

// EDIT
function* watchEditTKAdminBanners() {
  yield takeLatest(EDIT_TK_ADMIN_BANNERS_REQUEST, editTKAdminBanners);
}

function* editTKAdminBanners(action) {
  try {
    const {data, imageName, selectedId,  handleSetShowBannersEditModal} = action.payload;
    const {caption, image, status,banner,link, order_number} = data;

    let formData = new FormData();
    if (status) {
      formData.append('status', status);
    }
    if (caption) {
      formData.append('caption', caption);
    }
    if (image && imageName) { 
      formData.append('image', image, imageName);
    }
    if (banner)
    {
      formData.append('banner',banner);
    }

    if (order_number)
    {
      formData.append('order_number',order_number);
    }

    if (link)
    {
      formData.append('link',link);
    }

    
    yield call(apiCall, 
      'put', 
      `api/loyalty/banner/${selectedId}`, 
      formData
    );
    yield put(actions.processTKAdminBannersSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Afiş başarıyla güncellendi',
      'success'
    );
    yield call(handleSetShowBannersEditModal, false);
    yield put(actions.getTKAdminBannersRequest({}));
  } catch (err) {
    yield put(actions.processTKAdminBannersFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}

export default function* bannersSaga() {
  yield all([
    fork(watchGetTKAdminBanners),
    fork(watchCreateTKAdminBanners),  
    fork(watchDeleteTKAdminBanners),    
    fork(watchEditTKAdminBanners),  
    fork(watchGetTKAdminBannersContent)

  ]);
}