import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { apiCall } from "../../helpers/api";
import * as actions from "../actions/homepage";
import {
  GET_TK_HP_CAMPS_REQUEST,
  GET_TK_HP_NEWS_REQUEST,
  GET_TK_HP_BANNERS_REQUEST,
  GET_TK_HP_STATUS_REQUEST,
} from "../actionTypes";

function* watchGetTKHPCamps() {
  yield takeEvery(GET_TK_HP_CAMPS_REQUEST, getTKHPCamps);
}

function* getTKHPCamps(action) {
  try {
    const res = yield call(
      apiCall,
      "get",
      "public/campaign/getMainPageCampaigns"
    );
    yield put(actions.getTKHPCampsSucceeded([...res.data.data.slice(0, 5)]));
  } catch (err) {
    yield put(actions.getTKHPCampsFailed());
  }
}

function* watchGetTKHPNews() {
  yield takeEvery(GET_TK_HP_NEWS_REQUEST, getTKHPNews);
}

function* getTKHPNews(action) {
  try {
    const res = yield call(apiCall, "get", "public/news/getMainPageNews");
    yield put(actions.getTKHPNewsSucceeded([...res.data.data]));
  } catch (err) {
    yield put(actions.getTKHPNewsFailed());
  }
}

function* watchGetTKHPBanners() {
  yield takeEvery(GET_TK_HP_BANNERS_REQUEST, getTKHPBanners);
}

function* getTKHPBanners(action) {
  try {
    const res = yield call(apiCall, "get", "public/banner");

    yield put(actions.getTKHPBannersSucceeded([...res.data.data]));
  } catch (err) {
    yield put(actions.getTKHPBannersFailed());
  }
}

function* watchGetTKHPStatus() {
  yield takeEvery(GET_TK_HP_STATUS_REQUEST, getTKHPStatus);
}

function* getTKHPStatus(action) {
  try {
    const res = yield call(apiCall, "get", "public/homepage");

    console.log(res);

    yield put(actions.getTKHPStatusSucceeded(res.data));
  } catch (err) {
    console.log(err);
    yield put(actions.getTKHPStatusFailed());
  }
}

export default function* homepageSaga() {
  yield all([
    fork(watchGetTKHPCamps),
    fork(watchGetTKHPNews),
    fork(watchGetTKHPBanners),
    fork(watchGetTKHPStatus),
  ]);
}
