import { useEffect, useState } from "react";
import TKMap from "../../../TKMap/TKMap";
import  {Form, OverlayTrigger, Tooltip} from  "react-bootstrap";
import TKIcon from "../../../TKIcon/TKIcon";
import "./TKCoordinatesPicker.scss";
import { checkIsValidCoordinates } from "../../../../helpers/validate";

const TKCoordinatesPicker = ({position, onChange, ...props}) => {
  const DEFAULT_POSITION = { lat: 40.89573333940974, lng: 29.356143369314236 };

  const [locationPermission, setPermission] = useState('granted');
  const [center, setCenter] = useState({...DEFAULT_POSITION});

  useEffect(() => {
    try {
      navigator.permissions.query({name:'geolocation'}).then(function(result) {
        if (result.state === 'granted') {
          setPermission(result.state);
        } else if (result.state === 'prompt') {
          setPermission(result.state);
        } else if (result.state === 'denied') {
          setPermission(result.state);
        }
        result.onchange = function() {
          setPermission(result.state);
        }
      });
    } catch(err) {
      console.log(err.message);
      setPermission('denied');
    }
  }, []);

  const handleLatChange = (lat) => {
    if (isNaN(lat)) return;
    const newPosition = {...position, lat};

    onChange(newPosition);
    if (checkIsValidCoordinates(newPosition.lat, newPosition.lng)) {
      setCenter(newPosition);
    }
  }

  const handleLngChange = (lng) => {
    if (isNaN(lng)) return;
    const newPosition = {...position, lng};

    onChange(newPosition);
    if (checkIsValidCoordinates(newPosition.lat, newPosition.lng)) {
      setCenter(newPosition);
    }
  }

  const getCurrentLocation = () => {
    window.navigator.geolocation.getCurrentPosition((pos) => {
      const currentLocation = {
        lat: pos.coords.latitude, 
        lng: pos.coords.longitude
      };
      setPermission('granted');
      setCenter(currentLocation);
      onChange(currentLocation);
    }, (err) => {
      console.log(err.message);
      if (err.code === err.PERMISSION_DENIED) {
        setPermission('denied');
      }
    });
  }

  const getCurrentLocationTooltipText = () => {
    if (locationPermission === 'granted') {
      return "Konumum";
    } else if (locationPermission === 'prompt') {
      return "Konumum";
    } else if (locationPermission === 'denied') {
      return "Konum verinizi kullanabilmek için konum erişimine izin vermelisiniz.";
    }
    return "Konumum"
  }

  return (
  <div className="tk_coordinates-picker">
    <div className="d-flex justify-content-between aling-items-center">
      <div className="mr-2">
        <Form.Control 
          placeholder = "Enlem"
          value = {position?.lat ? position.lat : ""}
          onChange = {(e) => handleLatChange(e.target.value)}
        />
      </div>
      <div className="mx-2">
        <Form.Control 
          placeholder = "Boylam"
          value = {position?.lng ? position.lng : ""}
          onChange = {(e) => handleLngChange(e.target.value)}
        />
      </div>
      <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="tk_current-location-tooltip">{getCurrentLocationTooltipText()}</Tooltip>
          }
        >
        <div className="d-flex align-items-center ">
            <TKIcon
              className={`tk_coordinates-picker-my-location${locationPermission === 'denied' ? '-disabled' : ''}`}
              name="my-location" 
              color="black" 
              size="25" 
              onClick = {() => {getCurrentLocation()}}
            />
        </div>
      </OverlayTrigger>

    </div>
    <div className="tk_coordinates-picker-map my-2">
      <TKMap 
        position={{...DEFAULT_POSITION, ...position}}
        center={center}
        onClick={(t, map, coords) => { 
          setCenter({lat: map.center.lat(), lng: map.center.lng()}); 
          onChange({lat: coords.latLng.lat(), lng: coords.latLng.lng()});  
        }}
      />
      </div>
  </div>
  );
}

export default TKCoordinatesPicker;
TKCoordinatesPicker.defaultProps = {
  position: {},
  onChange: () => {},
}