import React from 'react';
import './TKSlider.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const TKSlider = ({className, customSettings, children}) => {
  const settings = {
    dots: true,
    swipeToSlide: true,
    arrows: false,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    initialSlide: 0,
    customPaging: i => (<div></div>),
    ...customSettings
  };

  return (
    <Slider className={`tk_slider ${className}`.trim()} {...settings}>
      {children}
    </Slider>
  );
};

export default TKSlider;

TKSlider.defaultProps = {
  className: '',
  customSettings: {}
}