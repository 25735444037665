import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKSelect from "../../components/TKSelect/TKSelect";
import "./TKSearchText.scss";
import { Form } from "react-bootstrap";
import { getTKCardTypesRequest } from "../../../src/store/actions/cardTypes";

const TKSearchText = ({
  status,
  isStatus,
  searchparams,
  cardtype,
  doSearch,
}) => {
  let typeTimer = null;

  const { listCardTypes } = useSelector((state) => state.cardTypes);
  const dispatch = useDispatch();
  const [cardType, setCardType] = useState(false);

  const [types, setTypes] = useState([
    { type: "İNDİRİM", _id: "İNDİRİM" },
    { type: "KAMPANYA", _id: "KAMPANYA" },
    { type: "YÜKLEME", _id: "YÜKLEME" },
  ]);

  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    dispatch(getTKCardTypesRequest());
  }, [dispatch]);

  const handleStatusChange = (stat) => {
    if (stat === status) {
      doSearch("status", "ACTIVE");
    } else {
      doSearch("status", stat);
    }
  };

  const handleCardTypeOnChange = (name, id) => {
    doSearch(name, id, status);

    setCardType(id);
  };

  return (
    <>
      <Form className="tk_search-text">
        <Form.Group className="d-flex align-items-center">
          <div className="d-flex justify-content-end">
            {searchparams.map((param) =>
              param?.name != "type" ? (
                <Form.Control
                  className={
                    "mr-2 tk_search-text-input tk_search-text-" + param.name
                  }
                  name={param.name}
                  placeholder={param.holder}
                  size="sm"
                  onChange={(e) => {
                    clearTimeout(typeTimer);
                    typeTimer = setTimeout(
                      () => doSearch(e.target.name, e.target.value, status),
                      500
                    );
                  }}
                />
              ) : (
                <TKSelect
                  defaultTitle={param.holder}
                  selectedItem={selectedType}
                  items={types}
                  itemSelector="type"
                  name={param.name}
                  itemOnClick={(id) => {
                    setSelectedType(id);
                    clearTimeout(typeTimer);
                    typeTimer = setTimeout(
                      () => doSearch("type", id, status),
                      500
                    );
                  }}
                />
              )
            )}
            {cardtype && (
              <>
                <TKSelect
                  defaultTitle="TÜM KARTLAR"
                  itemSelector="name"
                  items={listCardTypes}
                  selectedItem={cardType}
                  name="cardtype"
                  itemOnClick={(id) => handleCardTypeOnChange("cardtype", id)}
                />
              </>
            )}
          </div>
          &nbsp;&nbsp;
          {isStatus && (
            <>
              <Form.Check
                inline
                className=""
                type="checkbox"
                id="filter-status-active"
                label="Aktif"
                name="status-filter"
                value="ACTIVE"
                checked={status === "ACTIVE"}
                onChange={(e) => handleStatusChange(e.target.value)}
              />
              <Form.Check
                inline
                className=""
                type="checkbox"
                id="filter-status-wfa"
                label="Onay Bekleyen"
                name="status-filter"
                value="WFA"
                checked={status === "WFA"}
                onChange={(e) => handleStatusChange(e.target.value)}
              />
              <Form.Check
                inline
                className=""
                type="checkbox"
                id="filter-status-passive"
                label="Pasif"
                name="status-filter"
                value="PASSIVE"
                checked={status === "PASSIVE"}
                onChange={(e) => handleStatusChange(e.target.value)}
              />
              <Form.Check
                inline
                className=""
                type="checkbox"
                id="filter-status-canceled"
                label="Ret"
                name="status-filter"
                value="CANCELED"
                checked={status === "CANCELED"}
                onChange={(e) => handleStatusChange(e.target.value)}
              />
            </>
          )}
        </Form.Group>
      </Form>
    </>
  );
};

export default TKSearchText;

TKSearchText.defaultProps = {
  cardtype: false,
};
