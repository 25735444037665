import React from "react";
import "./TKAccordion.scss";
import { Accordion } from "react-bootstrap";
import ContextAwareToggle from "./ContextAwareToggle/ContextAwareToggle";
import tuzlakartnedir from "../../assets/images/tuzla-kart-nedir.png";
import genckartnedir from "../../assets/images/genc-kart-nedir.JPG";
import tuzlakartnerelerde from "../../assets/images/tuzla-kart-nereden-alabilirim.JPG";
import tuzlakartnereden from "../../assets/images/tuzla-kart-nerelerde-kullanılır.JPG";
import nerede1 from "../../assets/images/nerede_1.JPG";
import nerede2 from "../../assets/images/nerede_2.jpeg";
import nerede3 from "../../assets/images/nerede_3.JPG";
import parse from "html-react-parser";

import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { isJsonString } from "../../helpers/utilities";
const TKAccordion = ({ listFAQ }) => {
  return (
    <Accordion className="tk_accordion">
      {listFAQ.map((item, index) => {
        let image;
        if (
          item.caption == "Tuzla Kart Nedir?" &&
          window.location.pathname == "/tuzla-kart-nedir"
        ) {
          image =
            '<img style="width:100%" src="' +
            tuzlakartnedir +
            '" />' +
            item.detail;
        } else if (item.caption == "Tuzla Kart Nerede Kullanılır?") {
          image =
            '<img style="width:100%" src="' +
            tuzlakartnereden +
            '" />' +
            item.detail +
            '<img style="width:100%" src="' +
            nerede1 +
            '" /> <br>' +
            '<img style="width:100%" src="' +
            nerede2 +
            '" />  <br>' +
            '<img style="width:100%" src="' +
            nerede3 +
            '" />';
        } else if (item.caption == "Tuzla Kart'ı Nereden/Nasıl Alabilirim?") {
          image =
            '<img style="width:100%" src="' +
            tuzlakartnerelerde +
            '" />' +
            item.detail;
        } else if (
          item.caption == "Genç Kart Nedir?" &&
          window.location.pathname == "/genc-kart-nedir"
        ) {
          image =
            '<img style="width:100%" src="' +
            genckartnedir +
            '" />' +
            item.detail;
        } else {
          image = item.detail;
        }
        return (
          <div className="tk_accordion-card">
            <ContextAwareToggle eventKey={index + 1}>
              {item.caption}
            </ContextAwareToggle>
            <Accordion.Collapse eventKey={index + 1}>
              <div className="tk_accordion-body">
                {isJsonString(item.detail) ? (
                  <Editor
                    toolbarHidden
                    readOnly
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorState={EditorState.createWithContent(
                      convertFromRaw(JSON.parse(item.detail))
                    )}
                  />
                ) : (
                  parse(item.detail)
                )}
              </div>
            </Accordion.Collapse>
          </div>
        );
      })}
    </Accordion>
  );
};

export default TKAccordion;

TKAccordion.defaultProps = {
  listFAQ: [],
};
