import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiCall } from '../../helpers/api';
import * as actions from '../actions/apiUsers';
import { 
  GET_TK_ADMIN_APU_REQUEST, 
  CREATE_TK_ADMIN_APU_REQUEST, 
  DELETE_TK_ADMIN_APU_REQUEST, 
  CREATE_TK_ADMIN_APU_REQUEST_TOKEN
} from '../actionTypes';
import { customSwal } from '../../helpers/customSwal';
import { userRoles } from '../../constants/general';
import reduxSaga from 'redux-saga';

function* watchGetTKAdminAPU() {
  yield takeEvery(GET_TK_ADMIN_APU_REQUEST, getTKAdminAPU);
}

function* getTKAdminAPU(action) {
  const SIZE = 50;
  try {
    const {page} = action.payload;
    const res = yield call(apiCall, 
      'post', 
      'api/survey/users', 
      {
        role: userRoles.API,
        status:"ACTIVE"  
      }
    );
    let newList = res.data.data.map(item => {
        return { _id:item._id,name: item.name,type:item.api_key.type,key:item.api_key.key }       
    })
    yield put(actions.getTKAdminAPUSucceeded({
      data: newList,
      // page,
      // totalPages: Math.ceil(res.data.totalCount/SIZE)
      page: 1,
      totalPages: 1,
    }));
  } catch (err) {
    yield put(actions.getTKAdminAPUFailed());
  }
}

function* watchCreateTKAdminAPU() {
  yield takeLatest(CREATE_TK_ADMIN_APU_REQUEST, createTKAdminAPU);
}

function* createTKAdminAPU(action) {
  try {
    const {data, token, page, handleSetShowAPUAddModal} = action.payload;
    if (token === '')
    {
      yield put(actions.processTKAdminAPUFailed());
      yield call(customSwal, 
        'Hata',
        'Anahtar oluşturmanız gerekmektedir',
        'error'
      );

    }
    else {
    let api_key = { 
      type : data.type,
      key : token
     }
    yield call(apiCall, 
      'post', 
      'api/survey/users/createuser', 
      {
        name:data.name,
        surname:data.name,
        tckn:data.name,
        api_key,
        role: userRoles.API,
        status:"ACTIVE"
      }
    );
    yield put(actions.processTKAdminAPUSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Kullanıcı başarıyla oluşturuldu',
      'success'
    );
    yield call(handleSetShowAPUAddModal, false);
    yield put(actions.getTKAdminAPURequest({page}));
    }
  } catch (err) {
    yield put(actions.processTKAdminAPUFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}

function* watchCreateTKAdminAPUToken() {
  yield takeLatest(CREATE_TK_ADMIN_APU_REQUEST_TOKEN, createTKAdminAPUToken);
}

function* createTKAdminAPUToken(action) {
  try {
    const {company} = action.payload;

    const res = yield call(apiCall, 
      'post', 
      'token', 
      {
        client_type:'api-key-generator',
        api_type:'TRANS_API',
        company: company
      }
    );

    yield put(actions.createTKAdminAPUTokenSucceed({token : res.data.access_token }));
    yield call(customSwal, 
      'Başarılı',
      'Key başarıyla oluşturuldu. Anahtarı görebilmeniz için kayıt yapmanız gerekmektedir.',
      'success'
    );
    } catch (err) {
    yield put(actions.processTKAdminAPUFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}



function* watchDeleteTKAdminAPU() {
  yield takeLatest(DELETE_TK_ADMIN_APU_REQUEST, deleteTKAdminAPU);
}

function* deleteTKAdminAPU(action) {
  try {
    const {selectedId, page} = action.payload;
    yield call(apiCall, 
      'delete', 
      `api/survey/users/deleteUser/${selectedId}`
    );
    yield put(actions.processTKAdminAPUSucceeded());
    yield call(customSwal, 
      'Başarılı',
      'Kullanıcı başarıyla silindi',
      'success'
    );
    yield put(actions.setShowAPUDeleteModal(false));
    yield put(actions.getTKAdminAPURequest({page}));
  } catch (err) {
    yield put(actions.processTKAdminAPUFailed());
    yield call(customSwal, 
      'Hata',
      'Yanlış bir şey oldu, lütfen tekrar deneyiniz',
      'error'
    );
  }
}

export default function* apiUsersSaga() {
  yield all([
    fork(watchGetTKAdminAPU),
    fork(watchCreateTKAdminAPU),
    fork(watchDeleteTKAdminAPU),
    fork(watchCreateTKAdminAPUToken)
  ]);
}