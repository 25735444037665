import React from "react";
import { FormControl } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';


const TKCustomDropdownMenu = React.forwardRef(({ items, itemOnClick , selectedItem , search,itemSelector,onChange,show }, ref) => (
            <div
          ref={ref}
        >
          {show &&
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Filtreleme yapabilirsiniz..."          
            type="text"
            value={search}
            onKeyDown={(e) => onChange(e.key)}
            />
          }
        {show && items.map((item, index) => 
          <Dropdown.Item 
            key={index}
            onClick={() => itemOnClick(item._id)}
            className={`text-truncate ${item._id === selectedItem ? 'tk_select-item-active' : ''}`.trim()}
          >
            {item[itemSelector]}
          </Dropdown.Item>

        )}
         
        </div>

    )
);

export default TKCustomDropdownMenu;
