import * as types from '../actionTypes';

const initialState = {
  tkartList: {},
  genckartList: {},
  gazikartList: {},
  reportsLoading: false,
};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TK_ADMIN_REPORTS_TKART_REQUEST:
      return {
        ...state,
        reportsLoading: true,
      };
    case types.GET_TK_ADMIN_REPORTS_TKART_SUCCEEDED:
      return {
        ...state,
        tkartList: action.payload,
        reportsLoading: false,
      };
    case types.GET_TK_ADMIN_REPORTS_TKART_FAILED:
      return {
        ...state,
        reportsLoading: false,
      };
    case types.GET_TK_ADMIN_REPORTS_GENCKART_REQUEST:
      return {
        ...state,
        reportsLoading: true,
      };
    case types.GET_TK_ADMIN_REPORTS_GENCKART_SUCCEEDED:
      return {
        ...state,
        genckartList: action.payload,
        reportsLoading: false,
      };
    case types.GET_TK_ADMIN_REPORTS_GENCKART_FAILED:
      return {
        ...state,
        reportsLoading: false,
      };
    case types.GET_TK_ADMIN_REPORTS_GAZIKART_REQUEST:
      return {
        ...state,
        reportsLoading: true,
      };
    case types.GET_TK_ADMIN_REPORTS_GAZIKART_SUCCEEDED:
      return {
        ...state,
        gazikartList: action.payload,
        reportsLoading: false,
      };
    case types.GET_TK_ADMIN_REPORTS_GAZIKART_FAILED:
      return {
        ...state,
        reportsLoading: false,
      };
    case types.GET_TK_ADMIN_REPORTS_ACTIVEUSERS_REQUEST:
      return {
        ...state,
      };
    case types.GET_TK_ADMIN_REPORTS_ACTIVECOMPANIES_REQUEST:
      return {
        ...state,
      };
    case types.GET_TK_ADMIN_REPORTS_MOSTCAMPAIGNS_REQUEST:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default reports;
